import React, {PropsWithChildren} from 'react';

import {message} from 'antd';

import SnackBarContext from 'src/providers/SnackBar/Context';

const SnackBarProvider: React.FC<PropsWithChildren<{}>> = ({children}) => {
  // Message
  const [api, contextHolder] = message.useMessage();

  return (
    <SnackBarContext.Provider value={{api}}>
      {contextHolder}

      {children}
    </SnackBarContext.Provider>
  );
};

export default SnackBarProvider;
