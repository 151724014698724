import React, {forwardRef} from 'react';

import styled from 'styled-components';
import {useDebouncedCallback} from 'use-debounce';

import {SearchIcon} from 'src/components/atoms/icon/SearchIcon';

import {settings} from 'src/constants/settings';

const StyledInput = styled.input`
  color: ${({theme}) => theme.colors.gray12};
  ${({theme}) => theme.textStyles.p1};
  border: none;
  padding-left: ${({theme}) => theme.paddings.md};
  width: 100%;
  outline: none;
`;

const Container = styled.div`
  padding-left: ${({theme}) => theme.paddings.lg};
  padding-right: ${({theme}) => theme.paddings.lg};
  height: ${({theme}) => theme.heights.input.default};
  align-items: center;
  display: flex;
  border-width: ${({theme}) => theme.borders.xs};
  border-color: ${({theme}) => theme.colors.gray4};
  border-radius: ${({theme}) => theme.radius.lg};
  border-style: solid;
  flex-direction: row;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: ${({theme}) => theme.colors.primary5};
  }
`;

interface SearchProps extends React.HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  onSearch: (value: string) => void;
}

export const Search = forwardRef<HTMLInputElement | null, SearchProps>(
  ({onSearch, placeholder, ...rest}, ref) => {
    // Debounce callback
    const handleChange = useDebouncedCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        onSearch(e.target.value);
      },
      settings.input.debounce,
    );

    return (
      <Container {...rest}>
        <SearchIcon />

        <StyledInput ref={ref} onChange={handleChange} placeholder={placeholder} />
      </Container>
    );
  },
);
