import {createSlice, CaseReducer, PayloadAction} from '@reduxjs/toolkit';

interface InitialState {
  refreshToken: string | null;
  accessToken: string | null;
}

const setRefreshToken: CaseReducer<InitialState, PayloadAction<string | null>> = (
  state,
  action,
) => {
  state.refreshToken = action.payload;
};

const setAccessToken: CaseReducer<InitialState, PayloadAction<string | null>> = (
  state,
  action,
) => {
  state.accessToken = action.payload;
};

const signOut: CaseReducer<InitialState, PayloadAction<undefined>> = (state, _action) => {
  state.refreshToken = null;
  state.accessToken = null;
};

const initialState: InitialState = {
  refreshToken: null,
  accessToken: null,
};

const {actions, reducer} = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setRefreshToken,
    setAccessToken,
    signOut,
  },
});

export {actions as userActions};
export default reducer;
