import i18next from 'i18next';

import {Allergy, Dish, DishType, MenuType} from 'src/interfaces/api/generated.interface';

export function getDishTypeName(
  type: DishType,
  count?: number,
  menuType?: MenuType,
): string {
  if (
    menuType === MenuType.FOUR_DISHES &&
    (type === DishType.DAIRY_PRODUCT || type === DishType.DESSERT)
  ) {
    return i18next.t('dish-type.dairy-procut-or-dessert', {
      count,
    });
  }

  switch (type) {
    case DishType.STARTER:
      return i18next.t('dish-type.starter', {
        count,
      });
    case DishType.SIDE_DISH:
      return i18next.t('dish-type.side-dish', {
        count,
      });
    case DishType.PROTEIN_DISH:
      return i18next.t('dish-type.protein-dish', {
        count,
      });
    case DishType.MIXED_DISH:
      return i18next.t('dish-type.mixed-dish', {
        count,
      });
    case DishType.DAIRY_PRODUCT:
      return i18next.t('dish-type.dairy-product', {
        count,
      });
    case DishType.DESSERT:
      return i18next.t('dish-type.dessert', {
        count,
      });
  }
}

// export function getDishNutritionalLevelName(type: NutritionalScoreValue): string {
//   switch (type) {
//     case NutritionalScoreValue.LOW:
//       return i18next.t('nutritional-score-levels.low');
//     case NutritionalScoreValue.MEDIUM:
//       return i18next.t('nutritional-score-levels.medium');
//     case NutritionalScoreValue.HIGH:
//       return i18next.t('nutritional-score-levels.high');
//   }
// }

export function getAllergenName(allergen: Allergy): string {
  switch (allergen) {
    case Allergy.GLUTEN:
      return i18next.t('common:allergens.gluten');
    case Allergy.CRUSTACEAN:
      return i18next.t('common:allergens.crustaceans');
    case Allergy.EGG:
      return i18next.t('common:allergens.eggs');
    case Allergy.FISH:
      return i18next.t('common:allergens.fish');
    case Allergy.PEANUT:
      return i18next.t('common:allergens.peanuts');
    case Allergy.SOY:
      return i18next.t('common:allergens.soybeans');
    case Allergy.MILK:
      return i18next.t('common:allergens.milk');
    case Allergy.NUT:
      return i18next.t('common:allergens.nuts');
    case Allergy.CELERY:
      return i18next.t('common:allergens.celery');
    case Allergy.MUSTARD:
      return i18next.t('common:allergens.mustard');
    case Allergy.SESAME:
      return i18next.t('common:allergens.sesame');
    case Allergy.SULFITE:
      return i18next.t('common:allergens.sulphur-dioxide');
    case Allergy.LUPINE:
      return i18next.t('common:allergens.lupin');
    case Allergy.MOLLUSC:
      return i18next.t('common:allergens.molluscs');
    case Allergy.NONE:
      return i18next.t('common:allergens.none');
  }
}
