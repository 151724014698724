import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const UploadIcon = ({size = 24, color, strokeWidth = 1.5, ...rest}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M12.122 15.436V3.395M15.038 12.508l-2.916 2.928-2.916-2.928"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M16.755 8.128h.933a3.684 3.684 0 0 1 3.684 3.685v4.884a3.675 3.675 0 0 1-3.675 3.675H6.557a3.685 3.685 0 0 1-3.685-3.685v-4.885a3.675 3.675 0 0 1 3.675-3.674h.942"
      />
    </svg>
  );
};
