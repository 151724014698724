import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {Menu} from 'src/interfaces/api/generated.interface';
import {CreateMenu} from 'src/interfaces/api/menu.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useCreateMenuMutation() {
  return useMutation({
    mutationFn: async (menuData: CreateMenu): Promise<Menu> => {
      const {data} = await networkClient.post<Menu>(Routes.Menu, menuData);

      queryClient.invalidateQueries([Routes.Menus]);
      return data;
    },
  });
}
