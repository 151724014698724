import React, {useCallback} from 'react';

import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Col} from 'antd';
import moment from 'moment';

import {WeekMeals} from 'src/interfaces/api/meal.interface';

import {InfoTag} from 'src/components/atoms/tag/InfoTag';

const Container = styled(Col)`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  height: 100%;
  padding: ${({theme}) => theme.paddings.xxxs} 0px;
  overflow: hidden;
`;

const WeekItemContainer = styled.button`
  display: flex;
  height: fit-content;
  width: 100%;
  border: none;
  background-color: transparent;
  flex-direction: row;
  padding: ${({theme}) => theme.paddings.xs} 0px;
  margin: ${({theme}) => theme.paddings.xxxs} 0px;
  align-items: center;
  justify-content: space-between;
`;

const DateText = styled.text<{isPublished: boolean}>`
  ${({theme, isPublished}) =>
    isPublished ? theme.textStyles.p2Bold : theme.textStyles.p2};
  color: ${({theme, isPublished}) =>
    isPublished ? theme.colors.gray12 : theme.colors.gray8};
  margin-left: ${({theme}) => theme.paddings.xs};
  white-space: pre;
`;

const SelectedIndicator = styled.div`
  height: ${({theme}) => theme.heights.menuIndicator.default};
  width: ${({theme}) => theme.widths.menuIndicator.default};
  border-radius: ${({theme}) => theme.radius.xl};
  background-color: ${({theme}) => theme.colors.primary5};
  right: 0px;
  position: absolute;
`;

const StyledInfoTag = styled(InfoTag)`
  margin-right: ${({theme}) =>
    parseInt(theme.paddings.sm, 10) + parseInt(theme.widths.menuIndicator.default, 10)}px;
`;

interface WeeksMenuProps extends React.ComponentProps<typeof Col> {
  data: WeekMeals[];
  selectedItem: WeekMeals | null;
  onSelectItem?: (item: WeekMeals) => void;
}

export const WeeksMenu: React.FC<WeeksMenuProps> = ({
  data,
  selectedItem,
  onSelectItem,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation('meals-page');

  // Callbacks
  const renderWeekItem = useCallback(
    (item: WeekMeals) => {
      const {startDate, endDate, published} = item;
      return (
        <WeekItemContainer
          key={startDate.toString()}
          onClick={() => onSelectItem?.(item)}>
          <DateText isPublished={published}>
            {t('week-information', {
              startDate: moment(startDate).format('DD/MM/YYYY'),
              endDate: moment(endDate).format('DD/MM/YYYY'),
            })}
          </DateText>

          <StyledInfoTag
            value={published ? t('published') : t('draft')}
            bgColor={published ? 'primary5' : 'gray5'}
            textColor={published ? 'primary2' : 'gray10'}
          />

          {selectedItem?.startDate === startDate && <SelectedIndicator />}
        </WeekItemContainer>
      );
    },
    [selectedItem, onSelectItem, t],
  );

  return (
    <Container span={5} {...rest}>
      {data.map(renderWeekItem)}
    </Container>
  );
};
