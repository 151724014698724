import React, {useCallback, useState} from 'react';

import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Divider, Form, Row} from 'antd';

import {
  ReportFoodType,
  ReportFoodWithScaleForm,
  ReportFoodWithoutScaleForm,
} from 'src/interfaces/weather.interface';
import {WasteValue} from 'src/interfaces/api/generated.interface';

import {Mark} from 'src/components/atoms/mark/Mark';
import {Input} from 'src/components/atoms/input/Input';
import {OptionButton} from 'src/components/molecules/button/OptionButton';
import {Button} from 'src/components/atoms/button/Button';

const StyledOptionButton = styled(OptionButton<WasteValue>)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const SubmitFormItem = styled(Form.Item)`
  width: 100%;
`;

interface ReportFoodFormProps
  extends Omit<
    React.ComponentProps<
      typeof Form<ReportFoodWithScaleForm | ReportFoodWithoutScaleForm>
    >,
    'onFinish'
  > {
  type: ReportFoodType;
  isLoading?: boolean;
  onFinish: (values: ReportFoodWithScaleForm | ReportFoodWithoutScaleForm) => void;
}

export const ReportFoodForm: React.FC<ReportFoodFormProps> = ({
  type,
  isLoading,
  onFinish,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation(['common', 'report-weather-page']);

  // Form
  const [form] = Form.useForm();

  // States
  const [wasteValue, setWasteValue] = useState<WasteValue>(WasteValue.LOW);

  // Callbacks

  const renderOptions = useCallback(
    (option: WasteValue) => {
      return (
        <StyledOptionButton
          selected={wasteValue === option}
          value={option}
          text={t(
            // @ts-ignore
            `report-weather-page:food-waste.options.${option.toLowerCase()}.value`,
          )}
          onClick={setWasteValue}
        />
      );
    },
    [wasteValue, t],
  );
  const renderMark = useCallback((label: React.ReactNode, info: {required: boolean}) => {
    return <Mark label={label} info={info} />;
  }, []);

  const renderReportWithScale = useCallback(() => {
    return (
      <>
        <Form.Item
          label={t('report-weather-page:food-waste.fields.nb-participants')}
          name="totalParticipants"
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[0-9]+$/),
              message: t(
                'report-weather-page:food-waste.errors.nb-participants-required',
              ),
            },
          ]}>
          <Input
            placeholder={t('report-weather-page:food-waste.placeholders.nb-participants')}
          />
        </Form.Item>

        <Form.Item
          label={t('report-weather-page:food-waste.fields.weight')}
          name="totalWaste"
          rules={[
            {
              required: true,
              pattern: new RegExp(/^[0-9]+$/),
              message: t('report-weather-page:food-waste.errors.weight-required'),
            },
          ]}>
          <Input placeholder={t('report-weather-page:food-waste.placeholders.weight')} />
        </Form.Item>
      </>
    );
  }, [t]);

  return (
    <Form form={form} layout="vertical" requiredMark={renderMark} {...rest}>
      {type === ReportFoodType.WithScale
        ? renderReportWithScale()
        : Object.values(WasteValue).map(renderOptions)}

      <Divider />

      <Row wrap={false}>
        <BackButton block>{t('common:buttons.back')}</BackButton>

        <SubmitFormItem>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={() =>
              type === ReportFoodType.WithoutScale
                ? onFinish({wasteValue})
                : onFinish(form.getFieldsValue())
            }>
            {t('common:buttons.save')}
          </Button>
        </SubmitFormItem>
      </Row>
    </Form>
  );
};
