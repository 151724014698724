import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const TrashIcon = ({size = 24, color, strokeWidth = 1.5, ...rest}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 24 24" {...rest}>
      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m19.668 10.313-.612 8.376a3.317 3.317 0 0 1-3.308 3.076H9.755a3.318 3.318 0 0 1-3.308-3.077l-.612-8.375M14.661 12.871l-3.821 3.822m0-3.822 3.821 3.822M21.015 7.168H4.485M16.523 7.167 16.02 4.69a1.248 1.248 0 0 0-1.205-.925h-4.121a1.249 1.249 0 0 0-1.212.925l-.498 2.476"
      />
    </svg>
  );
};
