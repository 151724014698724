import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const HeartIcon = ({
  width = 21,
  height = 20,
  size,
  color,
  strokeWidth = 1.66,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width ?? size} height={height ?? size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M13.747 3.338c2.645 0 4.42 2.482 4.42 4.792 0 4.691-7.366 8.532-7.5 8.532-.134 0-7.5-3.84-7.5-8.532 0-2.31 1.774-4.792 4.42-4.792 1.512 0 2.505.754 3.08 1.425.574-.671 1.567-1.425 3.08-1.425Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
