import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {GetDish, GetDishResponse} from 'src/interfaces/api/dish.interface';

import {networkClient} from 'src/services/client';

export function useDishQuery(
  {dishId}: GetDish,
  options?: UseQueryOptions<GetDishResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.Dish, dishId],
    queryFn: async (): Promise<GetDishResponse | null> => {
      const {data} = await networkClient.get(Routes.Dish, {params: {dishId}});

      return data;
    },
    ...options,
  });
}
