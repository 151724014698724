import React from 'react';

import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Button} from 'src/components/atoms/button/Button';

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

interface ModalFooterProps extends React.HTMLAttributes<HTMLDivElement> {
  rightButtonLoading?: boolean;
  leftButtonText?: string;
  rightButtonText?: string;
  onLeftButtonClick: () => void;
  onRightButtonClick: () => void;
}

export const ModalFooter: React.FC<ModalFooterProps> = ({
  rightButtonLoading,
  leftButtonText,
  rightButtonText,
  onLeftButtonClick,
  onRightButtonClick,
}) => {
  // Translation
  const {t} = useTranslation();

  return (
    <FooterContainer>
      <BackButton block onClick={onLeftButtonClick}>
        {leftButtonText ?? t('buttons.back')}
      </BackButton>

      <Button
        type="primary"
        block
        onClick={onRightButtonClick}
        loading={rightButtonLoading}>
        {rightButtonText ?? t('buttons.next')}
      </Button>
    </FooterContainer>
  );
};
