import React, {useCallback, useMemo, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Menu, type MenuProps} from 'antd';

import {MyAccountMenu} from 'src/interfaces/my-account.interface';

import {MyInformationTab} from 'src/components/organisms/tabs/MyInformationTab';
import {MyCanteenTab} from 'src/components/organisms/tabs/MyCanteenTab';

import useCurrentUser from 'src/hooks/useCurrentUser';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export default function MyAccountPage() {
  // Translation
  const {t} = useTranslation('my-account-page');

  // States
  const [currentTab, setCurrentTab] = useState(MyAccountMenu.MyInformation);

  // Current user
  const {isChef} = useCurrentUser();

  // Constants
  const menuItems: MenuProps['items'] = useMemo(
    () =>
      isChef
        ? [
            {
              label: t('menu.my-information'),
              key: MyAccountMenu.MyInformation,
            },
            {
              label: t('menu.my-canteen'),
              key: MyAccountMenu.MyCanteen,
            },
          ]
        : [
            {
              label: t('menu.my-information'),
              key: MyAccountMenu.MyInformation,
            },
          ],

    [t, isChef],
  );

  const handleMenuClick = useCallback((e: {key: string}) => {
    setCurrentTab(e.key as MyAccountMenu);
  }, []);

  const renderMenuTab = useCallback(() => {
    switch (currentTab) {
      case MyAccountMenu.MyInformation:
        return <MyInformationTab />;
      case MyAccountMenu.MyCanteen:
        return <MyCanteenTab />;
      default:
        return null;
    }
  }, [currentTab]);

  return (
    <Container>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <Menu
        onClick={handleMenuClick}
        selectedKeys={[currentTab]}
        mode="horizontal"
        items={menuItems}
      />

      {renderMenuTab()}
    </Container>
  );
}
