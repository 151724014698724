import React, {useCallback, useEffect} from 'react';

import {Helmet} from 'react-helmet-async';
import {Form, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {SignIn} from 'src/interfaces/api/user.interface';

import {Button} from 'src/components/atoms/button/Button';
import {Input} from 'src/components/atoms/input/Input';
import {HelpFooter} from 'src/components/molecules/footer/HelpFooter';
import {ProfileIcon} from 'src/components/atoms/icon/ProfileIcon';
import {LockIcon} from 'src/components/atoms/icon/LockIcon';
import {paths} from 'src/navigation/paths';

import {useSignInMutation} from 'src/hooks/mutations/useSignInMutation';
import useSnackBar from 'src/hooks/useSnackBar';
import useAuth from 'src/hooks/useAuth';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${({theme}) => theme.paddings.lg};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRow = styled(Row)`
  display: flex;
  width: 100%;
`;

const OtherButtonContainer = styled(Row)`
  margin-bottom: ${({theme}) => theme.margins.xl};
`;

const StyledForm = styled(Form<SignIn>)`
  width: 60%;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h4};
  color: ${({theme}) => theme.colors.gray12};
`;

const Description = styled.text`
  ${({theme}) => theme.textStyles.h7};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.xl};
  margin-top: ${({theme}) => theme.margins.xs};
`;

export default function SignInPage() {
  // Translation
  const {t} = useTranslation('sign-in-page');

  // Navigate
  const navigate = useNavigate();

  // Mutation
  const {mutateAsync: signIn, isLoading} = useSignInMutation();

  // Auth
  const {isSignedIn} = useAuth();

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Effects
  useEffect(() => {
    if (isSignedIn) {
      navigate(paths.home.pattern);
    }
  }, [isSignedIn, navigate]);

  // Callbacks
  const handleFinish = useCallback(
    async (values: SignIn) => {
      try {
        await signIn(values);
        showSuccessSnackBar(t('success.sign-in'));
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [showErrorSnackBar, showSuccessSnackBar, signIn, t],
  );

  const handleForgotPassword = useCallback(() => {
    navigate(paths.auth.forgotPassword.pattern);
  }, [navigate]);

  return (
    <Container>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <StyledRow justify="center">
        <StyledForm layout="vertical" onFinish={handleFinish} autoComplete="off">
          <ContentContainer>
            <Title>{t('title')}</Title>

            <Description>{t('description')}</Description>

            <Form.Item
              label={t('fields.email')}
              name="email"
              rules={[{required: true, message: t('errors.email')}]}>
              <Input prefix={<ProfileIcon />} placeholder={t('placeholders.email')} />
            </Form.Item>

            <Form.Item
              label={t('fields.password')}
              name="password"
              rules={[{required: true, message: t('errors.password')}]}>
              <Input
                isPassword
                prefix={<LockIcon />}
                placeholder={t('placeholders.password')}
              />
            </Form.Item>

            <OtherButtonContainer justify="end">
              <Button type="link" htmlType="button" onClick={handleForgotPassword}>
                {t('buttons.forgot-password')}
              </Button>
            </OtherButtonContainer>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading} block>
                {t('buttons.sign-in')}
              </Button>
            </Form.Item>

            <HelpFooter />
          </ContentContainer>
        </StyledForm>
      </StyledRow>
    </Container>
  );
}
