import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const ListIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width ?? size} height={height ?? size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M7.083 16.572h10M7.083 11.572h10M7.083 6.572h10M3.76 6.56v.053m.215-.042a.217.217 0 1 1-.434 0 .217.217 0 0 1 .434 0ZM3.76 11.667v.054m.215-.042a.217.217 0 1 1-.434 0 .217.217 0 0 1 .434 0ZM3.76 16.56v.053m.215-.042a.217.217 0 1 1-.434 0 .217.217 0 0 1 .434 0Z"
      />
    </svg>
  );
};
