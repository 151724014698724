import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const CalendarInfoIcon = ({
  width = 33,
  height = 24,
  strokeWidth,
  color,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width} height={height} fill="none" {...rest}>
      <circle cx={12} cy={12} r={12} fill={theme.colors.yellow3} />

      <rect
        width={18.008}
        height={16.507}
        x={11.996}
        y={4.497}
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        rx={3}
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M16.498 2.996v3.002M25.502 2.996v3.002M19.4 11.8l1.2 1.2 2-2M26.002 16.988H15.998"
      />
    </svg>
  );
};
