import React, {useCallback} from 'react';

import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Image, Upload as UploadAnt, UploadFile} from 'antd';

import {UploadIcon} from 'src/components/atoms/icon/UploadIcon';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {FileIcon} from 'src/components/atoms/icon/FileIcon';

const {Dragger} = UploadAnt;

const Text = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray8};
  margin-right: ${({theme}) => theme.margins.xxs};
`;

const TextCta = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.primary5};
`;

const StyledDragger = styled(Dragger)`
  background-color: ${({theme}) => theme.colors.primary5};
  border-radius: ${({theme}) => theme.radius.lg};
`;

const UploadItemContainer = styled.div`
  display: inline-block;
  align-items: center;
  padding: ${({theme}) => theme.paddings.xs};
  border-radius: ${({theme}) => theme.radius.max};
  background-color: ${({theme}) => theme.colors.gray2};
  justify-content: center;
  margin-top: ${({theme}) => theme.margins.ssm};
  margin-right: ${({theme}) => theme.margins.xs};
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Preview = styled(Image)`
  border-radius: ${({theme}) => theme.radius.lg};
`;

const FileName = styled(Text)`
  margin-left: ${({theme}) => theme.margins.xs};
`;

interface UploadProps extends React.ComponentProps<typeof Dragger> {
  onDeleteUploadedFile?: () => void;
}

export const Upload: React.FC<UploadProps> = ({onDeleteUploadedFile, ...rest}) => {
  // Translation
  const {t} = useTranslation('common');

  // Theme
  const theme = useTheme();

  // Callbacks
  const handleRemoveClick = useCallback(
    (
      file: UploadFile,
      actions: {
        download: () => void;
        preview: () => void;
        remove: () => void;
      },
    ) => {
      if ('id' in file && !file.originFileObj) {
        // File already uploaded
        onDeleteUploadedFile?.();
      }
      actions.remove();
    },
    [onDeleteUploadedFile],
  );

  const renderUploadItem = useCallback(
    (
      _originNode: React.ReactElement,
      file: UploadFile,
      _fileList: Array<UploadFile>,
      actions: {
        download: () => void;
        preview: () => void;
        remove: () => void;
      },
    ) => {
      return (
        <UploadItemContainer>
          <ContentContainer onClick={() => handleRemoveClick(file, actions)}>
            {file.type === 'application/pdf' ? (
              <FileIcon />
            ) : file.originFileObj ? (
              <Preview
                src={URL.createObjectURL(file.originFileObj)}
                width={parseInt(theme.heights.avatar.xxs, 10)}
                height={parseInt(theme.heights.avatar.xxs, 10)}
                preview={false}
              />
            ) : (
              <Preview
                src={file.url}
                width={parseInt(theme.heights.avatar.xxs, 10)}
                height={parseInt(theme.heights.avatar.xxs, 10)}
                preview={false}
              />
            )}

            <FileName>{file.name}</FileName>

            <CloseIcon />
          </ContentContainer>
        </UploadItemContainer>
      );
    },
    [theme.heights.avatar.xxs, handleRemoveClick],
  );

  return (
    <StyledDragger itemRender={renderUploadItem} {...rest}>
      <p className="ant-upload-drag-icon">
        <UploadIcon />
      </p>

      <Text>{t('upload.text')}</Text>

      <TextCta>{t('upload.cta')}</TextCta>
    </StyledDragger>
  );
};
