import React, {useCallback, useMemo, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';

import {DishChangedReason} from 'src/interfaces/api/generated.interface';

import {DayPicker} from 'src/components/molecules/calendar/DayPicker';
import {MonthYearPicker} from 'src/components/molecules/calendar/MonthYearPicker';
import {PastMealDetails} from 'src/components/organisms/meal/PastMealDetails';
import {ComingMealDetails} from 'src/components/organisms/meal/ComingMealDetails';
import {EmptyIllustrationCta} from 'src/components/organisms/empty/EmptyIllustrationCta';
import {paths} from 'src/navigation/paths';

import {useMealQuery} from 'src/hooks/queries/useMealQuery';
import {getClosestWasteValue} from 'src/utils/challenges';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledMonthPicker = styled(MonthYearPicker)`
  margin-bottom: ${({theme}) => theme.margins.md};
  z-index: 1;
`;

const StyledComingMealDetails = styled(ComingMealDetails)`
  margin-top: ${({theme}) => theme.margins.md};
`;

const StyledPastMealDetails = styled(PastMealDetails)`
  margin-top: ${({theme}) => theme.margins.md};
`;

export default function HomePage() {
  // Translation
  const {t} = useTranslation('home-page');

  // Navigate
  const navigate = useNavigate();

  // State
  const [selectedDay, setSelectedDay] = useState(new Date());

  // Queries
  const {data: meal} = useMealQuery({date: selectedDay});
  console.log('🚀 ~ HomePage ~ meal:', meal);

  // Constants
  const nbStudentChangedChoice = useMemo(
    () => meal?.dishRates?.filter(dishRate => dishRate.dishChangedReason)?.length ?? 0,
    [meal],
  );

  const nbStudentChangedChoiceNotAvailable = useMemo(
    () =>
      meal?.dishRates?.filter(
        dishRate => dishRate.dishChangedReason === DishChangedReason.NOT_AVAILABLE,
      )?.length ?? 0,
    [meal],
  );

  // Callbacks
  const handlePlanMeal = useCallback(() => {
    navigate(paths.chef.meals.pattern);
  }, [navigate]);

  return (
    <Container>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <StyledMonthPicker selectedDate={selectedDay} onChangeDate={setSelectedDay} />

      <DayPicker selectedDay={selectedDay} onChangeDay={setSelectedDay} />

      {!meal || meal.publicHoliday ? (
        <EmptyIllustrationCta
          title={t('empty.title')}
          description={t('empty.description')}
          buttonText={t('empty.button')}
          onClick={handlePlanMeal}
        />
      ) : moment(meal?.date).isBefore(moment()) ? (
        <StyledComingMealDetails
          date={selectedDay}
          menu={meal?.menu}
          nbAbsents={meal?.absences?.length ?? 0}
          nbParticipants={meal?.studentsVotes?.length ?? 0}
          total={meal?.totalParticipants ?? 0}
          studentsVotes={meal?.studentsVotes ?? []}
        />
      ) : (
        <StyledPastMealDetails
          mealId={meal?.id}
          menu={meal?.menu}
          nbAbsents={meal?.absences?.length ?? 0}
          nbChangedChoice={nbStudentChangedChoice}
          nbVotes={meal?.dishRates?.length ?? 0}
          nbParticipants={meal?.studentsVotes?.length ?? 0}
          nbChangedChoiceNotAvailable={nbStudentChangedChoiceNotAvailable}
          nbConfirmedChoices={meal?.totalParticipants - nbStudentChangedChoice ?? 0}
          total={meal?.totalParticipants ?? 0}
          studentsRates={meal?.dishRates ?? []}
          noisePollutionValue={meal?.weather?.noisePollutionValue}
          wasteValue={
            meal?.weather?.wasteValue ??
            getClosestWasteValue(
              meal?.weather?.totalWaste,
              meal?.weather?.totalParticipants,
            )
          }
        />
      )}
    </Container>
  );
}
