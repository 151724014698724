import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const CalendarIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg
      width={width ?? size}
      height={height ?? size}
      viewBox="0 0 20 21"
      fill="none"
      {...rest}>
      <g
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        clipPath="url(#a)">
        <path d="M2.577 8.774H17.43M13.702 12.029h.007M10.004 12.029h.008M6.298 12.029h.008M13.702 15.268h.007M10.004 15.268h.008M6.298 15.268h.008M13.37 2.604v2.742M6.638 2.604v2.742" />

        <path
          d="M13.532 3.92H6.476C4.029 3.92 2.5 5.283 2.5 7.79v7.54c0 2.546 1.529 3.94 3.976 3.94h7.048c2.455 0 3.976-1.37 3.976-3.877V7.79c.008-2.506-1.513-3.87-3.968-3.87Z"
          clipRule="evenodd"
        />
      </g>

      <defs>
        <clipPath id="a">
          <path fill={theme.colors.white} d="M0 .938h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
