import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const HomeIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width ?? size} height={height ?? size} fill="none" {...rest}>
      <g clipPath="url(#a)">
        <path
          stroke={color ?? theme.colors.gray8}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={strokeWidth}
          d="M7.63 18.247V15.69c0-.65.53-1.178 1.187-1.182h2.405c.66 0 1.195.53 1.195 1.182v2.564a1.02 1.02 0 0 0 1.002 1.016h1.604c1.598 0 2.894-1.283 2.894-2.866v-7.27a2.033 2.033 0 0 0-.802-1.587L11.63 3.175a2.65 2.65 0 0 0-3.287 0l-5.46 4.381c-.499.379-.795.964-.8 1.587v7.262c0 1.583 1.295 2.866 2.893 2.866h1.604a1.03 1.03 0 0 0 1.034-1.024"
        />
      </g>

      <defs>
        <clipPath id="a">
          <path fill={theme.colors.white} d="M0 .938h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
