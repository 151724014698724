import React from 'react';

import {Helmet} from 'react-helmet-async';
import {Avatar, Divider, Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';

import {EmptyDetails} from 'src/components/molecules/empty/EmptyDetails';
import {CalendarInfoIcon} from 'src/components/atoms/icon/CalendarInfoIcon';
import {CompassInfoIcon} from 'src/components/atoms/icon/CompassInfoIcon';
import {StrengthInfoIcon} from 'src/components/atoms/icon/StrengthInfoIcon';
import {ThumbUpInfoIcon} from 'src/components/atoms/icon/ThumbUpInfoIcon';
import {Button} from 'src/components/atoms/button/Button';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';

import {useFoodQuery} from 'src/hooks/queries/useFoodQuery';

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const Text = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
`;

const InfoTitle = styled.text`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

const InfoContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: ${({theme}) => theme.margins.sm};
`;

const InfoValue = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
`;

const InfoFieldsContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-left: ${({theme}) => theme.margins.ssm};
  width: 100%;
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export default function FoodDetailsPage() {
  // Translation
  const {t} = useTranslation(['food-details-page', 'common']);

  // Theme
  const theme = useTheme();

  // Navigate
  const navigate = useNavigate();

  // Extract dishId from URL
  const {id: foodId} = useParams();

  // Query
  const {data: food, error} = useFoodQuery(
    {foodId: Number(foodId!)},
    {enabled: !!foodId},
  );

  const handleCancel = () => {
    navigate(-1);
  };

  if (!food) {
    return <EmptyDetails details={error?.message} />;
  }

  return (
    <>
      <Helmet>
        <title>{t('food-details-page:helmet')}</title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        footer={[
          <FooterContainer key="back">
            <Button onClick={handleCancel}>{t('common:buttons.back')}</Button>
          </FooterContainer>,
        ]}>
        <Avatar size={parseInt(theme.heights.avatar.xs, 10)} src={food.image?.url} />

        <Title>{food.name}</Title>

        <Divider />

        <Text>{food.description}</Text>

        <InfoContainer>
          <CalendarInfoIcon />

          <InfoFieldsContainer>
            <InfoTitle>{t('food-details-page:sections.seasonality')}</InfoTitle>

            <InfoValue>{food.seasonality}</InfoValue>
          </InfoFieldsContainer>
        </InfoContainer>

        <InfoContainer>
          <CompassInfoIcon />

          <InfoFieldsContainer>
            <InfoTitle>{t('food-details-page:sections.origin')}</InfoTitle>

            <InfoValue>{food.origin}</InfoValue>
          </InfoFieldsContainer>
        </InfoContainer>

        <InfoContainer>
          <StrengthInfoIcon />

          <InfoFieldsContainer>
            <InfoTitle>{t('food-details-page:sections.nutritionalIntake')}</InfoTitle>

            <InfoValue>{food.nutritionalIntake}</InfoValue>
          </InfoFieldsContainer>
        </InfoContainer>

        <InfoContainer>
          <ThumbUpInfoIcon />

          <InfoFieldsContainer>
            <InfoTitle>{t('food-details-page:sections.benefits')}</InfoTitle>

            <InfoValue>{food.benefits}</InfoValue>
          </InfoFieldsContainer>
        </InfoContainer>

        <Divider />
      </Modal>
    </>
  );
}
