import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';

import * as config from 'src/services/localization/config';

const date = {
  /**
   * Load library, setting its initial locale
   *
   * @param {string} locale
   * @return Promise
   */
  async init(locale: config.Locale): Promise<void> {
    // Load translations
    await config.supportedLocales[locale].libraries.momentLoader();

    // Set locale
    moment.locale(locale);
    dayjs.locale(locale);
  },

  /**
   * @param {Date} value
   * @param {string} format
   * @return {string}
   */
  format(value: Date, format: string | undefined): string {
    return moment(value).format(format);
  },
};

export default date;
