import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const NoiseIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      fill="none"
      viewBox="0 0 20 21"
      {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M3.982 12.04A2.969 2.969 0 0 0 2.6 15.382a2.94 2.94 0 0 0 2.73 2.18c.785.034 1.376-.712 1.194-1.478l-.786-3.28c-.185-.774-1.066-1.163-1.756-.764ZM16.017 12.04a2.969 2.969 0 0 1 1.383 3.342 2.94 2.94 0 0 1-2.73 2.18c-.785.034-1.377-.712-1.194-1.478l.785-3.28c.185-.774 1.067-1.163 1.756-.764Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M16.354 12.265v-1.6a6.354 6.354 0 0 0-12.708 0v1.6"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m9.992 14.128 1.223-2.246h-2.43l1.22-2.247"
      />
    </svg>
  );
};
