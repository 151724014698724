import React, {useCallback, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {Col, Row} from 'antd';
import styled from 'styled-components';

import {WeekMeals} from 'src/interfaces/api/meal.interface';

import {WeeksMenu} from 'src/components/organisms/menu/WeeksMenu';
import {WeekMealsForm} from 'src/components/organisms/form/WeekMealsForm';

import {useNextWeeksMealsQuery} from 'src/hooks/queries/useNextWeeksMealsQuery';
import {useEntityEatingDaysQuery} from 'src/hooks/queries/useEntityEatingDaysQuery';

const Container = styled(Row)`
  display: flex;
  height: 100%;
`;

const FormContainer = styled(Col)`
  padding-left: ${({theme}) => theme.paddings.lg};
`;

export default function MealsPage() {
  // Translation
  const {t} = useTranslation('meals-page');

  // State
  const [selectedWeek, setSelectedWeek] = useState<WeekMeals | null>(null);

  // Query
  const {data: meals} = useNextWeeksMealsQuery({});
  const {data: entityEatingDays} = useEntityEatingDaysQuery();

  // Callbacks
  const handleSelectWeek = useCallback((week: WeekMeals) => {
    setSelectedWeek(week);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <Container>
        <WeeksMenu
          selectedItem={selectedWeek ?? meals?.[0] ?? null}
          onSelectItem={handleSelectWeek}
          data={meals ?? []}
        />

        <FormContainer span={19}>
          <WeekMealsForm
            datum={selectedWeek ?? meals?.[0] ?? null}
            eatingDays={entityEatingDays?.eatingDays}
          />
        </FormContainer>
      </Container>
    </>
  );
}
