import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {GetDishes, GetDishesResponse} from 'src/interfaces/api/dish.interface';

import {networkClient} from 'src/services/client';

export function useDishesQuery(
  {page, search, publicDishes, types}: GetDishes,
  options?: UseQueryOptions<GetDishesResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.Dishes, page, search, publicDishes, types],
    queryFn: async (): Promise<GetDishesResponse | null> => {
      const {data} = await networkClient.get(Routes.Dishes, {
        params: {page: page ?? 1, search, publicDishes: publicDishes ?? true, types},
      });

      return data;
    },
    keepPreviousData: true,
    ...options,
  });
}
