import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const CompassInfoIcon = ({
  width = 33,
  height = 24,
  strokeWidth,
  color,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width} height={height} fill="none" {...rest}>
      <circle cx={12} cy={12} r={12} fill={theme.colors.green5} />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M21 21c4.95 0 9-4.05 9-9s-4.05-9-9-9-9 4.05-9 9 4.05 9 9 9Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m19.236 9.553 4.928-1.327a.5.5 0 0 1 .613.612L23.47 13.74a1 1 0 0 1-.706.708l-4.928 1.327a.5.5 0 0 1-.613-.612l1.307-4.902a1 1 0 0 1 .706-.708v0Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M20.999 11.75a.25.25 0 1 0 .002.5.25.25 0 0 0-.002-.5M21 3v1.5M21 21v-1.5M30 12h-1.5M12 12h1.5"
      />
    </svg>
  );
};
