import React, {useCallback, useState} from 'react';

import {Checkbox, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {
  ReportFoodType,
  ReportFoodWithScaleForm,
  ReportFoodWithoutScaleForm,
} from 'src/interfaces/weather.interface';

import {Separator} from 'src/components/atoms/separator/Separator';
import {ReportFoodForm} from 'src/components/organisms/form/ReportFoodForm';
import {Button} from 'src/components/atoms/button/Button';

import useSnackBar from 'src/hooks/useSnackBar';
import {useReportWeatherMutation} from 'src/hooks/mutations/useReportWeatherMutation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSeparator = styled(Separator)`
  margin: ${({theme}) => theme.margins.sm} 0;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
  text-align: center;
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const LearnMore = styled.span`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray8};
  text-decoration: underline;
  margin-top: ${({theme}) => theme.margins.sm};
  text-align: center;
`;

const ReportingTypeRow = styled(Row)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

interface ReportFoodWasteTabProps {
  mealId: number;
  onLearnMoreClick: () => void;
}

export const ReportFoodWasteTab: React.FC<ReportFoodWasteTabProps> = ({
  mealId,
  onLearnMoreClick,
}) => {
  // Translation
  const {t} = useTranslation(['common', 'report-weather-page']);

  // Snack bar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // State
  const [formType, setFormType] = useState<ReportFoodType>(ReportFoodType.WithScale);

  // Mutation
  const {mutateAsync: reportWeather, isLoading} = useReportWeatherMutation();

  // Callbacks
  const handleFinish = useCallback(
    async (values: ReportFoodWithScaleForm | ReportFoodWithoutScaleForm) => {
      try {
        if ('wasteValue' in values) {
          await reportWeather({
            mealId,
            wasteValue: values.wasteValue,
          });
        } else if ('totalWaste' in values && 'totalParticipants' in values) {
          await reportWeather({
            mealId,
            totalWaste: Number(values.totalWaste),
            totalParticipants: Number(values.totalParticipants),
          });
        }
        showSuccessSnackBar(t('report-weather-page:food-waste.success.reported'));
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [showErrorSnackBar, reportWeather, t, showSuccessSnackBar, mealId],
  );

  return (
    <Container>
      <Button type="link" onClick={onLearnMoreClick}>
        <LearnMore>{t('report-weather-page:food-waste.learn-more.link')}</LearnMore>
      </Button>

      <StyledSeparator />

      <Title>{t('report-weather-page:food-waste.title')}</Title>

      <ReportingTypeRow justify="center">
        <Checkbox
          checked={formType === ReportFoodType.WithScale}
          onChange={() => setFormType(ReportFoodType.WithScale)}>
          {t('report-weather-page:food-waste.with-scale')}
        </Checkbox>

        <Checkbox
          checked={formType === ReportFoodType.WithoutScale}
          onChange={() => setFormType(ReportFoodType.WithoutScale)}>
          {t('report-weather-page:food-waste.without-scale')}
        </Checkbox>
      </ReportingTypeRow>

      <ReportFoodForm isLoading={isLoading} type={formType} onFinish={handleFinish} />
    </Container>
  );
};
