import React from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({theme}) => theme.margins.md};
  background-color: ${({theme}) => theme.colors.beige};
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.h3};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.xl};
`;

const SubTitle = styled.span`
  ${({theme}) => theme.textStyles.h5};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.lg};
`;

const Description = styled.span`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.xl};
  white-space: pre-line;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.margins.md};
`;

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function CGUChefPage() {
  // Translation
  const {t} = useTranslation('cgu-chef-page');

  return (
    <Container>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <Title>{t('title')}</Title>

      <ContentContainer>
        <SubTitle>{t('1.title')}</SubTitle>

        <Description>{t('1.description')}</Description>

        <SubTitle>{t('2.title')}</SubTitle>

        <Description>{t('2.description')}</Description>

        <SubTitle>{t('3.title')}</SubTitle>

        <Description>{t('3.description')}</Description>

        <SubTitle>{t('4.title')}</SubTitle>

        <Description>{t('4.description')}</Description>

        <SubTitle>{t('5.title')}</SubTitle>

        <Description>{t('5.description')}</Description>

        <SubTitle>{t('6.title')}</SubTitle>

        <Description>{t('6.description')}</Description>

        <SubTitle>{t('7.title')}</SubTitle>

        <Description>{t('7.description')}</Description>
      </ContentContainer>
    </Container>
  );
}
