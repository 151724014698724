import React, {useCallback, useEffect, useState} from 'react';

import styled, {useTheme} from 'styled-components';
import {List, Space} from 'antd';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

import {EatingDay, Menu} from 'src/interfaces/api/generated.interface';
import {WeekMealForm} from 'src/interfaces/meal.interface';
import {WeekMeals} from 'src/interfaces/api/meal.interface';
import {Routes} from 'src/interfaces/api/api.interface';

import {MealForm} from 'src/components/organisms/form/MealForm';
import {Button} from 'src/components/atoms/button/Button';
import {CircleCheckIcon} from 'src/components/atoms/icon/CircleCheckIcon';
import {ConfirmPublishMealsModal} from 'src/components/organisms/modal/ConfirmPublishMealsModal';

import {useCreateMealMutation} from 'src/hooks/mutations/useCreateMealMutation';
import {usePublishMealMutation} from 'src/hooks/mutations/usePublishMealMutation';
import useSnackBar from 'src/hooks/useSnackBar';
import {getDayNbFromEatingDay} from 'src/utils/day';
import {queryClient} from 'src/services/client';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({theme}) => theme.radius.lg};
  border: 1px solid ${({theme}) => theme.colors.gray4};
  padding: ${({theme}) => theme.paddings.lg};
  margin-bottom: ${({theme}) => theme.margins.md};
`;

const HeaderContainer = styled(Space)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface WeekMealsFormProps {
  datum: WeekMeals | null | undefined;
  eatingDays: EatingDay[] | undefined;
}

export const WeekMealsForm: React.FC<WeekMealsFormProps> = ({datum, eatingDays}) => {
  // Translation
  const {t} = useTranslation('meals-page');

  // Theme
  const theme = useTheme();

  // Snackbar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // State
  const [weekMeals, setWeekMeals] = useState<WeekMealForm[]>([]);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  // Mutation
  const {mutateAsync: createMeal} = useCreateMealMutation();
  const {mutateAsync: publishMeal} = usePublishMealMutation();

  // Effects
  useEffect(() => {
    if (eatingDays?.length && datum) {
      setWeekMeals(
        eatingDays.map(day => {
          const meal = datum?.meals.find(item => item.day === day);
          return {
            date: moment(datum?.startDate)
              .isoWeekday(getDayNbFromEatingDay(day))
              .toDate(),
            mealId: meal?.id,
            menu: meal?.menu,
            publicHoliday: meal?.publicHoliday ?? false,
          };
        }),
      );
    }
  }, [datum?.meals, datum?.startDate, eatingDays, weekMeals?.length, datum]);

  // Callbacks
  const handlePublicHolidayChange = useCallback(
    (weekMeal: WeekMealForm) => {
      setWeekMeals(
        weekMeals.map(item =>
          item.date === weekMeal.date
            ? {...item, publicHoliday: !item.publicHoliday, menu: undefined}
            : item,
        ),
      );
    },
    [weekMeals],
  );

  const handleMenuChange = useCallback(
    (weekMeal: WeekMealForm, menu: Menu | undefined) => {
      setWeekMeals(
        weekMeals.map(item => (item.date === weekMeal.date ? {...item, menu} : item)),
      );
    },
    [weekMeals],
  );

  const renderMealForm = useCallback(
    (item: WeekMealForm, index: number) => {
      return (
        <MealForm
          item={item}
          isLast={index === (eatingDays?.length ?? 0) - 1}
          onPublicHolidayChange={() => handlePublicHolidayChange(item)}
          onMenuChange={menu => handleMenuChange(item, menu)}
          published={datum?.published}
        />
      );
    },
    [eatingDays?.length, datum?.published, handlePublicHolidayChange, handleMenuChange],
  );

  const handleSaveDraft = useCallback(async () => {
    try {
      const isComplete = weekMeals.every(item => item.menu ?? item.publicHoliday);
      if (!isComplete) {
        showErrorSnackBar(t('errors.invalid-week'));
        return;
      }
      for (const item of weekMeals) {
        await createMeal({
          date: item.date,
          menuId: item.menu?.id,
          publicHoliday: item.publicHoliday,
        });
      }
      showSuccessSnackBar(t('success.saved-draft'));
    } catch (error) {
      showErrorSnackBar(t('errors.save-draft', {error}));
    }
  }, [createMeal, weekMeals, t, showErrorSnackBar, showSuccessSnackBar]);

  const handlePublishMeals = useCallback(async () => {
    try {
      setIsConfirmModalOpened(false);
      const isComplete = weekMeals.every(item => item.menu ?? item.publicHoliday);
      if (!isComplete) {
        showErrorSnackBar(t('errors.invalid-week'));
        return;
      }
      for (const item of weekMeals) {
        if (item?.mealId) {
          await publishMeal(item.mealId);
        } else {
          await createMeal({
            date: item.date,
            menuId: item.menu?.id,
            publicHoliday: item.publicHoliday,
            published: true,
          });
        }
      }
      queryClient.invalidateQueries([Routes.NextWeeksMeals]);
      showSuccessSnackBar(t('success.published'));
    } catch (error) {
      showErrorSnackBar(t('errors.publish-meals', {error}));
    }
  }, [publishMeal, weekMeals, t, showErrorSnackBar, createMeal, showSuccessSnackBar]);

  const handleShowConfirmModal = useCallback(() => {
    setIsConfirmModalOpened(true);
  }, []);

  return (
    <Container>
      <HeaderContainer size={parseInt(theme.paddings.lg, 10)}>
        <Button
          type="default"
          size="medium"
          onClick={handleSaveDraft}
          disabled={datum?.published}>
          {t('buttons.save-draft')}
        </Button>

        <Button
          type="primary"
          size="medium"
          disabled={datum?.published}
          icon={
            <CircleCheckIcon
              stroke="white"
              color={datum?.published ? theme.colors.gray5 : undefined}
              size={16}
              strokeWidth={1.5}
            />
          }
          onClick={handleShowConfirmModal}>
          {t('buttons.publish-meals')}
        </Button>
      </HeaderContainer>

      <List dataSource={weekMeals} renderItem={renderMealForm} />

      <ConfirmPublishMealsModal
        startDate={datum?.startDate}
        endDate={datum?.endDate}
        isVisible={isConfirmModalOpened}
        onCancel={() => setIsConfirmModalOpened(false)}
        onConfirm={handlePublishMeals}
      />
    </Container>
  );
};
