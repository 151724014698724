import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';

import {networkClient} from 'src/services/client';

export function useForgotPasswordMutation() {
  return useMutation({
    mutationFn: async (identifier: string): Promise<boolean> => {
      const {data} = await networkClient.post<boolean>(Routes.ForgotPassword, {
        identifier,
      });

      return data;
    },
  });
}
