import React from 'react';

import {useTranslation} from 'react-i18next';
import {Divider, Modal} from 'antd';
import styled, {useTheme} from 'styled-components';
import moment from 'moment';

import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {ListIcon} from 'src/components/atoms/icon/ListIcon';
import {Button} from 'src/components/atoms/button/Button';

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const Description = styled.span`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

interface ConfirmPublishMealsModalProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  isVisible: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmPublishMealsModal: React.FC<ConfirmPublishMealsModalProps> = ({
  isVisible,
  startDate,
  endDate,
  onCancel,
  onConfirm,
}) => {
  // Theme
  const theme = useTheme();

  // Translation
  const {t} = useTranslation(['meals-page', 'common']);

  return (
    <Modal
      open={isVisible}
      onCancel={onCancel}
      closeIcon={<CloseIcon />}
      width={parseInt(theme.widths.confirmPublishMealsModal.default, 10)}
      footer={<></>}>
      <HeaderContainer>
        <ListIcon color={theme.colors.primary5} size={24} />

        <Title>
          {t('meals-page:confirmation-modal.title', {
            startDate: moment(startDate).format('DD/MM/YYYY'),
            endDate: moment(endDate).format('DD/MM/YYYY'),
          })}
        </Title>
      </HeaderContainer>

      <Divider />

      <Description>{t('meals-page:confirmation-modal.description')}</Description>

      <Divider />

      <FooterContainer>
        <BackButton block key="back" onClick={onCancel}>
          {t('common:buttons.back')}
        </BackButton>

        <Button type="primary" htmlType="submit" block onClick={onConfirm}>
          {t('meals-page:confirmation-modal.buttons.confirm')}
        </Button>
      </FooterContainer>
    </Modal>
  );
};
