import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const CircleCheckIcon = ({
  width = 19,
  height = 18,
  size,
  color,
  fill,
  stroke,
  strokeWidth,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      fill="none"
      viewBox="0 0 19 18"
      {...rest}>
      <rect
        width={height - 1}
        height={height - 1}
        x={1.375}
        y={0.5}
        fill={color ?? fill ?? theme.colors.primary5}
        stroke={color ?? stroke ?? theme.colors.primary5}
        strokeWidth={strokeWidth ?? 1}
        rx={8.5}
      />

      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14.083 7.28 9.465 11.9a.746.746 0 0 1-1.061 0l-2.31-2.31a.75.75 0 0 1 1.06-1.06l1.78 1.779 4.088-4.088a.75.75 0 0 1 1.06 1.06Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
