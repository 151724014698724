import React from 'react';

import styled from 'styled-components';
import {Input as AntInput} from 'antd';

const StyledInput = styled(AntInput)`
  border-radius: ${({theme}) => theme.radius.lg};
  background-color: ${({theme}) => theme.colors.white};
  height: ${({theme}) => theme.heights.input.default};
`;

const StyledInputPassword = styled(AntInput.Password)`
  border-radius: ${({theme}) => theme.radius.lg};
  background-color: ${({theme}) => theme.colors.white};
  height: ${({theme}) => theme.heights.input.default};
`;

interface InputProps extends React.ComponentProps<typeof AntInput> {
  isPassword?: boolean;
}

export const Input: React.FC<InputProps> = ({isPassword, ...rest}) => {
  return isPassword ? <StyledInputPassword {...rest} /> : <StyledInput {...rest} />;
};
