import React, {useCallback, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Divider, Modal} from 'antd';
import styled, {useTheme} from 'styled-components';

import {ChallengeType} from 'src/interfaces/meal.interface';

import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {TrophyIcon} from 'src/components/atoms/icon/TrophyIcon';
import {ReportFoodWasteTab} from 'src/components/organisms/tabs/ReportFoodWasteTab';
import {ReportNoisePollutionTab} from 'src/components/organisms/tabs/ReportNoisePollutionTab';
import {ArrowLeftIcon} from 'src/components/atoms/icon/ArrowLeftIcon';

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ChallengeTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({theme}) => theme.colors.beige};
  height: ${({theme}) => theme.heights.foodTabBar.default};
  border-radius: ${({theme}) => theme.radius.md};
  padding: ${({theme}) => theme.paddings.xxxs};
  margin-top: ${({theme}) => theme.margins.sm};
`;

const ChallengeTypeItem = styled.div<{active: boolean}>`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: ${({theme}) => theme.paddings.md};
  background-color: ${({theme, active}) =>
    active ? theme.colors.primary5 : 'transparent'};
  width: 100%;
  border-radius: ${({theme}) => theme.radius.md};
  margin-right: ${({theme}) => theme.margins.xs};
`;

const ChallengeTypeName = styled.span<{active: boolean; isLast?: boolean}>`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme, active}) => (active ? theme.colors.white : theme.colors.gray6)};
  margin-left: ${({theme, isLast}) => (isLast ? '0px' : theme.margins.xs)};
`;

const LearnMoreText = styled.span`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
  white-space: pre-line;
`;

const LearnMoreSubtitle = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-top: ${({theme}) => theme.margins.lg};
  margin-bottom: ${({theme}) => theme.margins.sm};
  white-space: pre-line;
`;

export default function ReportWeatherPage() {
  // Translation
  const {t} = useTranslation(['common', 'report-weather-page']);

  // Theme
  const theme = useTheme();

  // Navigate
  const navigate = useNavigate();

  // State
  const [challengeType, setChallengeType] = useState(ChallengeType.FoodWaste);
  const [learnMore, setLearnMore] = useState(false);

  // Extract mealId from URL if exists
  const {mealId} = useParams();

  // Callbacks
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleLearnMoreClick = useCallback(() => {
    setLearnMore(true);
  }, []);

  const renderTab = useCallback(() => {
    switch (challengeType) {
      case ChallengeType.FoodWaste:
        return (
          <ReportFoodWasteTab
            mealId={Number(mealId)}
            onLearnMoreClick={handleLearnMoreClick}
          />
        );
      case ChallengeType.NoisePollution:
        return (
          <ReportNoisePollutionTab
            mealId={Number(mealId)}
            onLearnMoreClick={handleLearnMoreClick}
          />
        );
      default:
        return <></>;
    }
  }, [challengeType, mealId, handleLearnMoreClick]);

  const renderLearnMore = useCallback(() => {
    return (
      <>
        <HeaderContainer>
          <Button
            shape="circle"
            type="link"
            icon={<ArrowLeftIcon size={24} />}
            onClick={() => setLearnMore(false)}
          />

          <Title>
            {t(
              `report-weather-page:${
                challengeType === ChallengeType.NoisePollution
                  ? 'noise-pollution'
                  : 'food-waste'
              }.learn-more.title`,
            )}
          </Title>
        </HeaderContainer>

        <Divider />

        {challengeType === ChallengeType.NoisePollution && (
          <LearnMoreText>
            {t('report-weather-page:noise-pollution.learn-more.intro')}
          </LearnMoreText>
        )}

        <LearnMoreSubtitle>
          {t(
            `report-weather-page:${
              challengeType === ChallengeType.NoisePollution
                ? 'noise-pollution'
                : 'food-waste'
            }.learn-more.subtitle-1`,
          )}
        </LearnMoreSubtitle>

        <LearnMoreText>
          {t(
            `report-weather-page:${
              challengeType === ChallengeType.NoisePollution
                ? 'noise-pollution'
                : 'food-waste'
            }.learn-more.text-1`,
          )}
        </LearnMoreText>

        <LearnMoreSubtitle>
          {t(
            `report-weather-page:${
              challengeType === ChallengeType.NoisePollution
                ? 'noise-pollution'
                : 'food-waste'
            }.learn-more.subtitle-2`,
          )}
        </LearnMoreSubtitle>

        <LearnMoreText>
          {t(
            `report-weather-page:${
              challengeType === ChallengeType.NoisePollution
                ? 'noise-pollution'
                : 'food-waste'
            }.learn-more.text-2`,
          )}
        </LearnMoreText>

        {challengeType === ChallengeType.FoodWaste && (
          <>
            <LearnMoreSubtitle>
              {t('report-weather-page:food-waste.learn-more.subtitle-3')}
            </LearnMoreSubtitle>

            <LearnMoreText>
              {t('report-weather-page:food-waste.learn-more.text-3')}
            </LearnMoreText>
          </>
        )}
      </>
    );
  }, [t, challengeType]);

  return (
    <>
      <Helmet>
        <title>{t('report-weather-page:helmet')}</title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        width={
          learnMore
            ? parseInt(theme.widths.learnMoreWeatherModal.default, 10)
            : parseInt(theme.widths.reportWeatherModal.default, 10)
        }
        footer={<></>}>
        {learnMore ? (
          renderLearnMore()
        ) : (
          <>
            <HeaderContainer>
              <TrophyIcon />

              <Title>{t('report-weather-page:title')}</Title>
            </HeaderContainer>

            <ChallengeTypeContainer>
              <ChallengeTypeItem
                active={challengeType === ChallengeType.FoodWaste}
                onClick={() => setChallengeType(ChallengeType.FoodWaste)}>
                <ChallengeTypeName active={challengeType === ChallengeType.FoodWaste}>
                  {t('common:food-waste')}
                </ChallengeTypeName>
              </ChallengeTypeItem>

              <ChallengeTypeItem
                active={challengeType === ChallengeType.NoisePollution}
                onClick={() => setChallengeType(ChallengeType.NoisePollution)}>
                <ChallengeTypeName
                  active={challengeType === ChallengeType.NoisePollution}
                  isLast={true}>
                  {t('common:noise-pollution')}
                </ChallengeTypeName>
              </ChallengeTypeItem>
            </ChallengeTypeContainer>

            {renderTab()}
          </>
        )}
      </Modal>
    </>
  );
}
