export const heights = {
  button: {
    default: '37px',
    medium: '46px',
    big: '54px',
  },
  smallButton: {
    lg: '31px',
    sm: '25px',
    xs: '22px',
  },
  avatar: {
    xxxl: '234px',
    xxl: '168px',
    xl: '100px',
    lg: '80px',
    md: '63px',
    sm: '48px',
    xs: '40px',
    xxs: '32px',
    xxxs: '24px',
  },
  logo: {
    default: '136px',
  },
  illustrationAuth: {
    default: '607px',
  },
  input: {
    default: '46px',
  },
  foodCardImage: {
    default: '80px',
  },
  foodCard: {
    default: '114px',
  },
  allergenCircle: {
    default: '68px',
    small: '32px',
    smallest: '20px',
  },
  foodTabBar: {
    default: '43px',
    active: '35px',
  },
  menuIndicator: {
    default: '24px',
  },
  menuForm: {
    default: '404px',
    small: '332px',
  },
  smallDishCardImage: {
    default: '56px',
  },
  mealForm: {
    default: '458px',
  },
  dayPicker: {
    default: '73px',
  },
  monthPicker: {
    default: '318px',
  },
  timeRemaining: {
    default: '264px',
  },
  challengeNoResult: {
    default: '124px',
  },
  illustrationTimeRemaining: {
    default: '189px',
  },
  timeContainer: {
    default: '66px',
  },
  pastMealStatsCard: {
    default: '362px',
  },
  statContainer: {
    default: '85px',
  },
  challengeResultCard: {
    default: '173px',
  },
  chartChallenges: {
    default: '325px',
  },
};

export const widths = {
  dishCard: {
    small: '240px',
    default: '300px',
  },
  logo: {
    default: '113px',
  },
  sider: {
    default: '286px',
  },
  foodTabBar: {
    active: '154px',
  },
  menuIndicator: {
    default: '3px',
  },
  createMenuModal: {
    default: '800px',
  },
  confirmPublishMealsModal: {
    default: '684px',
  },
  dayPicker: {
    default: '107px',
  },
  monthPicker: {
    default: '244px',
  },
  illustrationTimeRemaining: {
    default: '199px',
  },
  timeContainer: {
    default: '84px',
  },
  reportWeatherModal: {
    default: '548px',
  },
  learnMoreWeatherModal: {
    default: '984px',
  },
  createEntityModal: {
    default: '684px',
  },
  createFoodModal: {
    default: '684px',
  },
};

export const aspectRatios = {
  image: 2 / 3,
  contactBackground: 205 / 259,
  dishImageCard: 2.55,
  dishDetailsImage: 2.96,
};
