import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const StarIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      fill="none"
      {...rest}
      viewBox="0 0 20 21">
      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m10.92 3.98 1.523 3.043c.15.299.437.506.771.554l3.407.49c.842.122 1.177 1.142.568 1.726l-2.464 2.367a1.003 1.003 0 0 0-.295.896l.582 3.342c.143.827-.737 1.457-1.49 1.066l-3.044-1.579a1.04 1.04 0 0 0-.956 0l-3.045 1.58c-.752.39-1.632-.24-1.488-1.067l.58-3.342a1.003 1.003 0 0 0-.294-.896L2.81 9.793c-.609-.584-.274-1.604.568-1.726l3.407-.49c.334-.048.623-.255.772-.554L9.08 3.981c.376-.753 1.464-.753 1.84 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
