import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {CreateEntityParams} from 'src/interfaces/api/entity.interface';
import {Entity} from 'src/interfaces/api/generated.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useCreateEntityMutation() {
  return useMutation<Entity, AxiosError<Error>, CreateEntityParams>({
    mutationFn: async (params: CreateEntityParams): Promise<Entity> => {
      const {data} = await networkClient.post<Entity>(Routes.AdminEntity, {
        ...params,
      });

      queryClient.invalidateQueries([Routes.Entities]);

      return data;
    },
  });
}
