import React, {useCallback} from 'react';

import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Button} from 'src/components/atoms/button/Button';

import {ReactComponent as Illustration} from 'src/assets/illustrations/contact-illustration.svg';

const Title = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.black};
  position: relative;
  white-space: pre;
`;

const Background = styled.img`
  position: absolute;
  top: ${({theme}) => theme.paddings.xl};
`;

const ButtonContainer = styled.div`
  margin-top: ${({theme}) => theme.margins.md};
  margin-bottom: ${({theme}) => theme.margins.md};
`;

const Container = styled.div`
  position: absolute;
  padding-left: ${({theme}) => theme.paddings.lg};
  padding-right: ${({theme}) => theme.paddings.lg};
  bottom: ${({theme}) =>
    (parseInt(theme.widths.sider.default, 10) - parseInt(theme.paddings.lg, 10) * 2) /
      theme.aspectRatios.contactBackground +
    parseInt(theme.paddings.lg, 10) * 2}px;
`;

const ContentContainer = styled.div`
  margin-top: ${({theme}) => theme.margins.sm};
  padding-top: ${({theme}) => theme.margins.md};
  position: absolute;
  text-align: center;
  height: ${({theme}) =>
    (parseInt(theme.widths.sider.default, 10) - parseInt(theme.paddings.lg, 10) * 2) /
    theme.aspectRatios.contactBackground}px;
  width: ${({theme}) =>
    parseInt(theme.widths.sider.default, 10) - parseInt(theme.paddings.lg, 10) * 2}px;
`;

const StyledIllustration = styled(Illustration)`
  position: relative;
  margin-top: ${({theme}) => theme.margins.xs};
`;

export const ContactCard: React.FC = () => {
  // Translation
  const {t} = useTranslation();

  // Theme
  const theme = useTheme();

  // Callbacks
  const handleContactUs = useCallback(() => {
    window.open("mailto:email@example.com?subject=Demande%20d'aide");
  }, []);

  return (
    <Container>
      <Background
        src={require('src/assets/backgrounds/contact-background.png')}
        width={
          parseInt(theme.widths.sider.default, 10) - parseInt(theme.paddings.lg, 10) * 2
        }
      />

      <ContentContainer>
        <Title>{t('help.title')}</Title>

        <ButtonContainer>
          <Button type="primary" onClick={handleContactUs}>
            {t('help.cta')}
          </Button>
        </ButtonContainer>

        <StyledIllustration width={189} height={145} />
      </ContentContainer>
    </Container>
  );
};
