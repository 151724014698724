import React from 'react';

import {Row} from 'antd';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';

import {ChallengeType} from 'src/interfaces/meal.interface';

import {TruckIcon} from 'src/components/atoms/icon/TruckIcon';
import {NoiseIcon} from 'src/components/atoms/icon/NoiseIcon';
import {Button} from 'src/components/atoms/button/Button';

const Container = styled.div`
  height: ${({theme}) => theme.heights.challengeNoResult.default};
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.lg};
  overflow: hidden;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
`;

const Description = styled.span`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray8};
`;

const StyledTruckIcon = styled(TruckIcon)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const StyledNoiseIcon = styled(NoiseIcon)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({theme}) => theme.margins.sm};
`;

interface MealChallengeResultNotAvailableCardProps
  extends React.HTMLAttributes<HTMLDivElement> {
  type: ChallengeType;
  rateButtonText?: string;
  onRateLastDay?: (type: ChallengeType) => void;
}

export const MealChallengeResultNotAvailableCard: React.FC<
  MealChallengeResultNotAvailableCardProps
> = ({type, rateButtonText, onRateLastDay, ...rest}) => {
  // Translation
  const {t} = useTranslation('home-page');

  // Theme
  const theme = useTheme();

  return (
    <Container {...rest}>
      <Row>
        {type === ChallengeType.FoodWaste ? (
          <StyledTruckIcon size={26} color={theme.colors.primary5} />
        ) : (
          <StyledNoiseIcon size={26} color={theme.colors.primary5} />
        )}

        <Title>
          {type === ChallengeType.FoodWaste
            ? t('challenge.food-waste')
            : t('challenge.noise-pollution')}
        </Title>
      </Row>

      <StyledRow wrap={false}>
        <Description>{t('challenge.no-result-yet')}</Description>

        {onRateLastDay && (
          <Button type="dashed" onClick={() => onRateLastDay(type)}>
            {rateButtonText ?? t('challenge.buttons.rate-last-day')}
          </Button>
        )}
      </StyledRow>
    </Container>
  );
};
