import React from 'react';

import styled from 'styled-components';

import {Input} from 'src/components/atoms/input/Input';
import {Button} from 'src/components/atoms/button/Button';

import {ReactComponent as Illustration} from 'src/assets/illustrations/illustration-2.svg';

const EmptyText = styled.text`
  ${({theme}) => theme.textStyles.h3};
  color: ${({theme}) => theme.colors.gray12};
`;

const EmptyTextDescription = styled.text`
  ${({theme}) => theme.textStyles.h7};
  color: ${({theme}) => theme.colors.gray10};
  margin-top: ${({theme}) => theme.margins.ssm};
`;

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 70%;
  margin: ${({theme}) => theme.margins.xxl} 0;
`;

const EmptyTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

const EmptyCtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({theme}) => theme.margins.md};
`;

const StyledInput = styled(Input)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

interface EmptyIllustrationCtaProps {
  title: string;
  description: string;
  buttonText?: string;
  input?: boolean;
  search?: string;
  onClick: () => void;
  onSearch?: (value: string) => void;
}

export const EmptyIllustrationCta: React.FC<EmptyIllustrationCtaProps> = ({
  title,
  description,
  search,
  input,
  buttonText,
  onSearch,
  onClick,
}) => {
  return (
    <EmptyContainer>
      <Illustration />

      <EmptyTextsContainer>
        <EmptyText>{title}</EmptyText>

        <EmptyTextDescription>{description}</EmptyTextDescription>

        <EmptyCtaContainer>
          {input && (
            <StyledInput
              value={search}
              onChange={event => onSearch?.(event.target.value)}
            />
          )}

          <Button type="primary" size="medium" onClick={onClick}>
            {buttonText}
          </Button>
        </EmptyCtaContainer>
      </EmptyTextsContainer>
    </EmptyContainer>
  );
};
