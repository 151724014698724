import React, {useMemo} from 'react';

import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {NutritionalScoreType} from 'src/interfaces/api/dish.interface';
import {NutritionalScoreValue} from 'src/interfaces/api/generated.interface';

import {FlashIcon} from 'src/components/atoms/icon/FlashIcon';
import {LeavesIcon} from 'src/components/atoms/icon/LeavesIcon';
import {JarIcon} from 'src/components/atoms/icon/JarIcon';
import {HeartIcon} from 'src/components/atoms/icon/HeartIcon';

interface NutritionalTagProps extends React.ComponentProps<typeof Container> {
  type: NutritionalScoreType;
  value: NutritionalScoreValue;
}

const nutritionalItems: {
  [key in NutritionalScoreType]: {name: string; icon: React.ReactNode; value: string};
} = {
  [NutritionalScoreType.Calories]: {
    name: 'nutritional-score.calories.name',
    icon: <FlashIcon />,
    value: 'nutritional-score.calories.values.',
  },
  [NutritionalScoreType.CarbonFootprint]: {
    name: 'nutritional-score.carbon-footprint.name',
    icon: <LeavesIcon />,
    value: 'nutritional-score.carbon-footprint.values.',
  },
  [NutritionalScoreType.Transformation]: {
    name: 'nutritional-score.transformation.name',
    icon: <JarIcon />,
    value: 'nutritional-score.transformation.values.',
  },
  [NutritionalScoreType.Benefits]: {
    name: 'nutritional-score.benefits.name',
    icon: <HeartIcon />,
    value: 'nutritional-score.benefits.values.',
  },
};

const Container = styled.div`
  display: flex;
  padding: ${({theme}) => theme.paddings.xs};
  border-radius: ${({theme}) => theme.radius.ssm};
  background-color: ${({theme}) => theme.colors.primary1};
  width: 100%;
  align-items: center;
`;

const InfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-left: ${({theme}) => theme.margins.xs};
`;

const Type = styled.text`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray8};
`;

const Value = styled.text`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

export const NutritionalTag: React.FC<NutritionalTagProps> = ({type, value, ...rest}) => {
  // Translation
  const {t} = useTranslation();

  // Constants
  const item = useMemo(() => nutritionalItems[type], [type]);
  return (
    <Container {...rest}>
      {item.icon}

      <InfoContainer>
        {/* @ts-ignore */}
        <Type>{t(item.name)}</Type>

        {/* @ts-ignore */}
        <Value>{t(`${item.value}${value.toLowerCase()}`)}</Value>
      </InfoContainer>
    </Container>
  );
};
