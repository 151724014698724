import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {
  GetDishRatesParams,
  GetDishRatesResponse,
} from 'src/interfaces/api/dish-rate.interface';

import {networkClient} from 'src/services/client';

export function useDishRatesQuery(
  {mealId, dishId}: GetDishRatesParams,
  options?: UseQueryOptions<GetDishRatesResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.DishRates, dishId, mealId],
    queryFn: async (): Promise<GetDishRatesResponse | null> => {
      const {data} = await networkClient.get(Routes.DishRates, {
        params: {mealId, dishId},
      });

      return data;
    },
    ...options,
  });
}
