export enum DishRateCriteria {
  Aroma = 'aroma',
  Appearance = 'appearance',
  Quantity = 'quantity',
  Temperature = 'temperature',
}

export interface Review {
  reason: string;
  nbVotes: number;
  total: number;
}
