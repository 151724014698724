import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {DeleteFoodImageParams} from 'src/interfaces/api/food.interface';
import {File} from 'src/interfaces/api/generated.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useDeleteFoodImageMutation() {
  return useMutation({
    mutationFn: async (params: DeleteFoodImageParams): Promise<File> => {
      const {data} = await networkClient.delete<File>(Routes.FoodImage, {
        data: params,
      });

      queryClient.invalidateQueries([Routes.Food, params.foodId]);

      return data;
    },
  });
}
