import React, {useCallback} from 'react';

import {Image, List} from 'antd';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';

import {DishCardSize} from 'src/interfaces/theme.interface';
import {Allergy} from 'src/interfaces/api/generated.interface';

import {AllergenTag} from 'src/components/atoms/tag/AllergenTag';
import {CutleryIcon} from 'src/components/atoms/icon/CutleryIcon';
import {StarIcon} from 'src/components/atoms/icon/StarIcon';

const _defaultSize: DishCardSize = 'small';

const Container = styled.div<{borderless?: boolean} & Pick<SmallDishCardProps, 'size'>>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: ${({theme}) => theme.paddings.xs};
  border-color: ${({theme}) => theme.colors.gray4} !important;
  border-width: ${({theme, borderless}) =>
    borderless ? '0px' : theme.borders.xs} !important;
  border-radius: ${({theme}) => theme.radius.lg} !important;
  border-style: solid !important;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: ${({theme}) => theme.colors.primary5} !important;
  }
  min-width: ${({theme, size = _defaultSize}) => theme.widths.dishCard[size]};
  background-color: ${({theme}) => theme.colors.white};
`;

const StyledListItem = styled(List.Item)`
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
`;

const StyledImage = styled(Image)`
  border-radius: ${({theme}) => theme.radius.sm};
  aspect-ratio: 1;
  min-width: ${({theme}) => theme.heights.smallDishCardImage.default};
  object-fit: cover;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

const AllergensContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({theme}) => theme.margins.xxxs};
`;

const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: ${({theme}) => theme.margins.sm};
`;

const StyledAllergenTag = styled(AllergenTag)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const Subtitle = styled.span`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray8};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const RightContainer = styled.div``;

interface SmallDishCardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'id' | 'onClick'> {
  id: number;
  name: string;
  image?: string;
  allergens?: Allergy[];
  nbStudentsChoice?: number;
  rightComponent?: React.ReactNode | ((id: number) => React.ReactNode);
  onClick?: (foodId: number) => void;
  borderless?: boolean;
  size?: DishCardSize;
  rating?: number;
}

export const SmallDishCard: React.FC<SmallDishCardProps> = ({
  id,
  image,
  name,
  allergens,
  rightComponent,
  nbStudentsChoice,
  rating,
  borderless = false,
  size,
  onClick,
  ...rest
}) => {
  // Theme
  const theme = useTheme();

  // Translation
  const {t} = useTranslation('home-page');

  // Callbacks
  const handleItemClick = useCallback(() => {
    onClick?.(id);
  }, [id, onClick]);

  return (
    <StyledListItem onClick={handleItemClick}>
      <Container borderless={borderless} size={size} {...rest}>
        <StyledImage
          src={image}
          preview={false}
          height={parseInt(theme.heights.smallDishCardImage.default, 10)}
        />

        <ContentContainer>
          <Title>{name}</Title>

          <AllergensContainer>
            {allergens ? (
              allergens.map(item => (
                <StyledAllergenTag key={item} type={item} size="smallest" />
              ))
            ) : nbStudentsChoice !== undefined ? (
              <>
                <CutleryIcon color={theme.colors.primary5} />

                <Subtitle>{nbStudentsChoice}</Subtitle>
              </>
            ) : rating !== undefined ? (
              <>
                <StarIcon />

                <Subtitle>{t('dish-rate', {rate: rating})}</Subtitle>
              </>
            ) : null}
          </AllergensContainer>
        </ContentContainer>

        <RightContainer>
          {typeof rightComponent === 'function' ? rightComponent(id) : rightComponent}
        </RightContainer>
      </Container>
    </StyledListItem>
  );
};
