import React, {useCallback, useMemo} from 'react';

import {Divider, Form, Row, Select} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {CreateDishInfo} from 'src/interfaces/api/dish.interface';
import {Dish, DishType} from 'src/interfaces/api/generated.interface';

import {Input} from 'src/components/atoms/input/Input';
import {Button} from 'src/components/atoms/button/Button';
import {Mark} from 'src/components/atoms/mark/Mark';
import {Upload} from 'src/components/atoms/input/Upload';

import {getDishTypeName} from 'src/utils/dish';
import {settings} from 'src/constants/settings';

const StyledFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
`;

const StyledFormItemMargin = styled(StyledFormItem)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

interface DishInfoFormProps
  extends Omit<React.ComponentProps<typeof Form<CreateDishInfo>>, 'values' | 'onFinish'> {
  dish?: Dish | null;
  onFinish: (values: CreateDishInfo) => void;
  onCancel: () => void;
}

export const DishInfoForm: React.FC<DishInfoFormProps> = ({
  dish,
  onFinish,
  onCancel,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation(['create-dish-page', 'common']);

  // Constants
  const types = useMemo(
    () =>
      Object.values(DishType).map(type => ({value: type, label: getDishTypeName(type)})),
    [],
  );

  const renderMark = useCallback((label: React.ReactNode, info: {required: boolean}) => {
    return <Mark label={label} info={info} />;
  }, []);

  return (
    <Form layout="vertical" onFinish={onFinish} requiredMark={renderMark} {...rest}>
      <Form.Item
        name="image"
        label={t('create-dish-page:fields.image')}
        initialValue={dish?.image}
        rules={[
          {
            required: true,
            message: t('create-dish-page:errors.image-required'),
          },
          {
            validator: (_, value) => {
              if (!value || 'id' in value) {
                return Promise.resolve();
              }
              if (value.file.size / 1024 / 1024 > settings.dish.maxFileSize.image) {
                return Promise.reject(
                  new Error(
                    t('create-dish-page:errors.image-too-big', {
                      maxSize: settings.dish.maxFileSize.image,
                    }),
                  ),
                );
              }
              const isImg =
                value.file.type === 'image/jpeg' ||
                value.file.type === 'image/jpg' ||
                value.file.type === 'image/png';

              if (!isImg) {
                return Promise.reject(
                  new Error(t('create-dish-page:errors.image-invalid-format')),
                );
              }

              return Promise.resolve();
            },
          },
        ]}>
        <Upload
          multiple={false}
          maxCount={1}
          defaultFileList={
            dish?.image ? [{...dish.image, uid: dish.id.toString()}] : undefined
          }
        />
      </Form.Item>

      <Row wrap={false}>
        <StyledFormItemMargin
          label={t('create-dish-page:fields.name')}
          name="name"
          initialValue={dish?.name}
          rules={[
            {
              required: true,
              message: t('create-dish-page:errors.name-required'),
            },
          ]}>
          <StyledInput
            defaultValue={dish?.name}
            placeholder={t('create-dish-page:placeholders.name')}
          />
        </StyledFormItemMargin>

        <StyledFormItem
          label={t('create-dish-page:fields.type')}
          name="type"
          initialValue={dish?.type}
          rules={[
            {
              required: true,
              message: t('create-dish-page:errors.type-required'),
            },
          ]}>
          <Select
            placeholder={t('create-dish-page:placeholders.type')}
            filterOption={false}
            options={types}
            defaultValue={dish?.type}
          />
        </StyledFormItem>
      </Row>

      <Form.Item
        name="documents"
        label={t('create-dish-page:fields.technical-documentation')}
        rules={[
          {
            validator: (_, value) => {
              if (!value) {
                return Promise.resolve();
              }
              if (value.file.size / 1024 / 1024 > settings.dish.maxFileSize.document) {
                return Promise.reject(
                  new Error(
                    t('create-dish-page:errors.document-too-big', {
                      maxSize: settings.dish.maxFileSize.document,
                    }),
                  ),
                );
              }

              if (value.file.type !== 'application/pdf') {
                return Promise.reject(
                  new Error(t('create-dish-page:errors.document-invalid-format')),
                );
              }

              return Promise.resolve();
            },
          },
        ]}>
        <Upload
          multiple={true}
          maxCount={5}
          defaultFileList={
            dish?.documents
              ? [...dish.documents.map(doc => ({...doc, uid: dish.id.toString()}))]
              : undefined
          }
        />
      </Form.Item>

      <Divider />

      <FooterContainer>
        <BackButton block key="back" onClick={onCancel}>
          {t('common:buttons.back')}
        </BackButton>

        <StyledFormItem>
          <Button type="primary" htmlType="submit" block>
            {t('common:buttons.next')}
          </Button>
        </StyledFormItem>
      </FooterContainer>
    </Form>
  );
};
