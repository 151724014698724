import {FunctionComponent} from 'react';

import {PathParamsFor, createRouting, query, segment} from 'ts-routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PageProps<TPathParams extends (...args: any[]) => string> =
  PathParamsFor<TPathParams>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PageComponent<TPathParams extends (...args: any[]) => string> =
  FunctionComponent<PageProps<TPathParams>>;

export const paths = createRouting({
  auth: {
    ...segment`/auth`,
    children: {
      signIn: segment`/sign-in`,
      signUp: {
        ...segment`/sign-up/:entityId`,
        query: {
          token: query('required'),
        },
      },
      forgotPassword: segment`/forgot-password`,
      resetPassword: {
        ...segment`/reset-password`,
        query: {
          token: query('required'),
        },
      },
    },
  },
  home: segment`/`,

  cguChef: segment`/cgu-chef`,
  cguParent: segment`/cgu-parent`,

  admin: {
    ...segment`/admin`,
    children: {
      entities: {
        ...segment`/entities`,
        children: {
          create: segment`/create`,
          edit: segment`/edit/:id`,
        },
      },
      foods: {
        ...segment`/foods`,
        children: {
          create: segment`/create`,
          edit: segment`/edit/:id`,
          details: segment`/details/:id`,
        },
      },
      dishes: {
        ...segment`/dishes`,
        children: {
          create: segment`/create`,
          edit: segment`/edit/:id`,
          details: segment`/details/:id`,
        },
      },
      myAccount: segment`/my-account`,
    },
  },

  chef: {
    ...segment`/chef`,
    children: {
      home: {
        ...segment`/home`,
        children: {
          mealDishRatings: segment`/meal-dish-ratings/:mealId/:dishId`,
          reportWeather: segment`/report-weather/:mealId`,
        },
      },
      myAccount: segment`/my-account`,
      foods: {
        ...segment`/foods`,
        children: {
          details: segment`/details/:id`,
          edit: segment`/edit/:id`,
        },
      },
      dishes: {
        ...segment`/dishes`,
        children: {
          create: segment`/create`,
          edit: segment`/edit/:id`,
          details: segment`/details/:id`,
        },
      },
      menus: {
        ...segment`/menus`,
        children: {
          create: segment`/create`,
          details: segment`/details/:id`,
          edit: segment`/edit/:id`,
        },
      },
      meals: {
        ...segment`/meal`,
      },
      challenges: segment`/challenges`,
    },
  },
} as const);
