import {useContext, useCallback} from 'react';

import i18next from 'i18next';

import SnackBarContext from 'src/providers/SnackBar/Context';

export default function useSnackBar() {
  // Current user context
  const {api} = useContext(SnackBarContext);

  const handleShowSuccess = useCallback(
    (content: string) => {
      api?.open({
        type: 'success',
        content,
      });
    },
    [api],
  );

  const handleShowError = useCallback(
    (content?: string) => {
      api?.open({
        type: 'error',
        content: content ?? i18next.t('common:errors.unkown-try-again'),
      });
    },
    [api],
  );

  return {
    showSuccessSnackBar: handleShowSuccess,
    showErrorSnackBar: handleShowError,
  };
}
