import React from 'react';

import {Outlet} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';
import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {ReactComponent as Logo} from 'src/assets/icons/app-logo.svg';
import {ReactComponent as Illustration} from 'src/assets/illustrations/illustration.svg';

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  background-color: ${({theme}) => theme.colors.background};
`;

const LeftContainer = styled.div`
  height: 100%;
  align-items: center;
  background-color: ${({theme}) => theme.colors.white};
  justify-content: space-between;
  border-radius: ${({theme}) => theme.radius.xxl};
  display: flex;
  flex-direction: column;
  margin-top: ${({theme}) => theme.margins.md};
  margin-bottom: ${({theme}) => theme.margins.md};
  margin-left: ${({theme}) => theme.margins.md};
`;

const TextContainer = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  padding: ${({theme}) => theme.paddings.lg};
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h1};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.lg};
`;

const Subtitle = styled.text`
  ${({theme}) => theme.textStyles.s1};
  color: ${({theme}) => theme.colors.gray12};
  max-width: 400px;
  text-align: center;
`;

const StyledLogo = styled(Logo)`
  margin-top: ${({theme}) => theme.margins.xl};
`;

export const AuthLayout: React.FC<{}> = () => {
  // Theme
  const theme = useTheme();

  // Translation
  const {t} = useTranslation('common');

  // Dispatch

  return (
    <>
      <Row>
        <StyledCol span={12}>
          <LeftContainer>
            <StyledLogo
              height={parseInt(theme.heights.logo.default, 10)}
              width={parseInt(theme.widths.logo.default, 10)}
            />

            <TextContainer>
              <Title>{t('app-name')}</Title>

              <Subtitle>{t('app-description')}</Subtitle>
            </TextContainer>

            <Illustration
              width="100%"
              height={parseInt(theme.heights.illustrationAuth.default, 10)}
            />
          </LeftContainer>
        </StyledCol>

        <StyledCol span={12}>
          <Outlet />
        </StyledCol>
      </Row>
    </>
  );
};
