import React, {useCallback} from 'react';

import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Form, Popconfirm, Row} from 'antd';
import {useDispatch} from 'react-redux';
import {useForm} from 'antd/es/form/Form';

import {Separator} from 'src/components/atoms/separator/Separator';
import {Mark} from 'src/components/atoms/mark/Mark';
import {Input} from 'src/components/atoms/input/Input';
import {Button} from 'src/components/atoms/button/Button';

import useCurrentUser from 'src/hooks/useCurrentUser';
import {useUpdateUserMutation} from 'src/hooks/mutations/useUpdateUserMutation';
import {useDeleteMyAccount} from 'src/hooks/mutations/useDeleteMyAccount';
import useSnackBar from 'src/hooks/useSnackBar';
import {userActions} from 'src/redux/slices/user';
import {queryClient} from 'src/services/client';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({theme}) => theme.margins.md} ${({theme}) => theme.margins.xs};
`;

const SectionTitle = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.md};
`;

const StyledSeparator = styled(Separator)`
  margin: ${({theme}) => theme.margins.md} 0px;
`;

const StyledFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
`;

const StyledFormItemMargin = styled(StyledFormItem)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const SaveRow = styled(Row)`
  margin-top: ${({theme}) => theme.margins.md};
`;

const SignOut = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

export const MyInformationTab: React.FC = () => {
  // Translation
  const {t} = useTranslation(['my-account-page', 'common']);

  // Current user
  const {user} = useCurrentUser();

  // Form
  const [formInstance] = useForm();

  // Snack bar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Dispatch
  const dispatch = useDispatch();

  // Mutation
  const {mutateAsync: updateUser} = useUpdateUserMutation();
  const {mutateAsync: deleteMyAccount} = useDeleteMyAccount();

  // Callbacks
  const renderMark = useCallback((label: React.ReactNode, info: {required: boolean}) => {
    return <Mark label={label} info={info} />;
  }, []);

  const handleUpdateUserInfo = useCallback(
    async (values: {firstName: string; lastName: string}) => {
      try {
        await updateUser(values);
        showSuccessSnackBar(t('my-account-page:my-information.sucess.profile-updated'));
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [updateUser, showErrorSnackBar, showSuccessSnackBar, t],
  );

  const handleChangePassword = useCallback(
    async (values: {oldPassword: string; password: string}) => {
      try {
        await updateUser({password: values.password, oldPassword: values.oldPassword});
        showSuccessSnackBar(t('my-account-page:my-information.sucess.password-updated'));
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [updateUser, showErrorSnackBar, showSuccessSnackBar, t],
  );

  const handleSignOut = useCallback(() => {
    dispatch(userActions.signOut());
    queryClient.clear();
  }, [dispatch]);

  const handleDeleteAccount = useCallback(async () => {
    await deleteMyAccount();
    handleSignOut();
  }, [handleSignOut, deleteMyAccount]);

  return (
    <Container>
      <SectionTitle>{t('my-account-page:my-information.profile')}</SectionTitle>

      <Form layout="vertical" onFinish={handleUpdateUserInfo} requiredMark={renderMark}>
        <Row wrap={false}>
          {user?.lastName && (
            <StyledFormItemMargin
              label={t('common:labels.lastName')}
              name="lastName"
              initialValue={user?.lastName}
              rules={[
                {
                  required: true,
                  message: t('my-account-page:my-information.errors.last-name-required'),
                },
              ]}>
              <StyledInput defaultValue={user?.lastName} />
            </StyledFormItemMargin>
          )}

          {user?.firstName && (
            <StyledFormItem
              label={t('common:labels.firstName')}
              name="firstName"
              initialValue={user?.firstName}
              rules={[
                {
                  required: true,
                  message: t('my-account-page:my-information.errors.first-name-required'),
                },
              ]}>
              <StyledInput defaultValue={user?.firstName} />
            </StyledFormItem>
          )}
        </Row>

        <StyledInput defaultValue={user?.email} disabled />

        <SaveRow justify="end">
          <Form.Item>
            <Button htmlType="submit" type="primary">
              {t('common:buttons.save')}
            </Button>
          </Form.Item>
        </SaveRow>
      </Form>

      <StyledSeparator />

      <SectionTitle>{t('my-account-page:my-information.security')}</SectionTitle>

      <Form
        layout="vertical"
        form={formInstance}
        onFinish={handleChangePassword}
        requiredMark={renderMark}>
        <StyledFormItem
          label={t('my-account-page:my-information.labels.current-password')}
          name="oldPassword"
          rules={[
            {
              required: true,
              message: t('my-account-page:my-information.errors.password-required'),
            },
          ]}>
          <StyledInput isPassword />
        </StyledFormItem>

        <Row wrap={false}>
          <StyledFormItemMargin
            label={t('my-account-page:my-information.labels.new-password')}
            name="password"
            rules={[
              {
                required: true,
                message: t('my-account-page:my-information.errors.new-password-required'),
              },
            ]}>
            <StyledInput isPassword />
          </StyledFormItemMargin>

          <StyledFormItem
            label={t('my-account-page:my-information.labels.confirm-new-password')}
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: t('my-account-page:my-information.errors.new-password-required'),
              },
              {
                validator: (_, value) => {
                  const {getFieldValue} = formInstance;
                  if (value && value !== getFieldValue('password')) {
                    return Promise.reject(
                      new Error(
                        t(
                          'my-account-page:my-information.errors.confirm-password-mismatch',
                        ),
                      ),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <StyledInput isPassword />
          </StyledFormItem>
        </Row>

        <Row justify="end">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('my-account-page:my-information.buttons.change-password')}
            </Button>
          </Form.Item>
        </Row>
      </Form>

      <StyledSeparator />

      <Row>
        <SignOut type="default" onClick={handleSignOut}>
          {t('my-account-page:my-information.buttons.sign-out')}
        </SignOut>

        <Popconfirm
          title={t('my-account-page:delete-account.title')}
          description={t('my-account-page:delete-account.description')}
          onConfirm={handleDeleteAccount}
          okText={t('my-account-page:delete-account.buttons.delete')}
          cancelText={t('my-account-page:delete-account.buttons.cancel')}>
          <Button type="default">
            {t('my-account-page:my-information.buttons.delete-account')}
          </Button>
        </Popconfirm>
      </Row>
    </Container>
  );
};
