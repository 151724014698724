import React from 'react';

import styled from 'styled-components';
import {Col, Row} from 'antd';

import {ChallengeType} from 'src/interfaces/meal.interface';
import {Menu} from 'src/interfaces/api/generated.interface';

import {MealTimeRemainingCard} from 'src/components/molecules/card/MealTimeRemainingCard';
import {UpcomingMealStatsCard} from 'src/components/molecules/card/UpcomingMealStatsCard';
import {MealChallengeResultNotAvailableCard} from 'src/components/molecules/card/MealChallengeResultNotAvailableCard';
import {Separator} from 'src/components/atoms/separator/Separator';
import {MealMenuDetailsCard} from 'src/components/molecules/card/MealMenuDetailsCard';

const StyledMealTimeRemainingCard = styled(MealTimeRemainingCard)`
  margin-right: ${({theme}) => theme.margins.md};
`;

const StyledUpcomingMealStatsCard = styled(UpcomingMealStatsCard)`
  margin-right: ${({theme}) => theme.margins.md};
`;

const StyledMealChallengeResultNotAvailableCard = styled(
  MealChallengeResultNotAvailableCard,
)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const StyledSeparator = styled(Separator)`
  margin: ${({theme}) => theme.margins.md} 0;
`;

interface ComingMealDetailsProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<
      React.ComponentProps<typeof UpcomingMealStatsCard>,
      'total' | 'nbAbsents' | 'nbParticipants'
    >,
    Pick<React.ComponentProps<typeof MealMenuDetailsCard>, 'studentsVotes'> {
  date: Date;
  menu: Menu;
}

export const ComingMealDetails: React.FC<ComingMealDetailsProps> = ({
  date,
  menu,
  nbAbsents,
  nbParticipants,
  total,
  studentsVotes,
  ...rest
}) => {
  return (
    <>
      <Row {...rest}>
        <Col span={9}>
          <StyledMealTimeRemainingCard date={date} />
        </Col>

        <Col span={7}>
          <StyledUpcomingMealStatsCard
            total={total}
            nbParticipants={nbParticipants}
            nbAbsents={nbAbsents}
          />
        </Col>

        <Col span={8}>
          <StyledMealChallengeResultNotAvailableCard type={ChallengeType.FoodWaste} />

          <StyledMealChallengeResultNotAvailableCard
            type={ChallengeType.NoisePollution}
          />
        </Col>
      </Row>

      <StyledSeparator />

      <MealMenuDetailsCard
        dishCardStudentsChoice
        menu={menu}
        studentsVotes={studentsVotes}
      />
    </>
  );
};
