import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const CopyCheckmarkIcon = ({
  size = 20,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M5.834 5v6.667a2.5 2.5 0 0 0 2.5 2.5h6.667a2.5 2.5 0 0 0 2.5-2.5V5A2.5 2.5 0 0 0 15 2.5H8.334a2.5 2.5 0 0 0-2.5 2.5Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M14.167 14.167V15a2.5 2.5 0 0 1-2.5 2.5H5A2.5 2.5 0 0 1 2.5 15V8.334a2.5 2.5 0 0 1 2.5-2.5h.833M13.333 7.292l-2.082 2.083L10 8.125"
      />
    </svg>
  );
};
