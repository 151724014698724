import React, {useCallback, useState} from 'react';

import {Divider} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {
  CreateDishNutritionalScore,
  NutritionalScoreType,
} from 'src/interfaces/api/dish.interface';
import {NutritionalScoreValue} from 'src/interfaces/api/generated.interface';

import {NutritionalScoreItemForm} from 'src/components/molecules/form/NutritionalScoreItemForm';
import {Button} from 'src/components/atoms/button/Button';

import useSnackBar from 'src/hooks/useSnackBar';
import {nustritionalScoreDefaultValues} from 'src/constants/dish';
import {
  calculateNutritionalScore,
  convertNutritionalScoreToValue,
  isNutritionalScoreFormCompleted,
} from 'src/utils/nutritional-score';

interface DishNutritionalScoreFormProps extends React.HTMLAttributes<HTMLDivElement> {
  isSubmitting?: boolean;
  onFinish: (values: CreateDishNutritionalScore) => void;
  onCancel: () => void;
}

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Container = styled.div``;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const StyledNutritionalScoreItemForm = styled(NutritionalScoreItemForm)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

export const DishNutritionalScoreForm: React.FC<DishNutritionalScoreFormProps> = ({
  isSubmitting,
  onFinish,
  onCancel,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation(['create-dish-page', 'common']);

  // Snack bar
  const {showErrorSnackBar} = useSnackBar();

  // States
  const [nutritionalScore, setNutritionalScore] = useState(
    nustritionalScoreDefaultValues,
  );

  // Callbacks
  const handleSelectItem = useCallback(
    (type: NutritionalScoreType, question: number, value: NutritionalScoreValue) => {
      setNutritionalScore({
        ...nutritionalScore,
        [type]: {
          ...nutritionalScore[type],
          [`question${question}`]: value,
        },
      });
    },
    [nutritionalScore],
  );

  const handleFinish = useCallback(() => {
    if (!isNutritionalScoreFormCompleted(nutritionalScore)) {
      showErrorSnackBar(t('create-dish-page:errors.nutritional-score-form-incomplete'));
      return;
    }
    onFinish(convertNutritionalScoreToValue(calculateNutritionalScore(nutritionalScore)));
  }, [onFinish, t, showErrorSnackBar, nutritionalScore]);

  return (
    <Container {...rest}>
      <StyledNutritionalScoreItemForm
        type={NutritionalScoreType.CarbonFootprint}
        onSelectItem={handleSelectItem}
        values={nutritionalScore[NutritionalScoreType.CarbonFootprint]}
      />

      <StyledNutritionalScoreItemForm
        type={NutritionalScoreType.Calories}
        onSelectItem={handleSelectItem}
        values={nutritionalScore[NutritionalScoreType.Calories]}
      />

      <StyledNutritionalScoreItemForm
        type={NutritionalScoreType.Benefits}
        onSelectItem={handleSelectItem}
        values={nutritionalScore[NutritionalScoreType.Benefits]}
      />

      <NutritionalScoreItemForm
        type={NutritionalScoreType.Transformation}
        onSelectItem={handleSelectItem}
        values={nutritionalScore[NutritionalScoreType.Transformation]}
      />

      <Divider />

      <FooterContainer key="back">
        <BackButton block onClick={onCancel}>
          {t('common:buttons.back')}
        </BackButton>

        <Button
          type="primary"
          onClick={handleFinish}
          htmlType="submit"
          block
          loading={isSubmitting}>
          {t('common:buttons.next')}
        </Button>
      </FooterContainer>
    </Container>
  );
};
