import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Divider, Form, List} from 'antd';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';

import {CreateDishAllergens} from 'src/interfaces/api/dish.interface';
import {Allergy, Dish} from 'src/interfaces/api/generated.interface';

import {Button} from 'src/components/atoms/button/Button';
import {CircleCheckIcon} from 'src/components/atoms/icon/CircleCheckIcon';

import {allergensList} from 'src/constants/allergens';

interface DishAllergensFormProps extends React.ComponentProps<typeof Container> {
  dish?: Dish | null;
  isSubmitting?: boolean;
  onFinish: (values: CreateDishAllergens) => void;
  onCancel: () => void;
}

const Container = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({theme}) => theme.paddings.xs};
  margin-top: ${({theme}) => theme.margins.xs};
`;

const ItemName = styled.text<{selected: boolean}>`
  ${({theme, selected}) => (selected ? theme.textStyles.p2Bold : theme.textStyles.p2)};
  color: ${({theme}) => theme.colors.gray11};
  margin-top: ${({theme}) => theme.margins.xs};
`;

const ItemButton = styled(Button)<{selected: boolean}>`
  height: ${({theme}) => theme.heights.allergenCircle.default};
  border: none;
  width: ${({theme}) => theme.heights.allergenCircle.default};
  background-color: ${({theme, selected}) =>
    selected ? theme.colors.primary2 : 'transparent'};
`;

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  position: absolute;
  top: 2.5px;
  right: 2.5px;
`;

export const DishAllergensForm: React.FC<DishAllergensFormProps> = ({
  dish,
  onFinish,
  onCancel,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation(['create-dish-page', 'common']);

  // Theme
  const theme = useTheme();

  // State
  const [allergens, setAllergens] = useState(allergensList);

  // Constants
  const hasSelected = useMemo(() => allergens.some(item => item.selected), [allergens]);

  // Effects
  useEffect(() => {
    if (dish?.allergens && !allergens.some(item => item.selected)) {
      setAllergens(
        allergens.map(item => ({
          ...item,
          selected: dish.allergens.includes(item.key),
        })),
      );
    }
  }, [dish?.allergens, allergens]);

  // Callbacks
  const handleAllergenClick = useCallback(
    (allergen: Allergy) => {
      const noneItem = allergens.find(item => item.key === Allergy.NONE);
      if (allergen === Allergy.NONE) {
        setAllergens(
          allergens.map(item =>
            item.key === Allergy.NONE
              ? {...item, selected: item.selected ? false : true}
              : {...item, selected: noneItem?.selected ? item.selected : false},
          ),
        );
        return;
      }
      setAllergens(
        allergens.map(item =>
          item.key === Allergy.NONE
            ? {...item, selected: false}
            : item.key === allergen
            ? {...item, selected: item.selected ? false : true}
            : item,
        ),
      );
    },
    [allergens],
  );

  const handleFinish = useCallback(() => {
    onFinish({allergens: allergens.filter(item => item.selected).map(item => item.key)});
  }, [onFinish, allergens]);

  return (
    <Container {...rest}>
      <List
        grid={{gutter: parseInt(theme.paddings.xs, 10), column: 4}}
        dataSource={allergens}
        renderItem={item => (
          <List.Item>
            <ItemContainer>
              <ItemButton
                selected={item.selected}
                shape="circle"
                onClick={() => handleAllergenClick(item.key)}>
                {item.icon}

                {item.selected && <StyledCircleCheckIcon />}
              </ItemButton>

              {/* @ts-ignore */}
              <ItemName selected={item.selected}>{t(item.name)}</ItemName>
            </ItemContainer>
          </List.Item>
        )}
      />

      <Divider />

      <FooterContainer key="back">
        <BackButton block onClick={onCancel}>
          {t('common:buttons.back')}
        </BackButton>

        <StyledFormItem>
          <Button
            type="primary"
            htmlType="submit"
            block
            onClick={handleFinish}
            disabled={!hasSelected}>
            {t('common:buttons.next')}
          </Button>
        </StyledFormItem>
      </FooterContainer>
    </Container>
  );
};
