import React from 'react';

import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Divider} from 'antd';

import {ModalFooter} from 'src/components/molecules/footer/ModalFooter';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h5};
  color: ${({theme}) => theme.colors.gray12};
  text-align: center;
  margin-bottom: ${({theme}) => theme.margins.lg};
`;

const Text = styled.text`
  ${({theme}) => theme.textStyles.h7};
  color: ${({theme}) => theme.colors.gray8};
  text-align: center;
  white-space: pre;
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const Subtitle = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  text-align: center;
`;

interface MenuConfirmAllergensFormProps {
  isLoading: boolean;
  isUpdating?: boolean;
  onConfirm: () => void;
  onGoBack: () => void;
}

export const MenuConfirmAllergenForm: React.FC<MenuConfirmAllergensFormProps> = ({
  isLoading,
  isUpdating,
  onConfirm,
  onGoBack,
}) => {
  // Translation
  const {t} = useTranslation(['create-menu-page', 'common']);

  return (
    <Container>
      <Title>{t('create-menu-page:allergens-confirm.title')}</Title>

      <Text>{t('create-menu-page:allergens-confirm.description')}</Text>

      <Subtitle>{t('create-menu-page:allergens-confirm.subtitle')}</Subtitle>

      <Divider />

      <ModalFooter
        onLeftButtonClick={onGoBack}
        onRightButtonClick={onConfirm}
        rightButtonLoading={isLoading}
        rightButtonText={
          isUpdating ? t('common:buttons.edit') : t('common:buttons.create')
        }
      />
    </Container>
  );
};
