import React from 'react';

import {Row} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {SquareCheckIcon} from 'src/components/atoms/icon/SquareCheckIcon';
import {Separator} from 'src/components/atoms/separator/Separator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({theme}) => theme.heights.timeRemaining.default};
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.lg};
  overflow: scroll;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.paddings.xxs};
`;

const Description = styled.span`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray8};
`;

const Percentage = styled.span`
  ${({theme}) => theme.textStyles.h3};
  color: ${({theme}) => theme.colors.primary5};
`;

const PercentageDetail = styled.span`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const StyledSeparator = styled(Separator)`
  margin: ${({theme}) => theme.margins.sm} 0;
`;

const StyledSquareCheckIcon = styled(SquareCheckIcon)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface UpcomingMealStatsCardProps extends React.HTMLAttributes<HTMLDivElement> {
  total: number;
  nbParticipants: number;
  nbAbsents: number;
}

export const UpcomingMealStatsCard: React.FC<UpcomingMealStatsCardProps> = ({
  total,
  nbAbsents,
  nbParticipants,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation('home-page');

  return (
    <Container {...rest}>
      <Row>
        <StyledSquareCheckIcon />

        <Title>{t('participation-rate')}</Title>
      </Row>

      <StyledRow>
        <Percentage>{total ? Math.round((nbParticipants / total) * 100) : 0}%</Percentage>

        <PercentageDetail>{`${nbParticipants} / ${total} ${t(
          'students',
        )}`}</PercentageDetail>
      </StyledRow>

      <StyledSeparator />

      <Row>
        <Title>{t('absence.title')}</Title>
      </Row>

      <Description>{t('absence.description')}</Description>

      <StyledRow>
        <Percentage>{total ? Math.round((nbAbsents / total) * 100) : 0}%</Percentage>

        <PercentageDetail>{`${nbAbsents} / ${total} ${t('students')}`}</PercentageDetail>
      </StyledRow>
    </Container>
  );
};
