import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const TrophyIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      viewBox="0 0 20 21"
      fill="none"
      {...rest}>
      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M9.997 13.135v2.248m-3.317 2.31.74-2.31h5.28l.74 2.31m1.347 0H5.332m9.71-13.513H4.957s.005 8.955 5.044 8.955c5.04 0 5.04-8.955 5.04-8.955Z"
      />

      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M5.045 5.648H2.52s-.355 3.165 1.983 4.704M14.954 5.648h2.526s.355 3.165-1.982 4.704"
      />
    </svg>
  );
};
