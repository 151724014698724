export const spacings = {
  margins: {
    negSm: '-18px',
    negXs: '-8px',
    xxxs: '2px',
    xxs: '4px',
    xs: '8px',
    ssm: '12px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '40px',
    xxl: '58px',
  },
  paddings: {
    xxxs: '4px',
    xxs: '6px',
    xs: '8px',
    ssm: '10px',
    sm: '12px',
    md: '14px',
    mmd: '15px',
    lg: '16px',
    xl: '18px',
    xxl: '20px',
  },
};

export const radius = {
  xs: '6px',
  ssm: '8px',
  sm: '10px',
  md: '12px',
  lg: '16px',
  xl: '18px',
  xxl: '24px',
  max: '300px',
};

export const opacities = {
  xs: 0.1,
  full: 1,
};

export const borders = {
  null: '0px',
  xs: '1px',
  sm: '2px',
};
