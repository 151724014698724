import React, {useCallback, useState} from 'react';

import {Button, Image, List, Popover} from 'antd';
import styled, {useTheme} from 'styled-components';

import {MoreIcon} from 'src/components/atoms/icon/MoreIcon';

const Container = styled.div`
  max-height: ${({theme}) => theme.heights.foodCard.default};
  flex-direction: row;
  display: flex;
  padding: ${({theme}) => theme.paddings.lg};
  border-color: ${({theme}) => theme.colors.gray4} !important;
  border-width: ${({theme}) => theme.borders.xs} !important;
  border-radius: ${({theme}) => theme.radius.lg} !important;
  border-style: solid !important;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: ${({theme}) => theme.colors.primary5} !important;
  }
`;

const StyledImage = styled(Image)`
  border-radius: ${({theme}) => theme.radius.sm};
`;

const ImageContainer = styled.div`
  width: ${({theme}) => theme.heights.foodCardImage.default};
  height: ${({theme}) => theme.heights.foodCardImage.default};
`;

const InfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  padding-left: ${({theme}) => theme.paddings.lg};
  justify-content: center;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

const Description = styled.text`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray8};
  overflow: hidden;
`;

const RightContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: ${({theme}) => theme.margins.sm};
`;

const MoreButton = styled(Button)`
  position: absolute;
  top: ${({theme}) => theme.margins.sm};
  right: ${({theme}) => theme.margins.sm};
`;

interface FoodCardProps {
  id: number;
  name: string;
  description: string;
  image?: string;
  rightComponent?: React.ReactNode;
  onClick?: (foodId: number) => void;
  renderMoreContent?: (close: () => void) => JSX.Element;
}

export const FoodCard: React.FC<FoodCardProps> = ({
  id,
  image,
  name,
  description,
  rightComponent,
  onClick,
  renderMoreContent,
}) => {
  // Theme
  const theme = useTheme();

  // State
  const [open, setOpen] = useState(false);

  // Callbacks
  const handleItemClick = useCallback(() => {
    onClick?.(id);
  }, [id, onClick]);

  return (
    <List.Item onClick={handleItemClick}>
      <Container>
        <ImageContainer>
          <StyledImage
            src={image}
            width={theme.heights.foodCardImage.default}
            height={theme.heights.foodCardImage.default}
            preview={false}
          />

          {renderMoreContent && (
            <Popover
              open={open}
              content={() => renderMoreContent(() => setOpen(false))}
              onOpenChange={setOpen}
              trigger="click">
              <MoreButton
                shape="circle"
                icon={<MoreIcon />}
                onClick={e => e.stopPropagation()}
              />
            </Popover>
          )}
        </ImageContainer>

        <InfoContainer>
          <Title>{name}</Title>

          <Description>{description}</Description>
        </InfoContainer>

        <RightContainer>{rightComponent}</RightContainer>
      </Container>
    </List.Item>
  );
};
