import React, {useMemo} from 'react';

import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Button} from 'antd';

import {NoisePollutionValue, WasteValue} from 'src/interfaces/api/generated.interface';
import {ChallengeType} from 'src/interfaces/meal.interface';

import {TrashIcon} from 'src/components/atoms/icon/TrashIcon';
import {HelicopterIcon} from 'src/components/atoms/icon/HelicopterIcon';
import {CarIcon} from 'src/components/atoms/icon/CarIcon';
import {MessageChatIcon} from 'src/components/atoms/icon/MessageChatIcon';
import {LeavesIcon} from 'src/components/atoms/icon/LeavesIcon';

import {Theme} from 'src/constants/theme';

interface WeatherTagProps extends React.HTMLAttributes<HTMLDivElement> {
  value: WasteValue | NoisePollutionValue;
  type: ChallengeType;
  weight?: number;
}

interface WeatherItem {
  name: string;
  color: string;
  icon: React.ReactNode;
  lightColor: string;
}

const Container = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const TitleContainer = styled.div<Pick<WeatherItem, 'lightColor' | 'color'>>`
  padding: ${({theme}) => theme.margins.xxxs} ${({theme}) => theme.paddings.ssm};
  background-color: ${({lightColor}) => lightColor};
  border-radius: ${({theme}) => theme.radius.lg};
  display: inline-block;
  text-align: center;
  height: fit-content;
`;

const Title = styled.span<Pick<WeatherItem, 'color'>>`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({color}) => color};
  margin-top: ${({theme}) => theme.margins.xxxs};
`;

const StyledIconButton = styled(Button)<Pick<WeatherItem, 'color'>>`
  margin-right: ${({theme}) => theme.paddings.xs};
  background-color: ${({color}) => color};
  border: none !important;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const genNoisePollutionItems = (
  theme: Theme,
): {
  [key in NoisePollutionValue]: WeatherItem;
} => ({
  [NoisePollutionValue.VERY_LOW]: {
    name: 'noise-pollution-values.very_low',
    icon: <LeavesIcon color={theme.colors.white} />,
    color: theme.colors.munsell,
    lightColor: theme.colors.munsell24,
  },
  [NoisePollutionValue.LOW]: {
    name: 'noise-pollution-values.low',
    icon: <MessageChatIcon color={theme.colors.white} />,
    color: theme.colors.apple,
    lightColor: theme.colors.apple24,
  },
  [NoisePollutionValue.HIGH]: {
    name: 'noise-pollution-values.hight',
    icon: <CarIcon color={theme.colors.white} />,
    color: theme.colors.mikado,
    lightColor: theme.colors.mikado24,
  },
  [NoisePollutionValue.VERY_HIGH]: {
    name: 'noise-pollution-values.very_high',
    icon: <HelicopterIcon color={theme.colors.white} />,
    color: theme.colors.pigment,
    lightColor: theme.colors.pigment24,
  },
});

const genWasteItems = (
  theme: Theme,
): {
  [key in WasteValue]: WeatherItem;
} => ({
  [WasteValue.VERY_LOW]: {
    name: 'waste-values.very_low',
    icon: <TrashIcon color={theme.colors.white} />,
    color: theme.colors.munsell,
    lightColor: theme.colors.munsell24,
  },
  [WasteValue.LOW]: {
    name: 'waste-values.low',
    icon: <TrashIcon color={theme.colors.white} />,
    color: theme.colors.apple,
    lightColor: theme.colors.apple24,
  },
  [WasteValue.HIGH]: {
    name: 'waste-values.hight',
    icon: <TrashIcon color={theme.colors.white} />,
    color: theme.colors.mikado,
    lightColor: theme.colors.mikado24,
  },
  [WasteValue.VERY_HIGH]: {
    name: 'waste-values.very_high',
    icon: <TrashIcon color={theme.colors.white} />,
    color: theme.colors.pigment,
    lightColor: theme.colors.pigment24,
  },
});

export const WeatherTag: React.FC<WeatherTagProps> = ({value, type, ...rest}) => {
  // Translations
  const {t} = useTranslation();

  // Theme
  const theme = useTheme();

  const item = useMemo(() => {
    switch (type) {
      case ChallengeType.NoisePollution:
        return genNoisePollutionItems(theme)[value];
      case ChallengeType.FoodWaste:
        return genWasteItems(theme)[value];
      default:
        return genNoisePollutionItems(theme)[WasteValue.VERY_LOW];
    }
  }, [type, value, theme]);

  return (
    <Container {...rest}>
      <StyledIconButton size="large" icon={item.icon} shape="circle" color={item.color} />

      <TitleContainer lightColor={item.lightColor} color={item.color}>
        {/* @ts-ignore */}
        <Title color={item.color}>{t(item.name)}</Title>
      </TitleContainer>
    </Container>
  );
};
