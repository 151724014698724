import {Menu} from 'src/interfaces/api/generated.interface';

export interface WeekMealForm {
  date: Date;
  publicHoliday: boolean;
  menu?: Menu | {name: string; id: number};
  mealId?: number;
}

export enum ChallengeType {
  FoodWaste = 'FOOD_WASTE',
  NoisePollution = 'NOISE_POLLUTION',
}
