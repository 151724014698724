import React, {useCallback} from 'react';

import styled from 'styled-components';
import {Button, Col, Row} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {ChallengeType} from 'src/interfaces/meal.interface';
import {
  Menu,
  NoisePollutionValue,
  WasteValue,
} from 'src/interfaces/api/generated.interface';

import {PastMealStatsCard} from 'src/components/molecules/card/PastMealStatsCard';
import {Separator} from 'src/components/atoms/separator/Separator';
import {MealChallengeResultNotAvailableCard} from 'src/components/molecules/card/MealChallengeResultNotAvailableCard';
import {MealMenuDetailsCard} from 'src/components/molecules/card/MealMenuDetailsCard';
import {MealChallengeResultCard} from 'src/components/molecules/card/MealChallengeResultCard';
import {ArrowRightIcon} from 'src/components/atoms/icon/ArrowRightIcon';
import {paths} from 'src/navigation/paths';

const StyledPastMealStatsCard = styled(PastMealStatsCard)`
  margin-right: ${({theme}) => theme.margins.md};
`;

const StyledMealChallengeResultCard = styled(MealChallengeResultCard)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const StyledMealChallengeResultNotAvailableCard = styled(
  MealChallengeResultNotAvailableCard,
)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const StyledSeparator = styled(Separator)`
  margin: ${({theme}) => theme.margins.md} 0;
`;

interface PastMealDetailsProps
  extends React.HTMLAttributes<HTMLDivElement>,
    Pick<
      React.ComponentProps<typeof PastMealStatsCard>,
      | 'total'
      | 'nbAbsents'
      | 'nbChangedChoice'
      | 'nbConfirmedChoices'
      | 'nbParticipants'
      | 'nbVotes'
      | 'nbChangedChoiceNotAvailable'
    >,
    Pick<React.ComponentProps<typeof MealMenuDetailsCard>, 'studentsRates'> {
  mealId: number;
  menu: Menu;
  noisePollutionValue?: NoisePollutionValue;
  wasteValue?: WasteValue;
}

export const PastMealDetails: React.FC<PastMealDetailsProps> = ({
  mealId,
  menu,
  nbAbsents,
  nbChangedChoice,
  nbChangedChoiceNotAvailable,
  nbConfirmedChoices,
  nbParticipants,
  nbVotes,
  total,
  studentsRates,
  noisePollutionValue,
  wasteValue,
  ...rest
}) => {
  // Navigate
  const navigate = useNavigate();

  // Location
  const location = useLocation();

  // Translation
  const {t} = useTranslation('home-page');

  // Callbacks
  const handleOpenDishReviewsDetails = useCallback(
    (dishId: number) => {
      navigate(paths.chef.home.mealDishRatings({mealId, dishId}), {
        state: {previousLocation: location.pathname},
      });
    },
    [navigate, location.pathname, mealId],
  );

  const handleRenderDishCardRightComponent = useCallback(
    (id: number) => {
      return (
        <Button
          type="dashed"
          shape="circle"
          icon={<ArrowRightIcon size={12} />}
          onClick={() => handleOpenDishReviewsDetails(id)}
        />
      );
    },
    [handleOpenDishReviewsDetails],
  );

  const handleRateChallenge = useCallback(() => {
    navigate(paths.chef.home.reportWeather({mealId}), {
      state: {previousLocation: location.pathname},
    });
  }, [navigate, location, mealId]);

  return (
    <>
      <Row {...rest}>
        <Col span={16}>
          <StyledPastMealStatsCard
            nbAbsents={nbAbsents}
            total={total}
            nbChangedChoice={nbChangedChoice}
            nbChangedChoiceNotAvailable={nbChangedChoiceNotAvailable}
            nbParticipants={nbParticipants}
            nbVotes={nbVotes}
            nbConfirmedChoices={nbConfirmedChoices}
          />
        </Col>

        <Col span={8}>
          {wasteValue ? (
            <StyledMealChallengeResultCard
              type={ChallengeType.FoodWaste}
              value={wasteValue}
            />
          ) : (
            <StyledMealChallengeResultNotAvailableCard
              type={ChallengeType.FoodWaste}
              onRateLastDay={handleRateChallenge}
              rateButtonText={t('challenge.buttons.rate-today')}
            />
          )}

          {noisePollutionValue ? (
            <MealChallengeResultCard
              type={ChallengeType.NoisePollution}
              value={noisePollutionValue}
            />
          ) : (
            <MealChallengeResultNotAvailableCard
              type={ChallengeType.NoisePollution}
              rateButtonText={t('challenge.buttons.rate-today')}
              onRateLastDay={handleRateChallenge}
            />
          )}
        </Col>
      </Row>

      <StyledSeparator />

      <MealMenuDetailsCard
        menu={menu}
        dishCardSize="default"
        dishCardRating
        dishCardRightComponent={handleRenderDishCardRightComponent}
        studentsRates={studentsRates}
      />
    </>
  );
};
