import React, {useCallback, useState} from 'react';

import {Divider, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {NoisePollutionValue} from 'src/interfaces/api/generated.interface';

import {Separator} from 'src/components/atoms/separator/Separator';
import {Button} from 'src/components/atoms/button/Button';
import {OptionButton} from 'src/components/molecules/button/OptionButton';

import useSnackBar from 'src/hooks/useSnackBar';
import {useReportWeatherMutation} from 'src/hooks/mutations/useReportWeatherMutation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSeparator = styled(Separator)`
  margin: ${({theme}) => theme.margins.sm} 0;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
  text-align: center;
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const LearnMore = styled.span`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray8};
  text-decoration: underline;
  margin-top: ${({theme}) => theme.margins.sm};
  text-align: center;
`;

const StyledOptionButton = styled(OptionButton<NoisePollutionValue>)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

interface ReportNoisePollutionTabProps {
  mealId: number;
  onLearnMoreClick: () => void;
}

export const ReportNoisePollutionTab: React.FC<ReportNoisePollutionTabProps> = ({
  mealId,
  onLearnMoreClick,
}) => {
  // Translation
  const {t} = useTranslation(['common', 'report-weather-page']);

  // Snack bar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Mutation
  const {mutateAsync: reportWeather, isLoading} = useReportWeatherMutation();

  // States
  const [noisePollutionValue, setNoisePollutionValue] =
    useState<NoisePollutionValue | null>(null);

  // Callbacks
  const handleFinish = useCallback(async () => {
    try {
      if (!noisePollutionValue) {
        throw new Error(
          t('report-weather-page:noise-pollution.errors.noise-pollution-required'),
        );
      }
      await reportWeather({
        mealId,
        noisePollutionValue,
      });

      showSuccessSnackBar(t('report-weather-page:food-waste.success.reported'));
    } catch (error) {
      showErrorSnackBar(error?.message ?? error);
    }
  }, [
    showErrorSnackBar,
    reportWeather,
    t,
    showSuccessSnackBar,
    mealId,
    noisePollutionValue,
  ]);

  const renderOptions = useCallback(
    (option: NoisePollutionValue) => {
      return (
        <StyledOptionButton
          selected={noisePollutionValue === option}
          value={option}
          text={t(
            // @ts-ignore
            `report-weather-page:noise-pollution.options.${option.toLowerCase()}.value`,
          )}
          description={t(
            // @ts-ignore
            `report-weather-page:noise-pollution.options.${option.toLowerCase()}.description`,
          )}
          onClick={setNoisePollutionValue}
        />
      );
    },
    [noisePollutionValue, t],
  );

  return (
    <Container>
      <Button type="link" onClick={onLearnMoreClick}>
        <LearnMore>{t('report-weather-page:noise-pollution.learn-more.link')}</LearnMore>
      </Button>

      <StyledSeparator />

      <Title>{t('report-weather-page:noise-pollution.title')}</Title>

      {Object.values(NoisePollutionValue).map(renderOptions)}

      <Divider />

      <Row wrap={false}>
        <BackButton block>{t('common:buttons.back')}</BackButton>

        <Button
          block
          type="primary"
          htmlType="submit"
          loading={isLoading}
          onClick={handleFinish}>
          {t('common:buttons.save')}
        </Button>
      </Row>
    </Container>
  );
};
