import React from 'react';

import {AllergyItem} from 'src/interfaces/allergens.interface';
import {Allergy} from 'src/interfaces/api/generated.interface';

import {ReactComponent as Celery} from 'src/assets/allergens/celery.svg';
import {ReactComponent as Crustaceans} from 'src/assets/allergens/crustaceans.svg';
import {ReactComponent as Eggs} from 'src/assets/allergens/eggs.svg';
import {ReactComponent as Fish} from 'src/assets/allergens/fish.svg';
import {ReactComponent as Gluten} from 'src/assets/allergens/gluten.svg';
import {ReactComponent as Lupin} from 'src/assets/allergens/lupin.svg';
import {ReactComponent as Milk} from 'src/assets/allergens/milk.svg';
import {ReactComponent as Molluscs} from 'src/assets/allergens/molluscs.svg';
import {ReactComponent as Mustard} from 'src/assets/allergens/mustard.svg';
import {ReactComponent as Nuts} from 'src/assets/allergens/nuts.svg';
import {ReactComponent as Peanuts} from 'src/assets/allergens/peanuts.svg';
import {ReactComponent as Sesame} from 'src/assets/allergens/sesame.svg';
import {ReactComponent as Soybeans} from 'src/assets/allergens/soybeans.svg';
import {ReactComponent as SulphurDioxide} from 'src/assets/allergens/sulphur-dioxide.svg';
import {ReactComponent as Logo} from 'src/assets/icons/app-logo.svg';

export const allergensList: AllergyItem[] = [
  {
    key: Allergy.GLUTEN,
    name: 'common:allergens.gluten',
    icon: <Gluten />,
    selected: false,
  },
  {
    key: Allergy.CRUSTACEAN,
    name: 'common:allergens.crustaceans',
    icon: <Crustaceans />,
    selected: false,
  },
  {
    key: Allergy.EGG,
    name: 'common:allergens.eggs',
    icon: <Eggs />,
    selected: false,
  },
  {
    key: Allergy.FISH,
    name: 'common:allergens.fish',
    icon: <Fish />,
    selected: false,
  },
  {
    key: Allergy.PEANUT,
    name: 'common:allergens.peanuts',
    icon: <Peanuts />,
    selected: false,
  },
  {
    key: Allergy.SOY,
    name: 'common:allergens.soybeans',
    icon: <Soybeans />,
    selected: false,
  },
  {
    key: Allergy.MILK,
    name: 'common:allergens.milk',
    icon: <Milk />,
    selected: false,
  },
  {
    key: Allergy.NUT,
    name: 'common:allergens.nuts',
    icon: <Nuts />,
    selected: false,
  },
  {
    key: Allergy.CELERY,
    name: 'common:allergens.celery',
    icon: <Celery />,
    selected: false,
  },
  {
    key: Allergy.MUSTARD,
    name: 'common:allergens.mustard',
    icon: <Mustard />,
    selected: false,
  },
  {
    key: Allergy.SESAME,
    name: 'common:allergens.sesame',
    icon: <Sesame />,
    selected: false,
  },
  {
    key: Allergy.SULFITE,
    name: 'common:allergens.sulphur-dioxide',
    icon: <SulphurDioxide />,
    selected: false,
  },
  {
    key: Allergy.LUPINE,
    name: 'common:allergens.lupin',
    icon: <Lupin />,
    selected: false,
  },
  {
    key: Allergy.MOLLUSC,
    name: 'common:allergens.molluscs',
    icon: <Molluscs />,
    selected: false,
  },
  {
    key: Allergy.NONE,
    name: 'common:allergens.none',
    icon: <Logo />,
    selected: false,
  },
];

export const assetsAllergens: {[key in Allergy]: React.ReactNode} = {
  [Allergy.GLUTEN]: <Gluten height={14} width={14} />,
  [Allergy.CRUSTACEAN]: <Crustaceans height={14} width={14} />,
  [Allergy.EGG]: <Eggs height={14} width={14} />,
  [Allergy.FISH]: <Fish height={14} width={14} />,
  [Allergy.PEANUT]: <Peanuts height={14} width={14} />,
  [Allergy.SOY]: <Soybeans height={14} width={14} />,
  [Allergy.MILK]: <Milk height={14} width={14} />,
  [Allergy.NUT]: <Nuts height={14} width={14} />,
  [Allergy.CELERY]: <Celery height={14} width={14} />,
  [Allergy.MUSTARD]: <Mustard height={14} width={14} />,
  [Allergy.SESAME]: <Sesame height={14} width={14} />,
  [Allergy.SULFITE]: <SulphurDioxide height={14} width={14} />,
  [Allergy.LUPINE]: <Lupin height={14} width={14} />,
  [Allergy.MOLLUSC]: <Molluscs height={14} width={14} />,
  [Allergy.NONE]: <Logo height={14} width={14} />,
};
