import React, {useCallback} from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Divider, Form, Modal} from 'antd';
import styled, {useTheme} from 'styled-components';

import {CreateEntityForm} from 'src/interfaces/entity.interface';

import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {Input} from 'src/components/atoms/input/Input';
import {Mark} from 'src/components/atoms/mark/Mark';
import {ChefsForm} from 'src/components/organisms/form/ChefsForm';
import {Button} from 'src/components/atoms/button/Button';

import useSnackBar from 'src/hooks/useSnackBar';
import {useCreateEntityMutation} from 'src/hooks/mutations/useCreateEntityMutation';
import {useInviteToEntityMutation} from 'src/hooks/mutations/useInviteToEntityMutation';
import {useEntityQuery} from 'src/hooks/queries/useEntityQuery';
import {useDeleteUserMutation} from 'src/hooks/mutations/useDeleteUserMutation';
import {useAdminUpdateEntityMutation} from 'src/hooks/mutations/useAdminUpdateEntityMutation';

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const StyledFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
`;

export default function CreateEditEntityPage() {
  // Translation
  const {t} = useTranslation(['common', 'create-entity-page']);

  // Navigate
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Extract EntityId from URL if exists
  const {id: entityId} = useParams();

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Query
  const {data: entity} = useEntityQuery(
    {entityId: Number(entityId)},
    {enabled: !!entityId},
  );

  // Mutation
  const {
    mutateAsync: createEntity,
    isLoading: createLoading,
    error: createError,
  } = useCreateEntityMutation();
  const {mutateAsync: inviteChef, isLoading: inviteLoading} = useInviteToEntityMutation();
  const {mutateAsync: updateEntity} = useAdminUpdateEntityMutation();
  const {mutateAsync: deleteUser} = useDeleteUserMutation();

  // Callbacks
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleFinish = useCallback(
    async (values: CreateEntityForm) => {
      try {
        if (!entityId) {
          const newEntity = await createEntity({
            name: values.name,
            address: values.address,
          });
          showSuccessSnackBar(t('create-entity-page:success.created'));

          for (const chef of values.chefs) {
            await inviteChef({
              entityId: newEntity.id,
              email: chef.email,
              firstName: chef.firstName,
              lastName: chef.lastName,
            });
            showSuccessSnackBar(
              t('create-entity-page:success.invited', {
                firstName: chef.firstName,
                lastName: chef.lastName,
              }),
            );
          }
        } else {
          await updateEntity({
            entityId: Number(entityId),
            name: values.name,
            address: values.address,
          });

          showSuccessSnackBar(t('create-entity-page:success.updated'));

          if (values.chefs?.length) {
            for (const chef of values.chefs) {
              await inviteChef({
                entityId: Number(entityId),
                email: chef.email,
                firstName: chef.firstName,
                lastName: chef.lastName,
              });
              showSuccessSnackBar(
                t('create-entity-page:success.invited', {
                  firstName: chef.firstName,
                  lastName: chef.lastName,
                }),
              );
            }
          }
        }

        navigate(-1);
      } catch (error) {
        showErrorSnackBar(error.message ?? error);
      }
    },
    [
      createEntity,
      showSuccessSnackBar,
      t,
      inviteChef,
      showErrorSnackBar,
      navigate,
      entityId,
      updateEntity,
    ],
  );

  const renderMark = useCallback((label: React.ReactNode, info: {required: boolean}) => {
    return <Mark label={label} info={info} />;
  }, []);

  const handleDeleteChef = useCallback(
    async (userId: number) => {
      try {
        await deleteUser({userId, entityId: Number(entityId)});
        showSuccessSnackBar(t('create-entity-page:success.chef-deleted'));
      } catch (error) {
        showErrorSnackBar(error.message ?? error);
      }
    },
    [deleteUser, showErrorSnackBar, showSuccessSnackBar, t, entityId],
  );

  return (
    <>
      <Helmet>
        <title>
          {entityId
            ? t('create-entity-page:helmet-edit')
            : t('create-entity-page:helmet')}
        </title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        width={parseInt(theme.widths.createEntityModal.default, 10)}
        footer={<></>}>
        <HeaderContainer>
          <PlusIcon color={theme.colors.primary5} size={24} />

          <Title>
            {entityId
              ? t('create-entity-page:title-edit')
              : t('create-entity-page:title')}
          </Title>
        </HeaderContainer>

        <Divider />

        <Form
          layout="vertical"
          onFinish={handleFinish}
          requiredMark={renderMark}
          initialValues={{chefs: [{}]}}>
          <Form.Item
            label={t('create-entity-page:labels.name')}
            name="name"
            validateStatus={
              createError?.response?.data.statusCode === 409 ? 'error' : undefined
            }
            help={createError?.response?.data.message}
            initialValue={entity?.name}
            rules={[
              {
                required: true,
                message: t('create-entity-page:errors.name-required'),
              },
            ]}>
            <Input
              defaultValue={entity?.name}
              placeholder={t('create-entity-page:placeholders.name')}
            />
          </Form.Item>

          <Form.Item
            label={t('create-entity-page:labels.address')}
            name="address"
            initialValue={entity?.address}
            rules={[
              {
                required: true,
                message: t('create-entity-page:errors.address-required'),
              },
            ]}>
            <Input
              defaultValue={entity?.address}
              placeholder={t('create-entity-page:placeholders.address')}
            />
          </Form.Item>

          <Divider />

          <ChefsForm chefs={entity?.chefs} onDeleteChef={handleDeleteChef} />

          <Divider />

          <FooterContainer>
            <BackButton block key="back" onClick={handleCancel}>
              {t('common:buttons.back')}
            </BackButton>

            <StyledFormItem>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={createLoading || inviteLoading}>
                {t('common:buttons.next')}
              </Button>
            </StyledFormItem>
          </FooterContainer>
        </Form>
      </Modal>
    </>
  );
}
