import React, {useCallback, useMemo, useRef, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {RcFile} from 'antd/es/upload';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Divider, Modal, Steps} from 'antd';
import styled, {useTheme} from 'styled-components';

import {
  CreateDishAllergens,
  CreateDishInfo,
  CreateDishIngredients,
  CreateDishNutritionalScore,
  CreateDishValues,
} from 'src/interfaces/api/dish.interface';

import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {DishInfoForm} from 'src/components/organisms/form/DishInfoForm';
import {DishIngredientsForm} from 'src/components/organisms/form/DishIngredientsForm';
import {DishAllergensForm} from 'src/components/organisms/form/DishAllergens';
import {DishNutritionalScoreForm} from 'src/components/organisms/form/DishNutritionalScoreForm';
import {paths} from 'src/navigation/paths';

import {useUploadDishDocumentsMutation} from 'src/hooks/mutations/useUploadDishDocumentsMutation';
import {useUploadDishImageMutation} from 'src/hooks/mutations/useUploadDishImageMutation';
import useSnackBar from 'src/hooks/useSnackBar';
import {useCreateDishMutation} from 'src/hooks/mutations/useCreateDishMutation';
import {useDishQuery} from 'src/hooks/queries/useDishQuery';
import {useDeleteDishImageMutation} from 'src/hooks/mutations/useDeleteDishImageMutation';
import {useUpdateDishMutation} from 'src/hooks/mutations/useUpdateDishMutation';
import {useDeleteDishDocumentMutation} from 'src/hooks/mutations/useDeleteDishDocumentMutation';

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const StepTitle = styled.text`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledDishInfoForm = styled(DishInfoForm)`
  margin-top: ${({theme}) => theme.margins.sm};
`;

const StyledDishIngredientsForm = styled(DishIngredientsForm)`
  margin-top: ${({theme}) => theme.margins.sm};
`;

const StyledDishAllergensForm = styled(DishAllergensForm)`
  margin-top: ${({theme}) => theme.margins.sm};
`;

const StyledDishNutritionalScoreForm = styled(DishNutritionalScoreForm)`
  margin-top: ${({theme}) => theme.margins.sm};
`;

enum DishSteps {
  Dish = 0,
  Ingredients = 1,
  Allergens = 2,
  NutritionalIntake = 3,
}

export default function CreateEditDishPage() {
  // Translation
  const {t} = useTranslation(['common', 'create-dish-page']);

  // Navigate
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Extract dishId from URL if exists
  const {id: dishId} = useParams();

  // State
  const [currentStep, setCurrentStep] = useState<DishSteps>(DishSteps.Dish);
  const dishValues = useRef<CreateDishValues | null>(null);

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Query
  const {data: dish} = useDishQuery({dishId: Number(dishId!)}, {enabled: !!dishId});

  // Mutations
  const {mutateAsync: createDish, isLoading: isSubmitting} = useCreateDishMutation();
  const {mutateAsync: uploadDishImage, isLoading: isUploadingImage} =
    useUploadDishImageMutation();
  const {mutateAsync: uploadDishDocuments, isLoading: isUploadingDocuments} =
    useUploadDishDocumentsMutation();
  const {mutateAsync: deleteDishImage} = useDeleteDishImageMutation();
  const {mutateAsync: deleteDishDocument} = useDeleteDishDocumentMutation();
  const {mutateAsync: updateDish, isLoading: isUpdatingDish} = useUpdateDishMutation();

  // Constants
  const steps = useMemo(
    () => [
      {
        title: t('create-dish-page:steps.0'),
      },
      {
        title: t('create-dish-page:steps.1'),
      },
      {
        title: t('create-dish-page:steps.2'),
      },
      {
        title: t('create-dish-page:steps.3'),
      },
    ],
    [t],
  );

  // Callbacks
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleBack = useCallback(() => {
    if (currentStep === DishSteps.Dish) {
      navigate(-1);
    } else {
      setCurrentStep(currentStep - 1);
    }
  }, [navigate, currentStep]);

  const handleFinish = useCallback(
    async ({
      image,
      documents,
      ...dishFields
    }: CreateDishInfo &
      CreateDishIngredients &
      CreateDishAllergens &
      CreateDishNutritionalScore) => {
      try {
        if (!dish) {
          const newDish = await createDish(dishFields);

          await uploadDishImage({
            image:
              'originFileObj' in image.file && image.file.originFileObj
                ? image.file.originFileObj
                : (image.file as RcFile),
            dishId: newDish.id,
          });

          if (documents && documents?.fileList?.length) {
            await uploadDishDocuments({
              documents: documents.fileList.map(
                file => file.originFileObj ?? (file as RcFile),
              ),
              dishId: newDish.id,
            });
          }
          showSuccessSnackBar(t('create-dish-page:success.created'));
          navigate(paths.chef.dishes.pattern);
        } else {
          await updateDish({
            dishId: dish.id,
            ...dishFields,
          });

          if (image?.file) {
            await deleteDishImage({
              imageId: dish.image.id,
              dishId: dish.id,
              key: dish.image.key,
            });

            await uploadDishImage({
              image:
                'originFileObj' in image.file && image.file.originFileObj
                  ? image.file.originFileObj
                  : (image.file as RcFile),
              dishId: dish.id,
            });
          }

          if (documents && documents?.fileList?.length) {
            for (const document of dish.documents) {
              if (
                !documents.fileList.find(file => 'id' in file && file.id === document.id)
              ) {
                await deleteDishDocument({
                  documentId: document.id,
                  dishId: dish.id,
                  key: document.key,
                });
              }
            }

            await uploadDishDocuments({
              documents: documents.fileList
                .filter(file => !('id' in file))
                .map(file => file.originFileObj ?? (file as RcFile)),
              dishId: dish.id,
            });
          }
          navigate(paths.chef.dishes.pattern);
          showSuccessSnackBar(t('create-dish-page:success.updated'));
        }
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [
      dish,
      t,
      navigate,
      createDish,
      uploadDishImage,
      showErrorSnackBar,
      showSuccessSnackBar,
      uploadDishDocuments,
      deleteDishImage,
      updateDish,
      deleteDishDocument,
    ],
  );

  const handleNextStep = useCallback(
    (
      values:
        | CreateDishInfo
        | CreateDishIngredients
        | CreateDishAllergens
        | CreateDishNutritionalScore,
    ) => {
      dishValues.current = {...dishValues.current, ...values};
      if (currentStep !== DishSteps.NutritionalIntake) {
        setCurrentStep(currentStep + 1);
      } else {
        handleFinish(
          dishValues.current as CreateDishInfo &
            CreateDishIngredients &
            CreateDishAllergens &
            CreateDishNutritionalScore,
        );
      }
    },
    [currentStep, handleFinish],
  );

  const renderStep = useCallback(() => {
    switch (currentStep) {
      case DishSteps.Dish:
        return (
          <StyledDishInfoForm
            onCancel={handleBack}
            onFinish={handleNextStep}
            dish={dish}
          />
        );
      case DishSteps.Ingredients:
        return (
          <StyledDishIngredientsForm
            dish={dish}
            onCancel={handleBack}
            onFinish={handleNextStep}
          />
        );
      case DishSteps.Allergens:
        return (
          <StyledDishAllergensForm
            dish={dish}
            onCancel={handleBack}
            onFinish={handleNextStep}
          />
        );
      case DishSteps.NutritionalIntake:
        return (
          <StyledDishNutritionalScoreForm
            onCancel={handleBack}
            onFinish={handleNextStep}
            isSubmitting={
              isSubmitting || isUploadingImage || isUploadingDocuments || isUpdatingDish
            }
          />
        );
      default:
        return <></>;
    }
  }, [
    currentStep,
    isSubmitting,
    isUploadingImage,
    isUploadingDocuments,
    isUpdatingDish,
    dish,
    handleBack,
    handleNextStep,
  ]);

  return (
    <>
      <Helmet>
        <title>{t('create-dish-page:helmet.title')}</title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        width={800}
        footer={<></>}>
        <HeaderContainer>
          <PlusIcon color={theme.colors.primary5} size={24} />

          <Title>{t('create-dish-page:title')}</Title>
        </HeaderContainer>

        <Divider />

        <Steps current={currentStep} labelPlacement="vertical" items={steps} />

        <Divider />

        <StepTitle>{t(`create-dish-page:steps.${currentStep}`)}</StepTitle>

        {renderStep()}
      </Modal>
    </>
  );
}
