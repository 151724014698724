import React, {useCallback, useState} from 'react';

import styled, {useTheme} from 'styled-components';
import {Button, List, Popover} from 'antd';
import {useTranslation} from 'react-i18next';

import {MenuType} from 'src/interfaces/api/generated.interface';

import {EditIcon} from 'src/components/atoms/icon/EditIcon';
import {TrashIcon} from 'src/components/atoms/icon/TrashIcon';
import {MoreIcon} from 'src/components/atoms/icon/MoreIcon';
import {InfoTag} from 'src/components/atoms/tag/InfoTag';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${({theme}) => theme.paddings.xxl} ${({theme}) => theme.paddings.lg};
  border-color: ${({theme}) => theme.colors.gray4} !important;
  border-width: ${({theme}) => theme.borders.xs} !important;
  border-radius: ${({theme}) => theme.radius.lg} !important;
  border-style: solid !important;
  transition: all 0.2s ease-in-out;
  margin-bottom: ${({theme}) => theme.margins.sm};
  &:hover {
    border-color: ${({theme}) => theme.colors.primary5} !important;
  }
`;

const StyledListItem = styled(List.Item)`
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
`;

const Name = styled.text`
  ${({theme}) => theme.textStyles.h5};
  color: ${({theme}) => theme.colors.gray12};
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MoreButton = styled(Button)`
  margin-left: ${({theme}) => theme.margins.sm};
`;

const PopoverButton = styled(Button)`
  align-items: center;
  display: flex;
  margin-bottom: ${({theme}) => theme.margins.xxs};
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface MenuCardProps {
  id: number;
  name: string;
  type: MenuType;
  onClick: (menuId: number) => void;
  onDelete: (menuId: number) => void;
  onEdit: (menuId: number) => void;
}

export const MenuCard: React.FC<MenuCardProps> = ({
  id,
  name,
  type,
  onClick,
  onDelete,
  onEdit,
}) => {
  // Translation
  const {t} = useTranslation(['common', 'menus-page']);

  // State
  const [popoverOpen, setPopoverOpen] = useState(false);

  // Theme
  const theme = useTheme();

  // Callbacks
  const handleItemClick = useCallback(() => {
    onClick?.(id);
  }, [id, onClick]);

  const handleEditClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      setPopoverOpen(false);
      onEdit?.(id);
    },
    [id, onEdit],
  );

  const handleDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      setPopoverOpen(false);
      onDelete?.(id);
    },
    [id, onDelete],
  );

  const renderMoreContent = useCallback(() => {
    return (
      <PopoverContainer>
        <PopoverButton icon={<EditIcon />} type="text" onClick={handleEditClick}>
          {t('buttons.edit')}
        </PopoverButton>

        {/* <PopoverButton icon={<CopyIcon />} type="text">
          {t('buttons.duplicate')}
        </PopoverButton> */}

        <PopoverButton
          icon={<TrashIcon size={20} strokeWidth={2} color={theme.colors.gray8} />}
          type="text"
          onClick={handleDeleteClick}>
          {t('buttons.delete')}
        </PopoverButton>
      </PopoverContainer>
    );
  }, [t, theme.colors.gray8, handleEditClick, handleDeleteClick]);

  return (
    <StyledListItem onClick={handleItemClick}>
      <Container>
        <Name>{t('menus-page:card.title', {name})}</Name>

        <RightContainer>
          <InfoTag
            value={
              type === MenuType.FIVE_DISHES
                ? t('common:menu-type.five-dishes')
                : t('common:menu-type.four-dishes')
            }
          />

          <Popover
            open={popoverOpen}
            content={renderMoreContent}
            onOpenChange={setPopoverOpen}
            trigger="click">
            <MoreButton
              shape="circle"
              icon={<MoreIcon />}
              onClick={e => e.stopPropagation()}
            />
          </Popover>
        </RightContainer>
      </Container>
    </StyledListItem>
  );
};
