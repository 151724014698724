import React, {useCallback, useEffect, useState} from 'react';

import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';
import {Layout, Menu} from 'antd';
import {useTranslation} from 'react-i18next';

import {HomeIcon} from 'src/components/atoms/icon/HomeIcon';
import {AppleIcon} from 'src/components/atoms/icon/AppleIcon';
import {HamburgerIcon} from 'src/components/atoms/icon/HamburgerIcon';
import {ListIcon} from 'src/components/atoms/icon/ListIcon';
import {CalendarIcon} from 'src/components/atoms/icon/CalendarIcon';
import {TrophyIcon} from 'src/components/atoms/icon/TrophyIcon';
import {ContactCard} from 'src/components/molecules/card/ContactCard';
import {AppActionBar} from 'src/components/molecules/header/AppActionBar';
import {paths} from 'src/navigation/paths';

import {ReactComponent as Logo} from 'src/assets/icons/app-logo-name.svg';

const {Content, Sider, Header} = Layout;

const StyledSider = styled(Sider)`
  background-color: ${({theme}) => theme.colors.white};
  min-height: 100vh;
  border-top-right-radius: ${({theme}) => theme.radius.xxl};
  border-bottom-right-radius: ${({theme}) => theme.radius.xxl};
  ${({theme}) => theme.shadows.sider.default};
`;

const StyledLayout = styled(Layout)`
  background-color: ${({theme}) => theme.colors.background};
  min-height: 100vh;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({theme}) => theme.margins.sm};
  margin-bottom: ${({theme}) => theme.margins.md};
`;

const Margin = styled.div`
  margin-top: ${({theme}) => theme.margins.md};
`;

const StyledHeader = styled(Header)`
  padding-left: ${({theme}) => theme.margins.md};
  padding-right: ${({theme}) => theme.margins.md};
  padding-top: ${({theme}) => theme.paddings.lg};
  background-color: ${({theme}) => theme.colors.background};
  flex-direction: row;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h3};
  color: ${({theme}) => theme.colors.gray12};
`;

const StyledContent = styled(Content)`
  padding: ${({theme}) => theme.margins.md};
  margin: ${({theme}) => theme.margins.md};
  border-radius: ${({theme}) => theme.radius.xxl};
  background-color: ${({theme}) => theme.colors.white};
  overflow: auto;
  height: 100%;
  ${({theme}) => theme.shadows.content.default};
`;

enum MenuItemKey {
  Home = '1',
  Foods = '2',
  Dishes = '3',
  Menus = '4',
  Meals = '5',
  Challenges = '6',
}

export const ChefLayout: React.FC<{}> = () => {
  // Navigation
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Translation
  const {t} = useTranslation();

  // State
  const [selectedMenuItem, setSelectedMenuItem] = useState(MenuItemKey.Home);

  // Location
  const location = useLocation();

  // Effects
  useEffect(() => {
    // Set selected menu item based on location
    if (location.pathname.includes(paths.chef.foods.pattern)) {
      setSelectedMenuItem(MenuItemKey.Foods);
    } else if (location.pathname.includes(paths.chef.dishes.pattern)) {
      setSelectedMenuItem(MenuItemKey.Dishes);
    } else if (location.pathname.includes(paths.chef.menus.pattern)) {
      setSelectedMenuItem(MenuItemKey.Menus);
    } else if (location.pathname.includes(paths.chef.meals.pattern)) {
      setSelectedMenuItem(MenuItemKey.Meals);
    } else if (location.pathname.includes(paths.chef.challenges.pattern)) {
      setSelectedMenuItem(MenuItemKey.Challenges);
    }
  }, [location]);

  // Callbacks
  const getTitle = useCallback(() => {
    switch (selectedMenuItem) {
      case MenuItemKey.Home:
        return t('sider-menu.home');
      case MenuItemKey.Foods:
        return t('sider-menu.foods');
      case MenuItemKey.Dishes:
        return t('sider-menu.dishes');
      case MenuItemKey.Menus:
        return t('sider-menu.menus');
      case MenuItemKey.Meals:
        return t('sider-menu.meals');
      case MenuItemKey.Challenges:
        return t('sider-menu.challenges');
      default:
        return '';
    }
  }, [selectedMenuItem, t]);

  const handleMenuItemSelected = useCallback(
    (info: {key: React.Key}) => {
      setSelectedMenuItem(info.key as MenuItemKey);

      switch (info.key) {
        case MenuItemKey.Home:
          navigate(paths.chef.home.pattern);
          break;
        case MenuItemKey.Foods:
          navigate(paths.chef.foods.pattern);
          break;
        case MenuItemKey.Dishes:
          navigate(paths.chef.dishes.pattern);
          break;
        case MenuItemKey.Menus:
          navigate(paths.chef.menus.pattern);
          break;
        case MenuItemKey.Meals:
          navigate(paths.chef.meals.pattern);
          break;
        case MenuItemKey.Challenges:
          navigate(paths.chef.challenges.pattern);
          break;

        default:
          break;
      }
    },
    [navigate],
  );

  const getItemColor = useCallback(
    (key: MenuItemKey) => {
      return selectedMenuItem === key ? theme.colors.primary5 : theme.colors.gray8;
    },
    [selectedMenuItem, theme.colors],
  );

  return (
    <Layout hasSider>
      <StyledSider
        width={parseInt(theme.widths.sider.default, 10)}
        style={{
          backgroundColor: theme.colors.white,
        }}>
        <LogoContainer>
          <Logo />
        </LogoContainer>

        <Menu
          mode="inline"
          selectedKeys={[selectedMenuItem]}
          onSelect={handleMenuItemSelected}>
          <Menu.ItemGroup key="grp1" title={t('sider-menu.group-1')}>
            <Menu.Item
              key={MenuItemKey.Home}
              icon={<HomeIcon color={getItemColor(MenuItemKey.Home)} />}>
              {t('sider-menu.home')}
            </Menu.Item>

            <Menu.Item
              key={MenuItemKey.Foods}
              icon={<AppleIcon color={getItemColor(MenuItemKey.Foods)} />}>
              {t('sider-menu.foods')}
            </Menu.Item>

            <Menu.Item
              key={MenuItemKey.Dishes}
              icon={<HamburgerIcon color={getItemColor(MenuItemKey.Dishes)} />}>
              {t('sider-menu.dishes')}
            </Menu.Item>

            <Menu.Item
              key={MenuItemKey.Menus}
              icon={<ListIcon color={getItemColor(MenuItemKey.Menus)} />}>
              {t('sider-menu.menus')}
            </Menu.Item>

            <Menu.Item
              key={MenuItemKey.Meals}
              icon={<CalendarIcon color={getItemColor(MenuItemKey.Meals)} />}>
              {t('sider-menu.meals')}
            </Menu.Item>
          </Menu.ItemGroup>

          <Margin />

          <Menu.ItemGroup key="grp2" title={t('sider-menu.group-2')}>
            <Menu.Item
              key={MenuItemKey.Challenges}
              icon={<TrophyIcon color={getItemColor(MenuItemKey.Challenges)} />}>
              {t('sider-menu.challenges')}
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu>

        <ContactCard />
      </StyledSider>

      <StyledLayout>
        <StyledHeader>
          <Title>{getTitle()}</Title>

          <AppActionBar />
        </StyledHeader>

        <StyledContent>
          <Outlet />
        </StyledContent>
      </StyledLayout>
    </Layout>
  );
};
