import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {UpdateDish} from 'src/interfaces/api/dish.interface';
import {Dish} from 'src/interfaces/api/generated.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useUpdateDishMutation() {
  return useMutation({
    mutationFn: async (dishData: UpdateDish): Promise<Dish> => {
      const {data} = await networkClient.put<Dish>(Routes.Dish, dishData);

      queryClient.invalidateQueries([Routes.Dishes]);
      queryClient.invalidateQueries([Routes.Dish, dishData.dishId]);

      return data;
    },
  });
}
