import React, {useCallback, useMemo} from 'react';

import {Col} from 'antd';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';

import {DishType, MenuType} from 'src/interfaces/api/generated.interface';
import {MenuSection} from 'src/interfaces/menu.interface';

import {menuFiveSections, menuFourSections} from 'src/constants/menu';

const SectionsContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  padding-top: ${({theme}) => theme.paddings.xs};
  padding-bottom: ${({theme}) => theme.paddings.xs};
  padding-left: ${({theme}) => theme.paddings.xs};
`;

const SectionItemContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${({theme}) => theme.paddings.sm};
  padding-bottom: ${({theme}) => theme.paddings.sm};
  padding-left: ${({theme}) => theme.paddings.xs};
  margin-bottom: ${({theme}) => theme.margins.xs};
  position: relative;
  border: none;
  background-color: transparent;
`;

const SectionName = styled.text<{isActive: boolean}>`
  ${({theme, isActive}) => (isActive ? theme.textStyles.p2Bold : theme.textStyles.p2)};
  color: ${({theme, isActive}) => (isActive ? theme.colors.gray12 : theme.colors.gray8)};
  margin-left: ${({theme}) => theme.paddings.sm};
  margin-bottom: ${({theme}) => -parseInt(theme.margins.xxs, 10)}px;
`;

const SectionIndicator = styled.div`
  height: ${({theme}) => theme.heights.menuIndicator.default};
  width: ${({theme}) => theme.widths.menuIndicator.default};
  border-radius: ${({theme}) => theme.radius.xl};
  background-color: ${({theme}) => theme.colors.primary5};
  right: 0px;
  position: absolute;
`;

interface MenuSectionsMenuProps extends React.ComponentProps<typeof Col> {
  activeSection: DishType;
  menuType: MenuType | undefined;
  setActiveSection: (section: DishType) => void;
}

export const MenuSectionsMenu: React.FC<MenuSectionsMenuProps> = ({
  activeSection,
  menuType,
  setActiveSection,
  ...rest
}) => {
  // Theme
  const theme = useTheme();

  // Translations
  const {t} = useTranslation();

  // Constants
  const sections: MenuSection[] = useMemo(
    () => (menuType === MenuType.FIVE_DISHES ? menuFiveSections : menuFourSections),
    [menuType],
  );

  // Callbacks
  const renderSection = useCallback(
    (section: MenuSection) => {
      const isActive = activeSection === section.key;
      return (
        <SectionItemContainer
          key={section.key}
          onClick={() => setActiveSection(section.key)}>
          {React.cloneElement(section.icon, {
            color: isActive ? theme.colors.primary5 : theme.colors.gray8,
          })}

          {/* @ts-ignore */}
          <SectionName isActive={isActive}>{t(section.name)}</SectionName>

          {isActive && <SectionIndicator />}
        </SectionItemContainer>
      );
    },
    [setActiveSection, activeSection, theme.colors.gray8, theme.colors.primary5, t],
  );

  return (
    <SectionsContainer span={8} {...rest}>
      {sections.map(renderSection)}
    </SectionsContainer>
  );
};
