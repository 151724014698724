import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from 'src/redux/slices/app';
import userReducer from 'src/redux/slices/user';

const appPersistConfig = {
  key: 'app',
  storage,
  version: 1,
};

const userPersistConfig = {
  key: 'user',
  storage,
  version: 1,
};

const rootReducer = combineReducers({
  app: persistReducer(appPersistConfig, appReducer),
  user: persistReducer(userPersistConfig, userReducer),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
