import React from 'react';

import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  align-items: center;
  justify-content: center;
  padding: ${({theme}) => theme.paddings.lg};
`;

const Text = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
`;

interface EmptyResultsProps {
  text?: string;
}

export const EmptyResults: React.FC<EmptyResultsProps> = ({text}) => {
  // Translation
  const {t} = useTranslation();

  return (
    <Container>
      <Text>{text ?? t('empty-results')}</Text>
    </Container>
  );
};
