import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const ThumbUpInfoIcon = ({
  width = 33,
  height = 24,
  strokeWidth,
  color,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width} height={height} fill="none" {...rest}>
      <circle cx="12" cy="12" r="12" fill={theme.colors.primary5} />

      <path
        d="M17.2997 10.4995V20.0035"
        stroke={color ?? theme.colors.gray12}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />

      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2997 10.4996L19.6364 4.62759C19.8381 4.12087 20.4084 3.86902 20.9188 4.06136L21.5582 4.30237C22.7272 4.74294 23.501 5.86153 23.501 7.11077V10.4995H26.5011C27.2712 10.4995 27.9984 10.8543 28.4724 11.4614C28.9463 12.0684 29.1142 12.86 28.9274 13.6072L27.8019 18.109C27.5236 19.2224 26.5232 20.0035 25.3756 20.0035H14.4973C13.6685 20.0035 12.9967 19.3316 12.9967 18.5029V12.0001C12.9967 11.1714 13.6685 10.4995 14.4973 10.4995L17.2997 10.4996Z"
        stroke={color ?? theme.colors.gray12}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
