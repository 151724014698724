import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {GetMenus, GetMenusResponse} from 'src/interfaces/api/menu.interface';

import {networkClient} from 'src/services/client';

export function useMenusQuery(
  {page, search}: GetMenus,
  options?: UseQueryOptions<GetMenusResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.Menus, page, search],
    queryFn: async (): Promise<GetMenusResponse | null> => {
      const {data} = await networkClient.get(Routes.Menus, {
        params: {page: page ?? 1, search},
      });

      return data;
    },
    keepPreviousData: true,
    ...options,
  });
}
