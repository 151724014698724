import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {Menu} from 'src/interfaces/api/generated.interface';
import {DeleteMenu} from 'src/interfaces/api/menu.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useDeleteMenuMutation() {
  return useMutation({
    mutationFn: async (deleteData: DeleteMenu): Promise<Menu> => {
      const {data} = await networkClient.delete<Menu>(Routes.Menu, {
        data: deleteData,
      });

      queryClient.invalidateQueries([Routes.Menus]);

      return data;
    },
  });
}
