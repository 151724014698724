import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {UpdateFoodParams} from 'src/interfaces/api/food.interface';
import {Food} from 'src/interfaces/api/generated.interface';

import {networkClient} from 'src/services/client';

export function useUpdateFoodMutation() {
  return useMutation({
    mutationFn: async (foodData: UpdateFoodParams): Promise<Food> => {
      const {data} = await networkClient.put<Food>(Routes.Food, foodData);

      return data;
    },
  });
}
