import React from 'react';

import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';

import {NotFoundPage} from 'src/pages/NotFoundPage';
import SignInPage from 'src/pages/auth/sign-in/SignInPage';
import SignUpPage from 'src/pages/auth/sign-up/SignUpPage';
import FoodsPage from 'src/pages/chef/food/FoodsPage';
import HomePage from 'src/pages/chef/home/HomePage';
import CreateEditDishPage from 'src/pages/chef/dish/CreateEditDishPage';
import DishDetailsPage from 'src/pages/chef/dish/DishDetailsPage';
import DishesPage from 'src/pages/chef/dish/DishesPage';
import CreateEditMenuPage from 'src/pages/chef/menu/CreateEditMenuPage';
import MenuDetailsPage from 'src/pages/chef/menu/MenuDetailsPage';
import MealDishRatingsPage from 'src/pages/chef/home/MealDishRatingsPage';
import FoodDetailsPage from 'src/pages/chef/food/FoodDetailsPage';
import ForgotPasswordPage from 'src/pages/auth/forgot-password/ForgotPasswordPage';
import MenusPage from 'src/pages/chef/menu/MenusPage';
import MyAccountPage from 'src/pages/common/MyAccountPage';
import CGUChefPage from 'src/pages/cgu/CGUChefPage';
import CGUParentPage from 'src/pages/cgu/CGUParentPage';
import ReportWeatherPage from 'src/pages/chef/home/ReportWeatherPage';
import ResetPasswordPage from 'src/pages/auth/reset-password/ResetPasswordPage';
import ChallengesPage from 'src/pages/chef/challenges/ChallengesPage';
import EntitiesPage from 'src/pages/admin/entities/EntitiesPage';
import CreateEditEntityPage from 'src/pages/admin/entities/CreateEditEntityPage';
import MealsPage from 'src/pages/chef/meal/MealsPage';
import CreateEditFoodPage from 'src/pages/admin/foods/CreateEditFoodPage';
import {ChefLayout} from 'src/components/organisms/layout/ChefLayout';
import {AuthLayout} from 'src/components/organisms/layout/AuthLayout';
import {AdminLayout} from 'src/components/organisms/layout/AdminLayout';
import {paths} from 'src/navigation/paths';

import useAuth from 'src/hooks/useAuth';
import useCurrentUser from 'src/hooks/useCurrentUser';
import {RequireChefGuard} from 'src/guards/RequireChefGuard';
import {RequireAdminGuard} from 'src/guards/RequireAdminGuard';

export default function Navigation() {
  // Translation
  const {i18n, t} = useTranslation();

  // Location
  const location = useLocation();

  // Auth
  const {isSignedIn} = useAuth();

  // Previous location
  const previousLocation = location.state?.previousLocation;

  // Current user
  const {isRetrieved, isLoading, isAdmin, isChef} = useCurrentUser();

  if (isLoading && !isRetrieved && isSignedIn) {
    return null;
  }

  return (
    <>
      <Helmet title={t('helmet.title')} htmlAttributes={{lang: i18n.language}}>
        <meta name="description" content={t('helmet.description')} />
      </Helmet>

      <Routes location={previousLocation || location}>
        <Route path={paths.auth.pattern} element={<AuthLayout />}>
          <Route path={paths.auth.signIn.pattern} element={<SignInPage />} />

          <Route
            path={paths.auth.forgotPassword.pattern}
            element={<ForgotPasswordPage />}
          />

          <Route
            path={paths.auth.resetPassword.pattern}
            element={<ResetPasswordPage />}
          />

          <Route path={paths.auth.signUp.pattern} element={<SignUpPage />} />
        </Route>

        <Route path={paths.cguChef.pattern} element={<CGUChefPage />} />

        <Route path={paths.cguParent.pattern} element={<CGUParentPage />} />

        {/* Home Redirect */}
        <Route
          path={paths.home.pattern}
          element={
            isAdmin ? (
              <Navigate to={paths.admin.entities.pattern} replace />
            ) : isChef ? (
              <Navigate to={paths.chef.home.pattern} replace />
            ) : (
              <Navigate to={paths.auth.signIn.pattern} replace />
            )
          }
        />

        {/* Admin Pages */}
        <Route
          path={paths.admin.pattern}
          element={
            <RequireAdminGuard>
              <AdminLayout />
            </RequireAdminGuard>
          }>
          {/* Entities Page */}
          <Route path={paths.admin.entities.pattern} element={<EntitiesPage />} />

          <Route path={paths.admin.foods.pattern} element={<FoodsPage />} />

          <Route path={paths.admin.dishes.pattern} element={<DishesPage />} />

          {/* My Account */}

          <Route path={paths.admin.myAccount.pattern} element={<MyAccountPage />} />
        </Route>

        {/* Chef Pages */}
        <Route
          path={paths.chef.pattern}
          element={
            <RequireChefGuard>
              <ChefLayout />
            </RequireChefGuard>
          }>
          {/* Home */}

          <Route path={paths.chef.home.pattern} element={<HomePage />} />

          {/* My Account */}

          <Route path={paths.chef.myAccount.pattern} element={<MyAccountPage />} />

          {/* Food Pages */}

          <Route path={paths.chef.foods.pattern} element={<FoodsPage />} />

          {/* Dish Pages */}

          <Route path={paths.chef.dishes.pattern} element={<DishesPage />} />

          {/* Menu Pages */}

          <Route path={paths.chef.menus.pattern} element={<MenusPage />} />

          {/* Meal Pages */}

          <Route path={paths.chef.meals.pattern} element={<MealsPage />} />

          {/* Challenges Pages */}

          <Route path={paths.chef.challenges.pattern} element={<ChallengesPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {/* Admin Modal Pages */}
      {previousLocation && isAdmin && (
        <RequireAdminGuard>
          <Routes>
            {/* Entities Pages */}

            <Route
              path={paths.admin.entities.create.pattern}
              element={<CreateEditEntityPage />}
            />

            <Route
              path={paths.admin.entities.edit.pattern}
              element={<CreateEditEntityPage />}
            />

            {/* Food Pages */}

            <Route
              path={paths.admin.foods.create.pattern}
              element={<CreateEditFoodPage />}
            />

            <Route
              path={paths.admin.foods.edit.pattern}
              element={<CreateEditFoodPage />}
            />

            <Route
              path={paths.admin.foods.details.pattern}
              element={<FoodDetailsPage />}
            />

            {/* <Route path={paths.chef.foods.edit.pattern} element={<EditFoodPage />} /> */}

            {/* Dish Pages */}

            <Route
              path={paths.admin.dishes.create.pattern}
              element={<CreateEditDishPage />}
            />

            <Route
              path={paths.admin.dishes.edit.pattern}
              element={<CreateEditDishPage />}
            />

            <Route
              path={paths.admin.dishes.details.pattern}
              element={<DishDetailsPage />}
            />
          </Routes>
        </RequireAdminGuard>
      )}

      {/* Chef Modal Pages */}
      {previousLocation && isChef && (
        <RequireChefGuard>
          <Routes>
            {/* Food Pages */}

            <Route
              path={paths.chef.foods.details.pattern}
              element={<FoodDetailsPage />}
            />

            {/* Dish Pages */}

            <Route
              path={paths.chef.dishes.create.pattern}
              element={<CreateEditDishPage />}
            />

            <Route
              path={paths.chef.dishes.edit.pattern}
              element={<CreateEditDishPage />}
            />

            <Route
              path={paths.chef.dishes.details.pattern}
              element={<DishDetailsPage />}
            />

            {/* Menu Pages */}

            <Route
              path={paths.chef.menus.create.pattern}
              element={<CreateEditMenuPage />}
            />

            <Route
              path={paths.chef.menus.edit.pattern}
              element={<CreateEditMenuPage />}
            />

            <Route
              path={paths.chef.menus.details.pattern}
              element={<MenuDetailsPage />}
            />

            {/* Home Pages */}

            <Route
              path={paths.chef.home.mealDishRatings.pattern}
              element={<MealDishRatingsPage />}
            />

            <Route
              path={paths.chef.home.reportWeather.pattern}
              element={<ReportWeatherPage />}
            />
          </Routes>
        </RequireChefGuard>
      )}
    </>
  );
}
