import React from 'react';

import styled from 'styled-components';
import {Row} from 'antd';
import {useTranslation} from 'react-i18next';

import {PhoneIcon} from 'src/components/atoms/icon/PhoneIcon';
import {MessageIcon} from 'src/components/atoms/icon/MessageIcon';

const FooterHelpContainer = styled(Row)`
  margin-top: ${({theme}) => theme.margins.md};
`;

const FooterContactRow = styled(Row)`
  margin-top: ${({theme}) => theme.margins.xs};
`;

const StyledMessageIcon = styled(MessageIcon)`
  margin-right: ${({theme}) => theme.margins.xs};
  margin-left: ${({theme}) => theme.margins.xl};
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const FooterText = styled.text`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray8};
  text-align: center;
`;

export const HelpFooter: React.FC = () => {
  // Translation
  const {t} = useTranslation('common');

  return (
    <>
      <FooterHelpContainer justify="center">
        <FooterText>{t('help.help')}</FooterText>
      </FooterHelpContainer>

      <FooterContactRow justify="center">
        <StyledPhoneIcon />

        <FooterText>{t('help.phone')}</FooterText>

        <StyledMessageIcon />

        <FooterText>{t('help.email')}</FooterText>
      </FooterContactRow>
    </>
  );
};
