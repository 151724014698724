import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {User} from 'src/interfaces/api/generated.interface';
import {UpdateUserParams} from 'src/interfaces/api/user.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useUpdateUserMutation() {
  return useMutation({
    mutationFn: async (params: UpdateUserParams): Promise<User> => {
      const {data} = await networkClient.put<User>(Routes.User, params);

      queryClient.invalidateQueries([Routes.Me]);
      return data;
    },
  });
}
