import {createSlice, CaseReducer, PayloadAction} from '@reduxjs/toolkit';

interface InitialState {
  locale: `${string}-${string}` | null;
}

const setLocale: CaseReducer<
  InitialState,
  PayloadAction<`${string}-${string}`>
> = (state, action) => {
  state.locale = action.payload;
};

const initialState: InitialState = {
  locale: null,
};

const {actions, reducer} = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLocale,
  },
});

export {actions as appActions};
export default reducer;
