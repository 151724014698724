import {EntityStatus, StatusConfig} from 'src/interfaces/entity.interface';

import {Theme} from 'src/constants/theme';

export const genStatusItems = (
  theme: Theme,
): {
  [key in EntityStatus]: StatusConfig;
} => ({
  [EntityStatus.Invited]: {
    color: theme.colors.primary2,
    textColor: theme.colors.primary6,
  },
  [EntityStatus.Registered]: {
    color: theme.colors.green2,
    textColor: theme.colors.green8,
  },
});
