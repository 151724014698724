import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const EditIcon = ({size = 20, color, strokeWidth = 1.5, ...rest}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M9.577 2.324H6.46c-2.562 0-4.17 1.814-4.17 4.383v6.928c0 2.568 1.6 4.383 4.17 4.383h7.353c2.571 0 4.17-1.815 4.17-4.383v-3.357"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m7.356 9.1 6.228-6.227a1.988 1.988 0 0 1 2.81 0l1.013 1.014a1.986 1.986 0 0 1 0 2.81l-6.257 6.257c-.34.34-.8.53-1.28.53H6.75l.078-3.15c.012-.463.201-.905.53-1.233Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m12.638 3.835 3.805 3.805"
      />
    </svg>
  );
};
