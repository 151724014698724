import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const MilkIcon = ({size = 20, color, strokeWidth = 1.5, ...rest}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M7.082 2.497h5.836c.46 0 .834.373.834.834v1.667H6.248V3.33c0-.46.374-.834.834-.834Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M7.499 8.333h5.002"
      />

      <circle
        cx={10}
        cy={12.501}
        r={1.667}
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m6.248 4.998-1.387 2.08c-.183.275-.28.597-.28.926v7.832c0 .92.747 1.667 1.667 1.667h7.503c.921 0 1.668-.746 1.668-1.667V8.004c0-.33-.098-.651-.28-.925l-1.387-2.081"
      />
    </svg>
  );
};
