import React, {useCallback} from 'react';

import {Form, Row} from 'antd';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import {ForgotPassword} from 'src/interfaces/api/user.interface';

import {Input} from 'src/components/atoms/input/Input';
import {HelpFooter} from 'src/components/molecules/footer/HelpFooter';
import {Button} from 'src/components/atoms/button/Button';
import {ProfileIcon} from 'src/components/atoms/icon/ProfileIcon';

import {useForgotPasswordMutation} from 'src/hooks/mutations/useForgotPasswordMutation';
import useSnackBar from 'src/hooks/useSnackBar';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${({theme}) => theme.paddings.lg};
`;

const StyledRow = styled(Row)`
  display: flex;
  width: 100%;
`;

const ButtonRow = styled(Row)`
  margin-top: ${({theme}) => theme.margins.md};
`;

const SendButton = styled(Button)`
  width: 100%;
`;

const StyledForm = styled(Form<ForgotPassword>)`
  width: 60%;
`;

const BackButton = styled(Button)`
  width: 48%;
`;

const StyledFormItem = styled(Form.Item)`
  width: 48%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h4};
  color: ${({theme}) => theme.colors.gray12};
`;

const Description = styled.text`
  ${({theme}) => theme.textStyles.h7};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.xl};
  margin-top: ${({theme}) => theme.margins.xs};
`;

export default function ForgotPasswordPage() {
  // Translation
  const {t} = useTranslation('forgot-password-page');

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Navigate
  const navigate = useNavigate();

  // Mutation
  const {mutateAsync: forgotPassword, isLoading} = useForgotPasswordMutation();

  // Callbacks
  const handleFinish = useCallback(
    async (values: ForgotPassword) => {
      try {
        await forgotPassword(values.email);
        showSuccessSnackBar(t('success.sent'));
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [t, showErrorSnackBar, showSuccessSnackBar, forgotPassword],
  );

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Container>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <StyledRow justify="center">
        <StyledForm layout="vertical" onFinish={handleFinish} autoComplete="off">
          <ContentContainer>
            <Title>{t('title')}</Title>

            <Description>{t('description')}</Description>

            <Form.Item
              label={t('fields.email')}
              name="email"
              rules={[{required: true, message: t('errors.email')}]}>
              <Input prefix={<ProfileIcon />} placeholder={t('placeholders.email')} />
            </Form.Item>

            <ButtonRow justify="space-between">
              <BackButton type="default" onClick={handleGoBack}>
                {t('buttons.back')}
              </BackButton>

              <StyledFormItem>
                <SendButton type="primary" htmlType="submit" loading={isLoading}>
                  {t('buttons.send')}
                </SendButton>
              </StyledFormItem>
            </ButtonRow>
          </ContentContainer>

          <HelpFooter />
        </StyledForm>
      </StyledRow>
    </Container>
  );
}
