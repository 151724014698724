import React, {useCallback, useMemo, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {Col, Divider, List, Modal} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';

import {DishType, MenuType} from 'src/interfaces/api/generated.interface';

import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {CalendarIcon} from 'src/components/atoms/icon/CalendarIcon';
import {MenuSectionsMenu} from 'src/components/molecules/menu/MenuSectionsMenu';
import {SmallDishCard} from 'src/components/molecules/card/SmallDishCard';
import {ModalFooter} from 'src/components/molecules/footer/ModalFooter';
import {paths} from 'src/navigation/paths';

import {useMenuQuery} from 'src/hooks/queries/useMenuQuery';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const SectionDishesContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-left: ${({theme}) => theme.margins.sm};
  overflow: scroll;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({theme}) => theme.heights.menuForm.default};
`;

const StyledSmallDishCard = styled(SmallDishCard)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

export default function MenuDetailsPage() {
  // Translation
  const {t} = useTranslation(['common', 'menu-details-page']);

  // Navigate
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Extract menuId from URL
  const {id: menuId} = useParams();

  // State
  const [activeSection, setActiveSection] = useState<DishType>(DishType.STARTER);

  // Query
  const {data: menu, isLoading} = useMenuQuery(
    {menuId: Number(menuId!)},
    {enabled: !!menuId},
  );

  // Constants
  const sectionDishes = useMemo(
    () =>
      menu?.dishes.filter(
        dish =>
          dish.type === activeSection ||
          (dish.type === DishType.DAIRY_PRODUCT &&
            menu.type === MenuType.FOUR_DISHES &&
            activeSection === DishType.DESSERT),
      ),
    [activeSection, menu?.dishes, menu?.type],
  );

  // Callbacks
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleEditMenu = useCallback(() => {
    if (menuId) {
      navigate(paths.chef.menus.edit({id: menuId}));
    }
  }, [navigate, menuId]);

  return (
    <>
      <Helmet>
        <title>{t('menu-details-page:helmet')}</title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        width={734}
        footer={<></>}>
        <HeaderContainer>
          <CalendarIcon width={24} height={24} color={theme.colors.primary5} />

          <Title>{t('menu-details-page:title', {name: menu?.name})}</Title>
        </HeaderContainer>

        <Divider />

        <ContentContainer>
          <MenuSectionsMenu
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            menuType={menu?.type}
            span={10}
          />

          <SectionDishesContainer span={14}>
            <List
              loading={isLoading}
              dataSource={sectionDishes}
              renderItem={item => (
                <StyledSmallDishCard
                  image={item?.image?.url}
                  name={item.name}
                  id={item.id}
                  allergens={item.allergens}
                />
              )}
            />
          </SectionDishesContainer>
        </ContentContainer>

        <Divider />

        <ModalFooter
          onRightButtonClick={handleEditMenu}
          onLeftButtonClick={handleCancel}
          rightButtonText={t('common:buttons.edit')}
        />
      </Modal>
    </>
  );
}
