import React, {useCallback} from 'react';

import {Helmet} from 'react-helmet-async';
import {Checkbox, Form, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'antd/es/form/Form';
import styled from 'styled-components';

import {SignUp} from 'src/interfaces/api/user.interface';

import {Input} from 'src/components/atoms/input/Input';
import {Button} from 'src/components/atoms/button/Button';
import {HelpFooter} from 'src/components/molecules/footer/HelpFooter';
import {ProfileIcon} from 'src/components/atoms/icon/ProfileIcon';
import {MessageIcon} from 'src/components/atoms/icon/MessageIcon';
import {LockIcon} from 'src/components/atoms/icon/LockIcon';
import {paths} from 'src/navigation/paths';

import {useSignUpChefMutation} from 'src/hooks/mutations/useSignUpChefMutation';
import useSearchParams from 'src/hooks/useSearchParams';
import useSnackBar from 'src/hooks/useSnackBar';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${({theme}) => theme.paddings.lg};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRow = styled(Row)`
  display: flex;
  width: 100%;
`;

const StyledForm = styled(Form<SignUp>)`
  width: 60%;
`;

const SignUpButton = styled(Button)`
  margin-top: ${({theme}) => theme.margins.md};
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h4};
  color: ${({theme}) => theme.colors.gray12};
`;

const Description = styled.text`
  ${({theme}) => theme.textStyles.h7};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.xl};
  margin-top: ${({theme}) => theme.margins.xs};
`;

const Link = styled.span`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray10};
  text-decoration: underline;
  margin-left: ${({theme}) => theme.margins.md};
  position: absolute;
`;

const StyledFormItem = styled(Form.Item)`
  width: auto;
  flex-direction: column;
`;

export default function SignUpPage() {
  // Translation
  const {t} = useTranslation('sign-up-page');

  // Extract entityId from URL
  const {entityId} = useParams();

  // Navigate
  const navigate = useNavigate();

  // Form
  const [formInstance] = useForm();

  // Extract token from query string
  const [searchParams] = useSearchParams<typeof paths.auth.signUp>();
  const token = searchParams.get('token');

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Mutation
  const {mutateAsync: signUp} = useSignUpChefMutation();

  const handleFinish = useCallback(
    async ({confirmPassword: _confirmPassword, ...rest}: SignUp) => {
      try {
        if (!entityId) {
          throw new Error(t('errors.entity-missing'));
        }
        if (!token) {
          throw new Error(t('errors.token-missing'));
        }
        await signUp({...rest, entityId: Number(entityId), token});
        showSuccessSnackBar(t('success.sign-up'));
        navigate(paths.auth.signIn.pattern);
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [showErrorSnackBar, showSuccessSnackBar, t, signUp, navigate, entityId, token],
  );

  const handleOpenCgu = useCallback(() => {
    navigate(paths.cguChef.pattern);
  }, [navigate]);

  return (
    <Container>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <StyledRow justify="center">
        <StyledForm
          form={formInstance}
          onFinish={handleFinish}
          autoComplete="off"
          layout="vertical">
          <ContentContainer>
            <Title>{t('title')}</Title>

            <Description>{t('description')}</Description>

            <Form.Item
              label={t('fields.last-name')}
              name="lastName"
              rules={[{required: true, message: t('errors.last-name-required')}]}>
              <Input prefix={<ProfileIcon />} placeholder={t('placeholders.last-name')} />
            </Form.Item>

            <Form.Item
              label={t('fields.first-name')}
              name="firstName"
              rules={[{required: true, message: t('errors.first-name-required')}]}>
              <Input
                prefix={<ProfileIcon />}
                placeholder={t('placeholders.first-name')}
              />
            </Form.Item>

            <Form.Item
              label={t('fields.email')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('errors.email-required'),
                },
                {
                  type: 'email',
                  message: t('errors.email-invalid'),
                },
              ]}>
              <Input prefix={<MessageIcon />} placeholder={t('placeholders.email')} />
            </Form.Item>

            <Form.Item
              label={t('fields.password')}
              name="password"
              rules={[{required: true, message: t('errors.password-required')}]}>
              <Input
                isPassword
                prefix={<LockIcon />}
                placeholder={t('placeholders.password')}
              />
            </Form.Item>

            <Form.Item
              label={t('fields.confirm-password')}
              name="confirmPassword"
              rules={[
                {required: true, message: t('errors.confirm-password-required')},
                {
                  validator: (_, value) => {
                    const {getFieldValue} = formInstance;
                    if (value && value !== getFieldValue('password')) {
                      return Promise.reject(
                        new Error(t('errors.confirm-password-mismatch')),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <Input
                isPassword
                prefix={<LockIcon />}
                placeholder={t('placeholders.password')}
              />
            </Form.Item>

            <Row>
              <StyledFormItem
                name="cgu"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(new Error('Should accept agreement')),
                    message: t('errors.cgu-required'),
                  },
                ]}>
                <Checkbox />
              </StyledFormItem>

              <Link onClick={handleOpenCgu}>{t('fields.cgu')}</Link>
            </Row>

            <Form.Item>
              <SignUpButton type="primary" htmlType="submit" block>
                {t('buttons.sign-up')}
              </SignUpButton>
            </Form.Item>
          </ContentContainer>

          <HelpFooter />
        </StyledForm>
      </StyledRow>
    </Container>
  );
}
