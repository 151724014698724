import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {CreateDishMutation} from 'src/interfaces/api/dish.interface';
import {Dish} from 'src/interfaces/api/generated.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useCreateDishMutation() {
  return useMutation({
    mutationFn: async (dishData: CreateDishMutation): Promise<Dish> => {
      const {data} = await networkClient.post<Dish>(Routes.Dish, dishData);

      queryClient.invalidateQueries([Routes.Dishes]);

      return data;
    },
  });
}
