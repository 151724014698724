import React, {useCallback, useMemo, useState} from 'react';

import moment from 'moment';
import styled, {useTheme} from 'styled-components';
import {Button} from 'antd';

import {ArrowDownIcon} from 'src/components/atoms/icon/ArrowDownIcon';
import {ArrowUpIcon} from 'src/components/atoms/icon/ArrowUpIcon';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

const DateText = styled.span`
  ${({theme}) => theme.textStyles.h4};
  color: ${({theme}) => theme.colors.gray12};
  text-transform: capitalize;
  margin-right: ${({theme}) => theme.margins.xs};
`;

const PickersContainer = styled.div<{visible: boolean}>`
  height: ${({theme, visible}) => (visible ? theme.heights.monthPicker.default : '0px')};
  width: ${({theme}) => theme.widths.monthPicker.default};
  border-radius: ${({theme}) => theme.radius.lg};
  border: ${({visible}) => (visible ? '1px' : '0px')} solid
    ${({theme}) => theme.colors.gray4};
  background-color: ${({theme}) => theme.colors.white};
  position: absolute;
  top: 35px;
  overflow: hidden;
  flex-direction: row;
  display: flex;
  transition: height 0.2s ease-in-out;
  ${({theme}) => theme.shadows.monthYearPicker.default};
`;

const ItemText = styled.span<{selected: boolean}>`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme, selected}) =>
    selected ? theme.colors.primary5 : theme.colors.gray12};
  text-transform: capitalize;
  display: block;
  cursor: default;
  padding: ${({theme}) => theme.paddings.xs};
`;

const PickerContainer = styled.div<{bordered?: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  border-right: ${({bordered}) => (bordered ? '1px' : '0px')} solid
    ${({theme}) => theme.colors.gray4};
`;

const ArrowDownButton = styled(Button)<{open: boolean}>`
  rotate: ${({open}) => (open ? '180deg' : '0deg')};
  transition: rotate 0.2s ease-in-out;
`;

interface MonthYearPickerProps extends React.HTMLAttributes<HTMLDivElement> {
  selectedDate: Date;
  onChangeDate: (date: Date) => void;
}

export const MonthYearPicker: React.FC<MonthYearPickerProps> = ({
  selectedDate,
  onChangeDate,
  ...rest
}) => {
  // State
  const [isOpened, setIsOpened] = useState(false);

  // Constants
  const months = useMemo(() => {
    const startDate = moment(selectedDate).subtract(4, 'months').toDate();

    return Array.from(Array(7)).map((_, index) =>
      moment(startDate).add(index, 'months').toDate(),
    );
  }, [selectedDate]);

  const years = useMemo(() => {
    const startDate = moment(selectedDate).subtract(4, 'years').toDate();

    return Array.from(Array(7)).map((_, index) =>
      moment(startDate).add(index, 'years').toDate(),
    );
  }, [selectedDate]);

  // Callbacks
  const onItemClicked = useCallback(
    (date: Date) => {
      onChangeDate(date);
      setIsOpened(false);
    },
    [onChangeDate],
  );

  const renderMonth = useCallback(
    (month: Date) => (
      <ItemText
        selected={moment(month).isSame(selectedDate)}
        key={month.toString()}
        onClick={() => onItemClicked(month)}>
        {moment(month).format('MMMM')}
      </ItemText>
    ),
    [selectedDate, onItemClicked],
  );

  const renderYear = useCallback(
    (month: Date) => (
      <ItemText
        selected={moment(month).isSame(selectedDate)}
        key={month.toString()}
        onClick={() => onItemClicked(month)}>
        {moment(month).format('YYYY')}
      </ItemText>
    ),
    [selectedDate, onItemClicked],
  );

  return (
    <Container {...rest}>
      <DateText>{moment(selectedDate).format('MMMM YYYY')}</DateText>

      <ArrowDownButton
        open={isOpened}
        shape="circle"
        type="text"
        icon={<ArrowDownIcon />}
        onClick={() => setIsOpened(!isOpened)}
      />

      <PickersContainer visible={isOpened}>
        <PickerContainer bordered>
          <Button
            shape="circle"
            type="text"
            icon={<ArrowUpIcon />}
            onClick={() =>
              onChangeDate(moment(selectedDate).subtract(1, 'months').toDate())
            }
          />

          {months.map(renderMonth)}

          <Button
            shape="circle"
            type="text"
            icon={<ArrowDownIcon />}
            onClick={() => onChangeDate(moment(selectedDate).add(1, 'months').toDate())}
          />
        </PickerContainer>

        <PickerContainer>
          <Button
            shape="circle"
            type="text"
            icon={<ArrowUpIcon />}
            onClick={() =>
              onChangeDate(moment(selectedDate).subtract(1, 'years').toDate())
            }
          />

          {years.map(renderYear)}

          <Button
            shape="circle"
            type="text"
            icon={<ArrowDownIcon />}
            onClick={() => onChangeDate(moment(selectedDate).add(1, 'years').toDate())}
          />
        </PickerContainer>
      </PickersContainer>
    </Container>
  );
};
