import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {Meal} from 'src/interfaces/api/generated.interface';

import {networkClient} from 'src/services/client';

export function usePublishMealMutation() {
  return useMutation({
    mutationFn: async (mealId: number): Promise<Meal> => {
      const {data} = await networkClient.put<Meal>(Routes.PublishMeal, {mealId});

      return data;
    },
  });
}
