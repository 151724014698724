import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const CarIcon = ({size = 24, color, strokeWidth = 1.44, ...rest}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 24 24" {...rest}>
      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M15.155 13.556h1.726M6.63 13.556h1.725M3.124 9.537a61.448 61.448 0 0 0 17.254 0"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M3.126 18.311a.96.96 0 0 0 .96.963H5.99c.53 0 .96-.43.96-.96v-.393c0-.53.43-.96.96-.96H15.6c.53 0 .96.43.96.96v.393c0 .53.43.96.96.96h1.915c.53 0 .96-.43.96-.96v-5.018c0-.778-.249-1.536-.709-2.164L18.568 9.63a1.725 1.725 0 0 1-.214-.41l-1.008-2.798a2.151 2.151 0 0 0-1.66-1.39 25.18 25.18 0 0 0-7.843 0c-.75.125-1.4.673-1.651 1.39L5.185 9.22c-.053.14-.125.272-.215.392l-1.138 1.566a3.628 3.628 0 0 0-.69 2.136l-.016 4.997Z"
      />
    </svg>
  );
};
