import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {EntitiesParams, EntitiesResponse} from 'src/interfaces/api/entity.interface';

import {networkClient} from 'src/services/client';

export function useEntitiesQuery(
  {page, search}: EntitiesParams,
  options?: UseQueryOptions<EntitiesResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.Entities, page, search],
    queryFn: async (): Promise<EntitiesResponse | null> => {
      const {data} = await networkClient.get(Routes.Entities, {
        params: {page, search},
      });
      return data;
    },
    keepPreviousData: true,
    ...options,
  });
}
