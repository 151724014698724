import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {Menu} from 'src/interfaces/api/generated.interface';
import {UpdateMenu} from 'src/interfaces/api/menu.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useUpdateMenuMutation() {
  return useMutation({
    mutationFn: async (menuData: UpdateMenu): Promise<Menu> => {
      const {data} = await networkClient.put<Menu>(Routes.Menu, menuData);

      queryClient.invalidateQueries([Routes.Menu, menuData.menuId]);
      queryClient.invalidateQueries([Routes.Menus]);
      return data;
    },
  });
}
