import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const LeavesIcon = ({
  size = 20,
  color,
  strokeWidth = 1.66,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M13.987 14.453c-1.36 1.484-3.78 2.64-6.884.849-3.104-1.791-3.313-4.464-2.707-6.383.624-1.974 2.228-3.552 4.03-4.494 2.76-1.442 5.486-1.885 6.07-1.92.262.523 1.24 3.103 1.37 6.213.086 2.031-.48 4.209-1.879 5.735ZM14.495 2.505l-8.66 14.99"
      />

      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m15.555 11.095-7.19 1.925-1.928-7.186"
      />

      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M15.796 7.755 10.67 9.126 9.295 4.005"
      />
    </svg>
  );
};
