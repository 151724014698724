import React, {useCallback} from 'react';

import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Review} from 'src/interfaces/dish-rates.interface';

import {Separator} from 'src/components/atoms/separator/Separator';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({theme}) => theme.colors.beige};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.sm};
  width: 100%;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

const StyledSeparator = styled(Separator)`
  margin: ${({theme}) => theme.margins.sm} 0;
`;

const Rate = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.primary5};
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const Reason = styled.span`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray12};
`;

const Percentage = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.primary5};
`;

const NumberStudent = styled.span`
  ${({theme}) => theme.textStyles.p3};
  color: ${({theme}) => theme.colors.primary6};
  white-space: pre-line;
  text-align: center;
`;

const NumberStudentContainer = styled.div`
  padding: ${({theme}) => theme.margins.xxxs} ${({theme}) => theme.paddings.xxs};
  background-color: ${({theme}) => theme.colors.primary2};
  border-radius: ${({theme}) => theme.radius.xs};
  display: flex;
  flex-direction: column;
  margin: 0 ${({theme}) => theme.margins.xs};
`;

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface DishRatingCriteriaCardProps extends React.HTMLAttributes<HTMLDivElement> {
  rating: number;
  reviews: Review[];
  title: string;
}

export const DishRatingCriteriaCard: React.FC<DishRatingCriteriaCardProps> = ({
  rating,
  reviews,
  title,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation('meal-dish-ratings-page');

  // Callbacks
  const renderReview = useCallback(
    (review: Review) => {
      return (
        <ReviewContainer key={review.reason}>
          <Reason>{review.reason}</Reason>

          <NumberStudentContainer>
            <NumberStudent>{t('student-number', {count: review.nbVotes})}</NumberStudent>
          </NumberStudentContainer>

          <Percentage>{((review.nbVotes / review.total) * 100).toFixed(0)}%</Percentage>
        </ReviewContainer>
      );
    },
    [t],
  );

  return (
    <Container {...rest}>
      <Title>{title}</Title>

      <StyledSeparator />

      <Rate>{t('criteria-rate', {rate: rating})}</Rate>

      {reviews.map(renderReview)}
    </Container>
  );
};
