import React from 'react';

import {useLocation, Navigate} from 'react-router-dom';

import {paths} from 'src/navigation/paths';

import useAuth from 'src/hooks/useAuth';
import useCurrentUser from 'src/hooks/useCurrentUser';

type Props = {};

export const RequireAdminGuard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
}) => {
  // Auth
  const {isSignedIn} = useAuth();

  // Current user
  const {isAdmin, isLoading, isRetrieved} = useCurrentUser();

  // Navigation location
  const location = useLocation();

  if (!isSignedIn || (!isAdmin && !isLoading && isRetrieved)) {
    // Redirect them to the /signIn page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={paths.auth.signIn.pattern} state={{from: location}} replace />;
  }

  return <>{children}</>;
};
