import React, {Suspense} from 'react';

import {Provider} from 'react-redux';
import {PersistQueryClientProvider} from '@tanstack/react-query-persist-client';
import {ThemeProvider, StyleSheetManager} from 'styled-components';
import {BrowserRouter} from 'react-router-dom';
import {Runtime, StyledTarget} from 'styled-components/dist/types';
import isPropValid from '@emotion/is-prop-valid';

import useCachedResources from 'src/hooks/useCachedResources';
import Navigation from 'src/navigation';
import {persister, queryClient} from 'src/services/client';
import CurrentUserProvider from 'src/providers/CurrentUser/Provider';
import SnackBarProvider from 'src/providers/SnackBar/Provider';
import AntConfigProvider from 'src/providers/AntConfig/Provider';
import store from 'src/redux/store';
import {themes} from 'src/constants/theme';
import 'src/index.css';

// This implements the default behavior from styled-components v5
function shouldForwardProp<R extends Runtime>(propName: string, target: StyledTarget<R>) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}

function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <Provider store={store}>
      <Suspense fallback={<div>Loading... </div>}>
        <PersistQueryClientProvider client={queryClient} persistOptions={{persister}}>
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <ThemeProvider theme={themes.light}>
              <CurrentUserProvider>
                <SnackBarProvider>
                  <BrowserRouter>
                    <AntConfigProvider>
                      <Navigation />
                    </AntConfigProvider>
                  </BrowserRouter>
                </SnackBarProvider>
              </CurrentUserProvider>
            </ThemeProvider>
          </StyleSheetManager>
        </PersistQueryClientProvider>
      </Suspense>
    </Provider>
  );
}

export default App;
