export const settings = {
  input: {
    debounce: 500,
  },
  pagination: {
    defaultPageSize: 20,
  },
  dish: {
    maxFileSize: {
      image: 1,
      document: 1,
    },
  },
  food: {
    maxFileSize: {
      image: 1,
    },
  },
};
