import React, {useCallback, useEffect, useRef, useState} from 'react';

import {Divider, Form, List, Button as AntButton} from 'antd';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';
import {useDebouncedCallback} from 'use-debounce';

import {CreateDishIngredients} from 'src/interfaces/api/dish.interface';
import {Dish, Food} from 'src/interfaces/api/generated.interface';

import {FoodCard} from 'src/components/molecules/card/FoodCard';
import {Search} from 'src/components/atoms/input/Search';
import {Button} from 'src/components/atoms/button/Button';
import {TrashIcon} from 'src/components/atoms/icon/TrashIcon';

import {useFoodsQuery} from 'src/hooks/queries/useFoodsQuery';
import {settings} from 'src/constants/settings';

interface DishIngredientsFormProps extends React.ComponentProps<typeof Container> {
  dish?: Dish | null;
  isSubmitting?: boolean;
  onFinish: (values: CreateDishIngredients) => void;
  onCancel: () => void;
}

const Container = styled.div``;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const StyledFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const StyledSearch = styled(Search)`
  margin-bottom: ${({theme}) => theme.margins.md};
`;

export const DishIngredientsForm: React.FC<DishIngredientsFormProps> = ({
  dish,
  onFinish,
  onCancel,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation(['create-dish-page', 'common']);

  // Ref
  const searchRef = useRef<HTMLInputElement>(null);

  // Theme
  const theme = useTheme();

  // State
  const [searchFood, setSearchFood] = useState('');
  const [selectedFoods, setSelectedFoods] = useState<Food[]>([]);

  useEffect(() => {
    if (dish?.foods && !selectedFoods.length) {
      setSelectedFoods(dish.foods);
    }
  }, [selectedFoods, dish?.foods]);

  // Queries
  const {data: foodData, isLoading} = useFoodsQuery({
    page: 1,
    search: searchFood,
    limit: 10,
  });

  // Callbacks
  const handleSearchFood = useDebouncedCallback((value: string) => {
    setSearchFood(value);
  }, settings.input.debounce);

  const handleSelectFood = useCallback(
    (foodId: number) => {
      const food = foodData?.foods.find(item => item.id === foodId);
      console.log(food);

      if (food && !selectedFoods.find(selectedFood => selectedFood.id === foodId)) {
        setSelectedFoods([...selectedFoods, food]);

        if (searchRef.current) {
          searchRef.current.value = '';
          setSearchFood('');
        }
      }
    },
    [selectedFoods, foodData?.foods],
  );

  const handleRemoveFood = useCallback(
    (foodId: number) => {
      const food = selectedFoods?.find(item => item.id === foodId);

      if (food) {
        setSelectedFoods(
          selectedFoods.filter(selectedFood => selectedFood.id !== foodId),
        );
      }
    },
    [selectedFoods],
  );

  const handleFinish = useCallback(() => {
    onFinish({foodIds: selectedFoods.map(food => food.id)});
  }, [onFinish, selectedFoods]);

  return (
    <Container {...rest}>
      <StyledSearch
        ref={searchRef}
        placeholder={t('create-dish-page:placeholders.foods')}
        onSearch={handleSearchFood}
      />

      {searchFood.length > 0 || selectedFoods.length === 0 ? (
        <List
          grid={{gutter: parseInt(theme.paddings.xs, 10), column: 2}}
          loading={isLoading}
          dataSource={foodData?.foods ?? []}
          renderItem={item => (
            <FoodCard
              image={item?.image?.url}
              name={item.name}
              description={item.description}
              id={item.id}
              onClick={handleSelectFood}
            />
          )}
        />
      ) : (
        <List
          grid={{gutter: parseInt(theme.paddings.xs, 10), column: 2}}
          loading={isLoading}
          dataSource={selectedFoods ?? []}
          renderItem={item => (
            <FoodCard
              image={item?.image?.url}
              name={item.name}
              description={item.description}
              id={item.id}
              rightComponent={
                <AntButton
                  type="dashed"
                  shape="circle"
                  onClick={() => handleRemoveFood(item.id)}>
                  <TrashIcon />
                </AntButton>
              }
            />
          )}
        />
      )}

      <Divider />

      <FooterContainer key="back">
        <BackButton block onClick={onCancel}>
          {t('common:buttons.back')}
        </BackButton>

        <StyledFormItem>
          <Button
            type="primary"
            htmlType="submit"
            block
            onClick={handleFinish}
            disabled={selectedFoods.length === 0}>
            {t('common:buttons.next')}
          </Button>
        </StyledFormItem>
      </FooterContainer>
    </Container>
  );
};
