import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const ArrowRightIcon = ({height = 15, width = 14, color, ...rest}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width} height={height} fill="none" {...rest}>
      <path
        fill={color ?? theme.colors.gray12}
        fillOpacity={0.85}
        fillRule="evenodd"
        d="M5.303 10.364a.75.75 0 0 1 0-1.06L7.106 7.5 5.303 5.697a.75.75 0 0 1 1.06-1.06L8.698 6.97a.75.75 0 0 1 0 1.06l-2.333 2.334a.75.75 0 0 1-1.061 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
