import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {EntityEatingDaysResponse} from 'src/interfaces/api/entity.interface';

import {networkClient} from 'src/services/client';

export function useEntityEatingDaysQuery(
  options?: UseQueryOptions<EntityEatingDaysResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.EntityEatingDays],
    queryFn: async (): Promise<EntityEatingDaysResponse | null> => {
      const {data} = await networkClient.get(Routes.EntityEatingDays);
      return data;
    },
    ...options,
  });
}
