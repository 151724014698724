import React, {useCallback, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDebouncedCallback} from 'use-debounce';
import {List, Row} from 'antd';

import {EntityStatus} from 'src/interfaces/entity.interface';

import {Search} from 'src/components/atoms/input/Search';
import {EntityCard} from 'src/components/molecules/card/EntityCard';
import {Button} from 'src/components/atoms/button/Button';
import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {paths} from 'src/navigation/paths';

import {useEntitiesQuery} from 'src/hooks/queries/useEntitiesQuery';
import {settings} from 'src/constants/settings';

const StyledSearch = styled(Search)`
  margin-bottom: ${({theme}) => theme.margins.md};
  width: 100%;
  margin-right: ${({theme}) => theme.margins.sm};
`;

const StyledEntityCard = styled(EntityCard)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

export default function EntitiesPage() {
  // Translation
  const {t} = useTranslation('entities-page');

  // Navigate
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Location
  const location = useLocation();

  // State
  const [search, setSearch] = useState('');

  // Query
  const {data, isLoading} = useEntitiesQuery({search});

  // Callbacks
  const handleSearch = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, settings.input.debounce);

  const handleCreateEntity = useCallback(() => {
    navigate(paths.admin.entities.create.pattern, {
      state: {previousLocation: location},
    });
  }, [navigate, location]);

  const handleEditEntity = useCallback(
    (entityId: number) => {
      navigate(paths.admin.entities.edit({id: entityId}), {
        state: {previousLocation: location},
      });
    },
    [navigate, location],
  );

  return (
    <>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <Row wrap={false}>
        <StyledSearch placeholder={t('placeholders.search')} onSearch={handleSearch} />

        <Button
          type="primary"
          size="medium"
          onClick={handleCreateEntity}
          icon={<PlusIcon color={theme.colors.white} size={24} />}>
          {t('buttons.add')}
        </Button>
      </Row>

      <List
        loading={isLoading}
        dataSource={data ?? []}
        renderItem={entity => (
          <StyledEntityCard
            id={entity.id}
            name={entity.name}
            code={entity.invitationCode}
            nbChefs={entity.nbChefs}
            address={entity.address}
            status={entity.nbChefs > 0 ? EntityStatus.Registered : EntityStatus.Invited}
            onClick={handleEditEntity}
          />
        )}
      />
    </>
  );
}
