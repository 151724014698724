export const fallback = 'fr';

export const supportedLocales = {
  en: {
    iso6391: 'en',
    iso31661: 'GB',
    name: 'English',

    // en is default locale in Moment
    libraries: {
      momentLoader: () => Promise.resolve(),
      isoCountriesLoader: () => import('i18n-iso-countries/langs/en.json'),
    },
  },
  fr: {
    iso6391: 'fr',
    iso31661: 'FR',
    name: 'Français',

    libraries: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      momentLoader: () => import('moment/locale/fr'),
      isoCountriesLoader: () => import('i18n-iso-countries/langs/fr.json'),
    },
  },
} as const;

export const supportedLocalesArray = Object.values(supportedLocales);

export const supportedLocaleKeys: Array<string> = Object.keys(supportedLocales);

export type Locale = keyof typeof supportedLocales;

export const defaultNamespace = 'common';
