import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const SideDishIcon = ({
  size = 20,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M16.662 10H3.338a.839.839 0 0 0-.833.95 6.669 6.669 0 0 0 3.326 4.854v.865c0 .46.374.834.834.834h6.67c.46 0 .834-.373.834-.834v-.865a6.669 6.669 0 0 0 3.326-4.855.84.84 0 0 0-.833-.949Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M15.57 10a4.598 4.598 0 0 0 1.1-3.001v-.334a.834.834 0 0 0-.834-.833h-.334A4.672 4.672 0 0 0 10.86 10"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M10.859 10H5.428c-.443 0-.867-.176-1.18-.488l-.345-.346a1.953 1.953 0 0 1 2.761-2.763V6.25h.001a3.335 3.335 0 0 1 6.67-.001v.116"
      />
    </svg>
  );
};
