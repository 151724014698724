import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const StrengthInfoIcon = ({
  width = 33,
  height = 24,
  strokeWidth,
  color,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width} height={height} fill="none" {...rest}>
      <circle cx={12} cy={12} r={12} fill={theme.colors.rose3} />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m24.591 14.384.267-.4a4.482 4.482 0 0 1 5.146-1.765"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m19.064 12.793 1.2-5.986 1.311.655a1.082 1.082 0 0 0 1.382-.364L24.1 5.38c.286-.429.23-1-.135-1.365l-1.44-1.44a1.827 1.827 0 0 0-2.461-.113 23.86 23.86 0 0 0-7.362 10.784l-.6 1.787c-.3.902.026 1.894.8 2.446l1.532 1.094a10.66 10.66 0 0 0 10.156 1.223l1.083 2.165"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M24.591 13.3a5.856 5.856 0 0 0-6.494 0"
      />
    </svg>
  );
};
