import {createContext} from 'react';

import {CurrentUserContextValue} from 'src/interfaces/current-user.interface';

export const defaultContext: CurrentUserContextValue = {
  user: null,
  isLoading: null,
};


const CurrentUserContext = createContext<CurrentUserContextValue>(defaultContext);

CurrentUserContext.displayName = 'CurrentUserContextValue';

export default CurrentUserContext;
