import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const HelicopterIcon = ({
  size = 24,
  color,
  strokeWidth = 1.44,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 24 24" {...rest}>
      <path
        stroke={color ?? theme.colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M12.848 16.623v2.639m4.465-2.639v2.639M5.865 11.475s2.718 1.165 3.23 1.869c.64.879 1.039 3.358 3.997 3.278 2.367-.064 3.784-.027 4.198 0 5.151 0 .774-7.353-3.395-7.353h-1.143a2.88 2.88 0 0 0-1.639.512l-1.277.884H6.23M10.115 19.262h8.226c.96 0 1.804-.642 2.06-1.569M7.312 6.586h11.434M13.03 5.477v1.109"
      />

      <path
        stroke={color ?? theme.colors.white}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M4.678 12.034a1.558 1.558 0 1 0 0-3.117 1.558 1.558 0 0 0 0 3.117ZM13.885 9.397v2.978c0 1.06.86 1.92 1.92 1.92h3.748"
      />
    </svg>
  );
};
