import React from 'react';

import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';

import {TimeCircleIcon} from 'src/components/atoms/icon/TimeCircleIcon';

import {ReactComponent as Illustration} from 'src/assets/illustrations/illustration-2.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({theme}) => theme.heights.timeRemaining.default};
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.lg};
  overflow: hidden;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.h5};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.ssm};
  z-index: 1;
`;

const StyledIllustration = styled(Illustration)`
  width: ${({theme}) => theme.widths.illustrationTimeRemaining.default};
  height: ${({theme}) => theme.heights.illustrationTimeRemaining.default};
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  max-width: 80%;
`;

const IconContainer = styled.div`
  width: fit-content;
`;

const TimeRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  z-index: 1;
`;

const TimeContainer = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.radius.lg};
  height: ${({theme}) => theme.heights.timeContainer.default};
  width: ${({theme}) => theme.widths.timeContainer.default};
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TimeValue = styled.span`
  ${({theme}) => theme.textStyles.h3};
  color: ${({theme}) => theme.colors.gray12};
`;

const TimeUnit = styled.span`
  ${({theme}) => theme.textStyles.p3};
  color: ${({theme}) => theme.colors.gray7};
`;

const UnitSeparator = styled.span`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
  margin: 0 ${({theme}) => theme.margins.xxs};
`;

interface MealTimeRemainingCardProps extends React.HTMLAttributes<HTMLDivElement> {
  date: Date;
}

export const MealTimeRemainingCard: React.FC<MealTimeRemainingCardProps> = ({
  date,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation(['home-page', 'common']);

  // Constants
  const hoursRemaining = moment(date).isSameOrAfter(moment())
    ? moment(date).diff(moment(), 'hours')
    : 0;
  const minutesRemaining = moment(date).isSameOrAfter(moment())
    ? moment(date).subtract(hoursRemaining, 'hours').diff(moment(), 'minutes')
    : 0;
  const secondesRemaining = moment(date).isSameOrAfter(moment())
    ? moment(date)
        .subtract(hoursRemaining, 'hours')
        .subtract(minutesRemaining, 'minutes')
        .diff(moment(), 'seconds')
    : 0;

  return (
    <Container {...rest}>
      <StyledIllustration />

      <TitleContainer>
        <IconContainer>
          <TimeCircleIcon />
        </IconContainer>

        <Title>{t('home-page:time-remaining')}</Title>
      </TitleContainer>

      <TimeRowContainer>
        <TimeContainer>
          <TimeUnit>{t('common:time.hour', {count: hoursRemaining})}</TimeUnit>

          <TimeValue>{hoursRemaining}</TimeValue>
        </TimeContainer>

        <UnitSeparator>:</UnitSeparator>

        <TimeContainer>
          <TimeUnit>{t('common:time.minute', {count: minutesRemaining})}</TimeUnit>

          <TimeValue>{minutesRemaining}</TimeValue>
        </TimeContainer>

        <UnitSeparator>:</UnitSeparator>

        <TimeContainer>
          <TimeUnit>{t('common:time.second', {count: secondesRemaining})}</TimeUnit>

          <TimeValue>{secondesRemaining}</TimeValue>
        </TimeContainer>
      </TimeRowContainer>
    </Container>
  );
};
