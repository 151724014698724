import {WasteValue} from 'src/interfaces/api/generated.interface';

export enum WeatherValue {
  VERY_LOW = 'VERY_LOW',
  LOW = 'LOW',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
}

export enum ReportFoodType {
  WithScale = 'WithScale',
  WithoutScale = 'WithoutScale',
}

export interface ReportFoodWithoutScaleForm {
  wasteValue: WasteValue;
}

export interface ReportFoodWithScaleForm {
  totalWaste: number;
  totalParticipants: number;
}
