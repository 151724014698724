import React, {useCallback, useState} from 'react';

import {Button, Image, List, Popover, Row} from 'antd';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Allergy, NutritionalScoreValue} from 'src/interfaces/api/generated.interface';
import {NutritionalScoreType} from 'src/interfaces/api/dish.interface';

import {StackedAvatars} from 'src/components/molecules/media/StackedAvatar';
import {NutritionalTag} from 'src/components/atoms/tag/NutritionalTag';
import {AllergenTag} from 'src/components/atoms/tag/AllergenTag';
import {MoreIcon} from 'src/components/atoms/icon/MoreIcon';
import {TrashIcon} from 'src/components/atoms/icon/TrashIcon';
import {EditIcon} from 'src/components/atoms/icon/EditIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({theme}) => theme.paddings.lg};
  border-color: ${({theme}) => theme.colors.gray4} !important;
  border-width: ${({theme}) => theme.borders.xs} !important;
  border-radius: ${({theme}) => theme.radius.lg} !important;
  border-style: solid !important;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: ${({theme}) => theme.colors.primary5} !important;
  }
`;

const StyledImage = styled(Image)`
  border-radius: ${({theme}) => theme.radius.sm};
  aspect-ratio: ${({theme}) => theme.aspectRatios.dishImageCard};
  object-fit: cover;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h5};
  color: ${({theme}) => theme.colors.gray12};
  margin-top: ${({theme}) => theme.margins.sm};
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const StyledNutritionalTag = styled(NutritionalTag)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const StyledRow = styled(Row)`
  margin-top: ${({theme}) => theme.margins.xs};
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const AllergensContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: ${({theme}) => theme.paddings.sm};
  margin-left: ${({theme}) => theme.paddings.sm};
`;

const StyledAllergenTag = styled(AllergenTag)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const ImageContainer = styled.div`
  position: relative;
`;

const MoreButton = styled(Button)`
  position: absolute;
  top: ${({theme}) => theme.margins.sm};
  right: ${({theme}) => theme.margins.sm};
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PopoverButton = styled(Button)`
  align-items: center;
  display: flex;
  margin-bottom: ${({theme}) => theme.margins.xxs};
`;

interface DishCardProps {
  id: number;
  name: string;
  image?: string;
  calories: NutritionalScoreValue;
  benefits: NutritionalScoreValue;
  carbonFootprint: NutritionalScoreValue;
  transformation: NutritionalScoreValue;
  allergens: Allergy[];
  foodImages: (string | undefined)[];
  onClick?: (dishId: number) => void;
  onDelete?: (dishId: number) => void;
  onEdit?: (dishId: number) => void;
}

export const DishCard: React.FC<DishCardProps> = ({
  id,
  image,
  name,
  calories,
  benefits,
  carbonFootprint,
  transformation,
  allergens,
  foodImages,
  onClick,
  onDelete,
  onEdit,
}) => {
  // Translation
  const {t} = useTranslation();

  // Theme
  const theme = useTheme();

  // State
  const [open, setOpen] = useState(false);

  // Callbacks
  const handleItemClick = useCallback(() => {
    onClick?.(id);
  }, [id, onClick]);

  const handleEditClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      setOpen(false);
      onEdit?.(id);
    },
    [id, onEdit],
  );

  const handleDeleteClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();
      setOpen(false);
      onDelete?.(id);
    },
    [id, onDelete],
  );

  const renderMoreContent = useCallback(() => {
    return (
      <PopoverContainer>
        <PopoverButton icon={<EditIcon />} type="text" onClick={handleEditClick}>
          {t('buttons.edit')}
        </PopoverButton>

        {/* <PopoverButton icon={<CopyIcon />} type="text">
          {t('buttons.duplicate')}
        </PopoverButton> */}

        <PopoverButton
          icon={<TrashIcon size={20} strokeWidth={2} color={theme.colors.gray8} />}
          type="text"
          onClick={handleDeleteClick}>
          {t('buttons.delete')}
        </PopoverButton>
      </PopoverContainer>
    );
  }, [t, theme.colors.gray8, handleEditClick, handleDeleteClick]);

  return (
    <List.Item onClick={handleItemClick}>
      <Container>
        <ImageContainer>
          <StyledImage src={image} preview={false} width="100%" />

          <AllergensContainer>
            {allergens.map(item => (
              <StyledAllergenTag key={item} type={item} />
            ))}
          </AllergensContainer>

          <Popover
            open={open}
            content={renderMoreContent}
            onOpenChange={setOpen}
            trigger="click">
            <MoreButton
              shape="circle"
              icon={<MoreIcon />}
              onClick={e => e.stopPropagation()}
            />
          </Popover>
        </ImageContainer>

        <Title>{name}</Title>

        <Row wrap={false}>
          <StyledNutritionalTag type={NutritionalScoreType.Calories} value={calories} />

          <NutritionalTag type={NutritionalScoreType.Benefits} value={benefits} />
        </Row>

        <StyledRow wrap={false}>
          <StyledNutritionalTag
            type={NutritionalScoreType.CarbonFootprint}
            value={carbonFootprint}
          />

          <NutritionalTag
            type={NutritionalScoreType.Transformation}
            value={transformation}
          />
        </StyledRow>

        <StackedAvatars sources={foodImages} max={8} />
      </Container>
    </List.Item>
  );
};
