import React from 'react';

import {DishType} from 'src/interfaces/api/generated.interface';

import {AppleIcon} from 'src/components/atoms/icon/AppleIcon';
import {MainDishIcon} from 'src/components/atoms/icon/MainDishIcon';
import {SideDishIcon} from 'src/components/atoms/icon/SideDishIcon';
import {MilkIcon} from 'src/components/atoms/icon/MilkIcon';
import {DessertIcon} from 'src/components/atoms/icon/DessertIcon';

export const menuFiveSections = [
  {
    name: 'common:dish-type.starter_plural',
    icon: <AppleIcon />,
    key: DishType.STARTER,
  },
  {
    name: 'common:dish-type.protein-dish_plural',
    icon: <MainDishIcon />,
    key: DishType.PROTEIN_DISH,
  },
  {
    name: 'common:dish-type.side-dish_plural',
    icon: <SideDishIcon />,
    key: DishType.SIDE_DISH,
  },
  {
    name: 'common:dish-type.dairy-product_plural',
    icon: <MilkIcon />,
    key: DishType.DAIRY_PRODUCT,
  },
  {
    name: 'common:dish-type.dessert_plural',
    icon: <DessertIcon />,
    key: DishType.DESSERT,
  },
];

export const menuFourSections = [
  {
    name: 'common:dish-type.starter_plural',
    icon: <AppleIcon />,
    key: DishType.STARTER,
  },
  {
    name: 'common:dish-type.protein-dish_plural',
    icon: <MainDishIcon />,
    key: DishType.PROTEIN_DISH,
  },
  {
    name: 'common:dish-type.side-dish_plural',
    icon: <SideDishIcon />,
    key: DishType.SIDE_DISH,
  },
  {
    name: 'common:dish-type.dairy-procut-or-dessert_plural',
    icon: <DessertIcon />,
    key: DishType.DESSERT,
  },
];
