import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {Weather} from 'src/interfaces/api/generated.interface';
import {ReportWeatherParams} from 'src/interfaces/api/weather.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useReportWeatherMutation() {
  return useMutation({
    mutationFn: async (params: ReportWeatherParams): Promise<Weather> => {
      const {data} = await networkClient.post<Weather>(Routes.MealWeather, params);

      queryClient.invalidateQueries([Routes.Meal, params.mealId]);

      return data;
    },
  });
}
