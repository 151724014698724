import React from 'react';

import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {SquareCheckIcon} from 'src/components/atoms/icon/SquareCheckIcon';
import {Separator} from 'src/components/atoms/separator/Separator';
import {InfoTag} from 'src/components/atoms/tag/InfoTag';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({theme}) => theme.heights.pastMealStatsCard.default};
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.lg};
  overflow: scroll;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
`;

const Percentage = styled.span`
  ${({theme}) => theme.textStyles.h3};
  color: ${({theme}) => theme.colors.primary5};
`;

const PercentageDetail = styled.span`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const StyledSeparator = styled(Separator)`
  margin: ${({theme}) => theme.margins.sm} 0;
`;

const StyledSquareCheckIcon = styled(SquareCheckIcon)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const HeaderRow = styled(Row)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const StatNameRow = styled(Row)`
  margin-bottom: ${({theme}) => theme.paddings.xxs};
`;

const StatName = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OtherStatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({theme}) => theme.margins.sm};
  margin-bottom: ${({theme}) => theme.margins.sm};
  height: ${({theme}) => theme.heights.statContainer.default};
`;

const InfoTagRow = styled(Row)`
  margin-bottom: ${({theme}) => theme.paddings.xxs};
`;

interface PastMealStatsCardProps extends React.HTMLAttributes<HTMLDivElement> {
  total: number;
  nbParticipants: number;
  nbAbsents: number;
  nbVotes: number;
  nbChangedChoice: number;
  nbChangedChoiceNotAvailable: number;
  nbConfirmedChoices: number;
}

export const PastMealStatsCard: React.FC<PastMealStatsCardProps> = ({
  total,
  nbAbsents,
  nbParticipants,
  nbVotes,
  nbChangedChoice,
  nbChangedChoiceNotAvailable,
  nbConfirmedChoices,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation('home-page');

  return (
    <Container {...rest}>
      <HeaderRow>
        <StyledSquareCheckIcon />

        <Title>{t('participation-rate')}</Title>
      </HeaderRow>

      <StyledRow>
        <Percentage>{total ? Math.round((nbParticipants / total) * 100) : 0}%</Percentage>

        <PercentageDetail>{`${nbParticipants} / ${total} ${t(
          'students',
        )}`}</PercentageDetail>
      </StyledRow>

      <StyledSeparator />

      <OtherStatsContainer>
        <Col span={8}>
          <StatContainer>
            <StatNameRow>
              <StatName>{t('menu-confirmed')}</StatName>
            </StatNameRow>

            <StyledRow>
              <Percentage>
                {total ? Math.round((nbConfirmedChoices / total) * 100) : 0}%
              </Percentage>

              <PercentageDetail>{`${nbConfirmedChoices} / ${total} ${t(
                'students',
              )}`}</PercentageDetail>
            </StyledRow>
          </StatContainer>

          <StatContainer>
            <StatNameRow>
              <StatName>{t('response-rate')}</StatName>
            </StatNameRow>

            <StyledRow>
              <Percentage>
                {total ? Math.round((nbVotes / 5 / total) * 100) : 0}%
              </Percentage>

              <PercentageDetail>{`${nbVotes / 5} / ${total} ${t(
                'students',
              )}`}</PercentageDetail>
            </StyledRow>
          </StatContainer>
        </Col>

        <Col span={8}>
          <StatContainer>
            <StatNameRow>
              <StatName>{t('menu-modified')}</StatName>
            </StatNameRow>

            <StyledRow>
              <Percentage>
                {total ? Math.round((nbChangedChoice / total) * 100) : 0}%
              </Percentage>

              <PercentageDetail>{`${nbChangedChoice} / ${total} ${t(
                'students',
              )}`}</PercentageDetail>
            </StyledRow>
          </StatContainer>

          <StatContainer>
            <StatNameRow>
              <StatName>{t('absence-and-not-responded')}</StatName>
            </StatNameRow>

            <StyledRow>
              <Percentage>
                {total ? Math.round((nbAbsents / total) * 100) : 0}%
              </Percentage>

              <PercentageDetail>{`${nbAbsents} / ${total} ${t(
                'students',
              )}`}</PercentageDetail>
            </StyledRow>
          </StatContainer>
        </Col>

        <Col span={8}>
          <StatContainer>
            <StatNameRow>
              <StatName>{t('menu-modified')}</StatName>
            </StatNameRow>

            <InfoTagRow>
              <InfoTag
                value={t('dish-not-available')}
                bgColor="white"
                textColor="gray12"
                borderless={false}
              />
            </InfoTagRow>

            <StyledRow>
              <Percentage>
                {total ? Math.round((nbChangedChoiceNotAvailable / total) * 100) : 0}%
              </Percentage>

              <PercentageDetail>{`${nbChangedChoiceNotAvailable} / ${total} ${t(
                'students',
              )}`}</PercentageDetail>
            </StyledRow>
          </StatContainer>
        </Col>
      </OtherStatsContainer>
    </Container>
  );
};
