import React, {useCallback} from 'react';

import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {Form, Row, Switch} from 'antd';

import {EatingDay} from 'src/interfaces/api/generated.interface';

import {Button} from 'src/components/atoms/button/Button';

import {useEntityEatingDaysQuery} from 'src/hooks/queries/useEntityEatingDaysQuery';
import {useUpdateEntityMutation} from 'src/hooks/mutations/useUpdateEntityMutation';
import useSnackBar from 'src/hooks/useSnackBar';
import useCurrentUser from 'src/hooks/useCurrentUser';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({theme}) => theme.margins.md} ${({theme}) => theme.margins.xs};
`;

const SectionTitle = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.md};
`;

const EatingDayText = styled.span`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

export const MyCanteenTab: React.FC = () => {
  // Translation
  const {t} = useTranslation(['my-account-page', 'common']);

  // Current user
  const {user} = useCurrentUser();

  // Snack bar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Mutation
  const {mutateAsync: updateEntity} = useUpdateEntityMutation();

  // Queries
  const {data: entityEatingDays} = useEntityEatingDaysQuery();

  const handleUpdateEatingDays = useCallback(
    async (values: {[value in EatingDay]: boolean}) => {
      try {
        if (user?.entityId) {
          updateEntity({
            eatingDays: Object.keys(values).filter(
              key => values[key as EatingDay],
            ) as EatingDay[],
          });
        }

        showSuccessSnackBar(t('my-account-page:my-canteen.sucess.eating-days-updated'));
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [showErrorSnackBar, showSuccessSnackBar, t, updateEntity, user?.entityId],
  );

  const renderEatingDay = useCallback(
    (eatingDay: EatingDay) => {
      return (
        <Row>
          <Form.Item
            name={eatingDay}
            initialValue={entityEatingDays?.eatingDays.includes(eatingDay)}>
            <Switch defaultChecked={entityEatingDays?.eatingDays.includes(eatingDay)} />
          </Form.Item>

          <EatingDayText>
            {/* @ts-ignore */}
            {t(`my-account-page:my-canteen.days.${eatingDay.toLowerCase()}`)}
          </EatingDayText>
        </Row>
      );
    },
    [t, entityEatingDays?.eatingDays],
  );

  return (
    <Container>
      <SectionTitle>{t('my-account-page:my-canteen.opening-days')}</SectionTitle>

      <Form layout="vertical" onFinish={handleUpdateEatingDays}>
        {Object.values(EatingDay).map(renderEatingDay)}

        <Row justify="end">
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {t('common:buttons.save')}
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Container>
  );
};
