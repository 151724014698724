import React, {useCallback, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {List, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDebouncedCallback} from 'use-debounce';
import styled, {useTheme} from 'styled-components';

import {Search} from 'src/components/atoms/input/Search';
import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {Button} from 'src/components/atoms/button/Button';
import {DishCard} from 'src/components/molecules/card/DishCard';
import {paths} from 'src/navigation/paths';

import {useDishesQuery} from 'src/hooks/queries/useDishesQuery';
import {useDeleteDishMutation} from 'src/hooks/mutations/useDeleteDishMutation';
import useCurrentUser from 'src/hooks/useCurrentUser';
import useSnackBar from 'src/hooks/useSnackBar';
import {settings} from 'src/constants/settings';

const StyledSearch = styled(Search)`
  margin-bottom: ${({theme}) => theme.margins.md};
  margin-right: ${({theme}) => theme.margins.sm};
`;

const InputContainer = styled.div`
  display: block;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

export default function DishesPage() {
  // Translation
  const {t} = useTranslation(['common', 'dishes-page']);

  // Navigate
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Location
  const location = useLocation();

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Current user
  const {isAdmin} = useCurrentUser();

  // State
  const [search, setSearch] = useState('');
  const [publicDishes, setPublicDishes] = useState(true);

  // Query
  const {data, isLoading} = useDishesQuery({page: 1, search, publicDishes});

  // Mutation
  const {mutateAsync: deleteDish} = useDeleteDishMutation();

  // Callbacks
  const handleAddDish = useCallback(() => {
    navigate(
      isAdmin ? paths.admin.dishes.create.pattern : paths.chef.dishes.create.pattern,
      {state: {previousLocation: location}},
    );
  }, [navigate, location, isAdmin]);

  const handleEditDish = useCallback(
    (dishId: number) => {
      if (dishId) {
        navigate(
          isAdmin
            ? paths.admin.dishes.edit({id: dishId})
            : paths.chef.dishes.edit({id: dishId}),
          {
            state: {previousLocation: location},
          },
        );
      }
    },
    [navigate, location, isAdmin],
  );

  const handleSearch = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, settings.input.debounce);

  const handleOpenDish = useCallback(
    (dishId: number) => {
      navigate(
        isAdmin
          ? paths.admin.dishes.details({id: dishId})
          : paths.chef.dishes.details({id: dishId}),
        {
          state: {previousLocation: location},
        },
      );
    },
    [navigate, location, isAdmin],
  );

  const handleDeleteDish = useCallback(
    async (dishId: number) => {
      try {
        if (dishId) {
          await deleteDish({dishId: Number(dishId)});
          showSuccessSnackBar(t('dishes-page:success.deleted'));
        }
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [deleteDish, showErrorSnackBar, showSuccessSnackBar, t],
  );

  return (
    <>
      <Helmet>
        <title>{t('dishes-page:helmet')}</title>
      </Helmet>

      <Row wrap={false}>
        <InputContainer>
          <StyledSearch
            placeholder={t('dishes-page:placeholders.search')}
            onSearch={handleSearch}
          />
        </InputContainer>

        <StyledButton type="dashed" size="medium">
          {t('dishes-page:buttons.filter')}
        </StyledButton>

        <Button
          type="primary"
          size="medium"
          onClick={handleAddDish}
          icon={<PlusIcon color={theme.colors.white} size={24} />}>
          {t('dishes-page:buttons.add')}
        </Button>
      </Row>

      {/* <Switch onChange={setPublicDishes} defaultChecked={publicDishes} />

      <Text strong>{t('dishes-page:filters.public-dishes')}</Text> */}

      <List
        grid={{gutter: parseInt(theme.paddings.xs, 10), column: 3}}
        loading={isLoading}
        dataSource={data?.dishes ?? []}
        renderItem={item => (
          <DishCard
            image={item?.image?.url}
            name={item.name}
            id={item.id}
            calories={item.nutritionalScore.calories}
            benefits={item.nutritionalScore.benefits}
            carbonFootprint={item.nutritionalScore.carbonFootprint}
            transformation={item.nutritionalScore.transformation}
            allergens={item.allergens}
            foodImages={item.foods.map(food => food.image?.url)}
            onClick={handleOpenDish}
            onDelete={handleDeleteDish}
            onEdit={handleEditDish}
          />
        )}
      />
    </>
  );
}
