import React, {useMemo} from 'react';

import {Col, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';

import {EntityStatus, StatusConfig} from 'src/interfaces/entity.interface';

import {CopyCheckmarkIcon} from 'src/components/atoms/icon/CopyCheckmarkIcon';

import useSnackBar from 'src/hooks/useSnackBar';
import {genStatusItems} from 'src/constants/entity';

const Container = styled(Row)`
  padding: ${({theme}) => theme.paddings.lg};
  border-color: ${({theme}) => theme.colors.gray4} !important;
  border-width: ${({theme}) => theme.borders.xs} !important;
  border-radius: ${({theme}) => theme.radius.lg} !important;
  border-style: solid !important;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: ${({theme}) => theme.colors.primary5} !important;
  }
`;

const Name = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
`;

const Text = styled.span`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
`;

const StatusContainer = styled.div<Pick<StatusConfig, 'color'>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({color}) => color};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.xxxs};
  justify-content: center;
`;

const StatusText = styled.span<Pick<StatusConfig, 'textColor'>>`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({textColor}) => textColor};
  margin-right: ${({theme}) => theme.margins.xs};
`;

const StyledCopyCheckmarkIcon = styled(CopyCheckmarkIcon)`
  margin-left: ${({theme}) => theme.margins.xxxs};
`;

const CodeContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

interface EntityCardProps
  extends Omit<React.ComponentProps<typeof Row>, 'onClick' | 'id'> {
  id: number;
  name: string;
  address: string;
  nbChefs: number;
  code: string;
  status: EntityStatus;
  onClick?: (entityId: number) => void;
}

export const EntityCard: React.FC<EntityCardProps> = ({
  id,
  name,
  address,
  nbChefs,
  code,
  status,
  onClick,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation('entities-page');

  // Theme
  const theme = useTheme();

  // Snack bar
  const {showSuccessSnackBar} = useSnackBar();

  // Constants
  const item = useMemo(() => genStatusItems(theme)[status], [theme, status]);

  const handleCopyCode = () => {
    navigator.clipboard.writeText(code);
    showSuccessSnackBar(t('success.invitation-code-copied'));
  };

  return (
    <Container wrap={false} {...rest} onClick={() => onClick?.(id)}>
      <Col span={6}>
        <Name>{name}</Name>
      </Col>

      <Col span={6}>
        <Text>{address}</Text>
      </Col>

      <Col span={4}>
        <Text>{t('chef', {count: nbChefs})}</Text>
      </Col>

      <Col span={4}>
        <CodeContainer onClick={handleCopyCode}>
          <Text>{code}</Text>

          <StyledCopyCheckmarkIcon />
        </CodeContainer>
      </Col>

      <Col span={4}>
        <StatusContainer color={item.color}>
          <StatusText textColor={item.textColor}>{t(`status.${status}`)}</StatusText>
        </StatusContainer>
      </Col>
    </Container>
  );
};
