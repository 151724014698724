import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {DeleteDishDocument} from 'src/interfaces/api/dish.interface';
import {File} from 'src/interfaces/api/generated.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useDeleteDishDocumentMutation() {
  return useMutation({
    mutationFn: async (deleteData: DeleteDishDocument): Promise<File> => {
      const {data} = await networkClient.delete<File>(Routes.DishDocument, {
        data: deleteData,
      });

      queryClient.invalidateQueries([Routes.Dish, deleteData.dishId]);

      return data;
    },
  });
}
