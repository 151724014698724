import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const AppleIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width ?? size} height={height ?? size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M3.653 11.788c.283-4.57 3.645-4.172 4.536-3.932a6.846 6.846 0 0 0 3.622 0c.892-.24 4.253-.638 4.535 3.932.036 4.34-2.759 6.5-3.442 6.575-1.232.3-2.302-.4-2.904-.4-.602 0-1.658.653-2.904.4-.692-.085-3.477-2.236-3.443-6.575Z"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M10 8.084c0-1.402-.97-2.998-2.75-3.343M9.774 6.416s-.413-2.992 3.125-2.978c.297 3.37-3.125 2.978-3.125 2.978ZM7.25 9.874a2.214 2.214 0 0 0-1.221 1.667"
      />
    </svg>
  );
};
