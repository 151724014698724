import {createContext} from 'react';

import {SnackBarContextValue} from 'src/interfaces/snack-bar.interface';

export const defaultContext: SnackBarContextValue = {
  api: null,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const SnackBarContext = createContext<SnackBarContextValue>(defaultContext);

SnackBarContext.displayName = 'SnackBarContextValue';

export default SnackBarContext;
