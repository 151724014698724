import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const DessertIcon = ({
  size = 20,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M2.5 10.637h14.746M17.5 14.167h-15M12.713 5.732a2.606 2.606 0 0 1-5.213 0c0-1.134.517-1.952 1.559-1.952v.001a7.5 7.5 0 0 1 1.043.167c.343-.08.691-.135 1.042-.167v0c1.043 0 1.567.817 1.567 1.95"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M10.104 3.946v-.945c0-.737.598-1.334 1.335-1.334M7.73 4.596a5.288 5.288 0 0 0-5.23 5.29v5.85c0 .974.79 1.764 1.765 1.764h11.47c.975 0 1.765-.79 1.765-1.765v-4.513c0-.57-.275-1.105-.739-1.436l-4.254-3.039"
      />
    </svg>
  );
};
