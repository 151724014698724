import React, {useCallback, useMemo, memo} from 'react';

import styled, {useTheme} from 'styled-components';
import {Avatar} from 'antd';

import {AvatarSize} from 'src/interfaces/theme.interface';

const _defaultSize: AvatarSize = 'xxs';
const _overlapPercentage = 0.25;

interface StackedAvatarsProps extends Omit<React.ComponentProps<typeof Avatar>, 'size'> {
  sources: (string | null | undefined)[];
  max: number;
  size?: AvatarSize;
}

interface AvatarLength {
  nbAvatars: number;
  index: number;
}

const Container = styled.div`
  flex-direction: row;
`;

const StackedAvatar = styled(Avatar)<AvatarLength & {avatarSize: AvatarSize}>`
  margin-left: ${({theme, avatarSize = _defaultSize, index}) =>
    index ? -(parseInt(theme.heights.avatar[avatarSize], 10) * _overlapPercentage) : 0}px;
  background-color: ${({theme}) => theme.colors.primary1};
`;

const keyExtractor = (_item: string | null | undefined, index: number) =>
  `StackedAvatar-${index}`;

export const StackedAvatars: React.FC<StackedAvatarsProps> = memo(
  ({sources: initialSources, max, size = _defaultSize, ...props}) => {
    // Theme
    const theme = useTheme();

    // Memoized
    const sources = useMemo(() => {
      return initialSources.slice(0, max).reverse();
    }, [max, initialSources]);

    // Callbacks
    const renderAvatar = useCallback(
      (source: string | null | undefined, index: number) => {
        const key = keyExtractor(source, index);

        return (
          <StackedAvatar
            key={key}
            src={source}
            avatarSize={size}
            size={parseInt(theme.heights.avatar[size], 10)}
            index={index}
            nbAvatars={sources.length}
            {...props}
          />
        );
      },
      [props, size, sources.length, theme.heights.avatar],
    );

    return <Container>{sources.map(renderAvatar)}</Container>;
  },
);
