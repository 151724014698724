import React from 'react';

import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const RequiredMark = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.red700};
  margin-left: ${({theme}) => theme.margins.xxxs};
`;

const Optional = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray8};
  background-color: ${({theme}) => theme.colors.gray3};
  margin-left: ${({theme}) => theme.margins.xxxs};
  padding-left: ${({theme}) => theme.paddings.xxxs};
  padding-right: ${({theme}) => theme.paddings.xxxs};
`;

interface MarkProps {
  label: React.ReactNode;
  info: {required: boolean};
}

export const Mark: React.FC<MarkProps> = ({label, info: {required}}) => {
  // Translation
  const {t} = useTranslation();

  return (
    <>
      {required && <RequiredMark>*</RequiredMark>}

      {label}

      {!required && <Optional>{t('optional')}</Optional>}
    </>
  );
};
