import React, {useCallback, useMemo} from 'react';

import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';
import {List, Row} from 'antd';

import {
  Dish,
  DishRate,
  Menu,
  MenuType,
  StudentMenuVote,
} from 'src/interfaces/api/generated.interface';
import {PrefixObject} from 'src/interfaces/global.tmp';
import {MenuSection} from 'src/interfaces/menu.interface';
import {DishCardSize} from 'src/interfaces/theme.interface';

import {CalendarIcon} from 'src/components/atoms/icon/CalendarIcon';
import {Separator} from 'src/components/atoms/separator/Separator';
import {SmallDishCard} from 'src/components/molecules/card/SmallDishCard';

import {menuFiveSections, menuFourSections} from 'src/constants/menu';

const Container = styled.div`
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.lg};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.h5};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.ssm};
  z-index: 1;
`;

const Subtitle = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
  margin-right: ${({theme}) => theme.margins.sm};
`;

const SubtitleRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${({theme}) => theme.margins.sm} 0;
`;

const DishesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledSmallDishCard = styled(SmallDishCard)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

interface MealMenuDetailsCardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    PrefixObject<
      Pick<React.ComponentProps<typeof SmallDishCard>, 'rightComponent'>,
      'dishCard'
    > {
  menu: Menu;
  dishCardSize?: DishCardSize;
  dishCardStudentsChoice?: boolean;
  dishCardRating?: boolean;
  studentsVotes?: StudentMenuVote[];
  studentsRates?: DishRate[];
}

export const MealMenuDetailsCard: React.FC<MealMenuDetailsCardProps> = ({
  menu,
  dishCardRightComponent,
  dishCardSize = 'small',
  dishCardRating,
  dishCardStudentsChoice,
  studentsRates,
  studentsVotes,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation(['home-page', 'common']);

  // Constants
  const sections: MenuSection[] = useMemo(
    () => (menu.type === MenuType.FIVE_DISHES ? menuFiveSections : menuFourSections),
    [menu.type],
  );

  // Theme
  const theme = useTheme();

  // Callbacks
  const renderRatingDishCard = useCallback(
    (dish: Dish) => {
      const dishRates = studentsRates?.filter(rate => rate.dishId === dish.id);

      const rating = dishRates?.length
        ? (dishRates
            ?.map(
              rate =>
                (rate.aroma + rate.appearance + rate.temperature + rate.quantity) / 2,
            )
            ?.reduce((a, b) => a + b, 0) ?? 0) / (dishRates?.length ?? 1)
        : undefined;

      return (
        <StyledSmallDishCard
          key={dish.id}
          id={dish.id}
          name={dish.name}
          image={dish.image?.url}
          borderless
          rating={rating}
          rightComponent={dishCardRightComponent}
          size={dishCardSize}
        />
      );
    },
    [studentsRates, dishCardRightComponent, dishCardSize],
  );

  const renderStudentsChoiceDishCard = useCallback(
    (dish: Dish) => {
      const dishVotes = studentsVotes?.filter(vote =>
        vote.dishes.find(voteDish => voteDish.id === dish.id),
      );

      const nbStudentsChoice = dishVotes?.length ?? 0;

      return (
        <StyledSmallDishCard
          key={dish.id}
          id={dish.id}
          name={dish.name}
          image={dish.image?.url}
          borderless
          nbStudentsChoice={nbStudentsChoice}
          rightComponent={dishCardRightComponent}
          size={dishCardSize}
        />
      );
    },
    [studentsVotes, dishCardRightComponent, dishCardSize],
  );

  const renderSection = useCallback(
    (sectionItem: MenuSection) => {
      const dishes = menu.dishes.filter(dish => dish.type === sectionItem.key);

      return (
        <>
          <SubtitleRow wrap={false}>
            {/* @ts-ignore */}
            <Subtitle>{t(sectionItem.name)}</Subtitle>

            <Separator />
          </SubtitleRow>

          <DishesContainer>
            {dishes.map(dish => {
              if (dishCardRating) {
                return renderRatingDishCard(dish);
              }

              if (dishCardStudentsChoice) {
                return renderStudentsChoiceDishCard(dish);
              }

              return (
                <StyledSmallDishCard
                  key={dish.id}
                  id={dish.id}
                  name={dish.name}
                  image={dish.image?.url}
                  borderless
                  rightComponent={dishCardRightComponent}
                  size={dishCardSize}
                />
              );
            })}
          </DishesContainer>
        </>
      );
    },
    [
      menu.dishes,
      dishCardRightComponent,
      dishCardSize,
      dishCardRating,
      dishCardStudentsChoice,
      t,
      renderRatingDishCard,
      renderStudentsChoiceDishCard,
    ],
  );

  return (
    <Container {...rest}>
      <Row>
        <CalendarIcon color={theme.colors.primary5} />

        <Title>{t('home-page:menu-details.title')}</Title>
      </Row>

      <List renderItem={renderSection} dataSource={sections} />
    </Container>
  );
};
