import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {Weather} from 'src/interfaces/api/generated.interface';
import {GetLastWeatherReportsParams} from 'src/interfaces/api/weather.interface';

import {networkClient} from 'src/services/client';

export function useLastWeatherReportsQuery(
  {from, to}: GetLastWeatherReportsParams,
  options?: UseQueryOptions<Weather[] | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.MealsLastWeatherReports, from, to],
    queryFn: async (): Promise<Weather[] | null> => {
      const {data} = await networkClient.get(Routes.MealsLastWeatherReports, {
        params: {from, to},
      });

      return data;
    },
    ...options,
  });
}
