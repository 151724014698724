import React, {useCallback} from 'react';

import {Form, Row} from 'antd';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'antd/es/form/Form';

import {ResetPasswordForm} from 'src/interfaces/user.interface';

import {Button} from 'src/components/atoms/button/Button';
import {Mark} from 'src/components/atoms/mark/Mark';
import {Input} from 'src/components/atoms/input/Input';
import {HelpFooter} from 'src/components/molecules/footer/HelpFooter';
import {paths} from 'src/navigation/paths';

import useSnackBar from 'src/hooks/useSnackBar';
import useSearchParams from 'src/hooks/useSearchParams';
import {useResetPasswordMutation} from 'src/hooks/mutations/useResetPasswordMutation';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${({theme}) => theme.paddings.lg};
`;

const StyledRow = styled(Row)`
  display: flex;
  width: 100%;
`;

const ButtonRow = styled(Row)`
  margin-top: ${({theme}) => theme.margins.md};
`;

const SendButton = styled(Button)`
  width: 100%;
`;

const StyledForm = styled(Form<ResetPasswordForm>)`
  width: 60%;
`;

const BackButton = styled(Button)`
  width: 48%;
`;

const StyledFormItem = styled(Form.Item)`
  width: 48%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h4};
  color: ${({theme}) => theme.colors.gray12};
`;

const Description = styled.text`
  ${({theme}) => theme.textStyles.h7};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.xl};
  margin-top: ${({theme}) => theme.margins.xs};
`;

export default function ResetPasswordPage() {
  // Translation
  const {t} = useTranslation('reset-password-page');

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Navigate
  const navigate = useNavigate();

  // Form
  const [formInstance] = useForm();

  // Extract token from query string
  const [searchParams] = useSearchParams<typeof paths.auth.resetPassword>();
  const token = searchParams.get('token');

  // Mutation
  const {mutateAsync: resetPassword} = useResetPasswordMutation();

  // Callbacks
  const handleFinish = useCallback(
    async (values: ResetPasswordForm) => {
      try {
        if (!token) {
          throw new Error(t('errors.token-not-found'));
        }
        await resetPassword({token, newPassword: values.newPassword});
        showSuccessSnackBar(t('success.changed'));
        navigate(paths.auth.signIn.pattern);
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [t, showErrorSnackBar, showSuccessSnackBar, resetPassword, navigate, token],
  );

  const handleGoBack = useCallback(() => {
    navigate(paths.auth.signIn.pattern);
  }, [navigate]);

  // Callbacks
  const renderMark = useCallback((label: React.ReactNode, info: {required: boolean}) => {
    return <Mark label={label} info={info} />;
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>

      <StyledRow justify="center">
        <StyledForm
          layout="vertical"
          form={formInstance}
          onFinish={handleFinish}
          requiredMark={renderMark}>
          <ContentContainer>
            <Title>{t('title')}</Title>

            <Description>{t('description')}</Description>

            <Form.Item
              label={t('fields.new-password')}
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: t('errors.password-required'),
                },
              ]}>
              <Input isPassword />
            </Form.Item>

            <Form.Item
              label={t('fields.confirm-password')}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: t('errors.password-required'),
                },
                {
                  validator: (_, value) => {
                    const {getFieldValue} = formInstance;
                    if (value && value !== getFieldValue('newPassword')) {
                      return Promise.reject(
                        new Error(t('errors.confirm-password-mismatch')),
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}>
              <Input isPassword />
            </Form.Item>

            <ButtonRow justify="space-between">
              <BackButton type="default" onClick={handleGoBack}>
                {t('buttons.back')}
              </BackButton>

              <StyledFormItem>
                <SendButton type="primary" htmlType="submit">
                  {t('buttons.edit')}
                </SendButton>
              </StyledFormItem>
            </ButtonRow>
          </ContentContainer>

          <HelpFooter />
        </StyledForm>
      </StyledRow>
    </Container>
  );
}
