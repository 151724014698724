import React from 'react';

import {Button as AntButton} from 'antd';
import styled from 'styled-components';

import {ButtonSize} from 'src/interfaces/theme.interface';

interface ButtonProps extends Omit<React.ComponentProps<typeof AntButton>, 'size'> {
  size?: ButtonSize;
}

const StyledButton = styled(AntButton)<
  Pick<ButtonProps, 'icon'> & {buttonSize?: ButtonSize}
>`
  border-radius: ${({theme}) => theme.radius.xxl};
  background-color: ${({theme}) => theme.colors.primary5};
  height: ${({theme, buttonSize = 'default'}) => theme.heights.button[buttonSize]};
  align-items: center;
  display: ${({icon}) => (icon ? 'flex' : 'inline-block')};
`;

const StyledDefaultButton = styled(AntButton)<
  Pick<ButtonProps, 'icon'> & {buttonSize?: ButtonSize}
>`
  border-radius: ${({theme}) => theme.radius.xxl};
  background-color: transparent;
  height: ${({theme, buttonSize = 'default'}) => theme.heights.button[buttonSize]};
  align-items: center;
  display: ${({icon}) => (icon ? 'flex' : 'inline-block')};
`;

const StyledLinkButton = styled(AntButton)<{buttonSize?: ButtonSize}>`
  border-radius: ${({theme}) => theme.radius.xxl};
  height: ${({theme, buttonSize = 'default'}) => theme.heights.button[buttonSize]};
`;

const TextLink = styled.text`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.primary5};
  text-decoration: underline;
  text-decoration-color: ${({theme}) => theme.colors.primary5};
  text-decoration-thickness: 1;
`;

// TODO: Change button text to be Button text style on figma
export const Button: React.FC<ButtonProps> = ({type, size, ...rest}) => {
  switch (type) {
    case 'link':
      return (
        <StyledLinkButton type={type} buttonSize={size} {...rest}>
          <TextLink>{rest.children}</TextLink>
        </StyledLinkButton>
      );
    case 'primary':
      return <StyledButton type={type} buttonSize={size} {...rest} />;

    default:
      return <StyledDefaultButton type={type} buttonSize={size} {...rest} />;
  }
};
