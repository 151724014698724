import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {User} from 'src/interfaces/api/generated.interface';

import {networkClient} from 'src/services/client';

export function useDeleteMyAccount() {
  return useMutation({
    mutationFn: async (): Promise<User> => {
      const {data} = await networkClient.delete<User>(Routes.User);

      return data;
    },
  });
}
