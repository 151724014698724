export const fonts = {
  bold: 'quicksand-bold',
  light: 'quicksand-light',
  medium: 'quicksand-medium',
  regular: 'quicksand-regular',
  semiBold: 'quicksand-semi-bold',
};

export const fontSizes = {
  button: {
    default: '17px',
  },

  text: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    xxl: '24px',
    xxxl: '32px',
    xxxxl: '48px',
  },
};

export const textStyles = {
  // Button Styles
  button: {
    default: {
      'font-family': fonts.bold,
      'line-height': '22px',
      'font-size': fontSizes.button.default,
    },
    small: {
      'font-family': fonts.semiBold,
      'line-height': '16px',
      'font-size': fontSizes.text.xs,
    },
  },

  // Text Styles
  xsText: {
    'font-family': fonts.regular,
    'line-height': '13px',
    'font-size': fontSizes.text.xs,
  },
  sText: {
    'font-family': fonts.regular,
    'line-height': '18px',
    'font-size': fontSizes.text.sm,
  },
  sSemiBoldText: {
    'font-family': fonts.semiBold,
    'line-height': '22px',
    'font-size': fontSizes.text.sm,
  },
  mText: {
    'font-family': fonts.medium,
    'line-height': '22px',
    'font-size': fontSizes.text.md,
  },
  lText: {
    'font-family': fonts.regular,
    'line-height': '29px',
    'font-size': fontSizes.text.md,
  },
  xlText: {
    'font-family': fonts.medium,
    'line-height': '30px',
    'font-size': fontSizes.text.lg,
  },

  mTitle: {
    'font-family': fonts.semiBold,
    'line-height': '22px',
    'font-size': fontSizes.text.md,
  },
  mSemiBoldTitle: {
    'font-family': fonts.semiBold,
    'line-height': '22px',
    'font-size': fontSizes.text.md,
  },
  mBoldTitle: {
    'font-family': fonts.bold,
    'line-height': '22px',
    'font-size': fontSizes.text.md,
  },
  lTitle: {
    'font-family': fonts.bold,
    'line-height': '40px',
    'font-size': fontSizes.text.xl,
  },
  xlTitle: {
    'font-family': fonts.bold,
    'line-height': '41px',
    'font-size': fontSizes.text.xxl,
  },

  // -----------------  NEW TEXT STYLES ----------------- //
  h1: {
    'font-family': fonts.bold,
    'line-height': '57.6px',
    'font-size': fontSizes.text.xxxxl,
  },
  h3: {
    'font-family': fonts.bold,
    'line-height': '38.4px',
    'font-size': fontSizes.text.xxxl,
  },
  h4: {
    'font-family': fonts.bold,
    'font-weight': 700,
    'line-height': '28.8px',
    'font-size': fontSizes.text.xxl,
  },
  h5: {
    'font-family': fonts.bold,
    'line-height': '24px',
    'font-size': fontSizes.text.xl,
  },
  h6: {
    'font-family': fonts.bold,
    'line-height': '25.2px',
    'font-size': fontSizes.text.lg,
  },
  h7: {
    'font-family': fonts.medium,
    'line-height': '25.2px',
    'font-size': fontSizes.text.lg,
  },

  s1: {
    'font-family': fonts.medium,
    'line-height': '24px',
    'font-size': fontSizes.text.xxl,
  },

  p1Bold: {
    'font-family': fonts.bold,
    'line-height': '19.2px',
    'font-size': fontSizes.text.md,
  },
  p1: {
    'font-family': fonts.medium,
    'line-height': '22.4px',
    'font-size': fontSizes.text.md,
  },

  p2Bold: {
    'font-family': fonts.bold,
    'line-height': '19.6px',
    'font-size': fontSizes.text.sm,
  },
  p2: {
    'font-family': fonts.medium,
    'line-height': '19.6px',
    'font-size': fontSizes.text.sm,
  },
  p3: {
    'font-family': fonts.medium,
    'line-height': '12px',
    'font-size': fontSizes.text.xs,
  },
};
