import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {EntityParams, GetEntityResponse} from 'src/interfaces/api/entity.interface';

import {networkClient} from 'src/services/client';

export function useEntityQuery(
  {entityId}: EntityParams,
  options?: UseQueryOptions<GetEntityResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.Entity, entityId],
    queryFn: async (): Promise<GetEntityResponse | null> => {
      const {data} = await networkClient.get(Routes.Entity, {
        params: {entityId},
      });
      return data;
    },
    ...options,
  });
}
