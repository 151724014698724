import React, {useCallback} from 'react';

import {Form, Row, Button as AntButton, List, Popconfirm} from 'antd';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';

import {User} from 'src/interfaces/api/generated.interface';

import {Input} from 'src/components/atoms/input/Input';
import {Button} from 'src/components/atoms/button/Button';
import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {TrashIcon} from 'src/components/atoms/icon/TrashIcon';

const ChefFormContainer = styled.div`
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.lg};
  margin-bottom: ${({theme}) => theme.margins.sm};
  position: relative;
`;

const ChefInfoContainer = styled(ChefFormContainer)`
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const StyledFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
`;

const StyledFormItemMargin = styled(StyledFormItem)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const AddChefButton = styled(Button)`
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

const LastFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
  margin-bottom: 0;
`;

const CloseButton = styled(AntButton)`
  position: absolute;
  right: ${({theme}) => theme.margins.xxs};
  top: ${({theme}) => theme.margins.xxs};
`;

const Name = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
`;

interface ChefsFormProps {
  chefs: User[] | undefined;
  onDeleteChef: (userId: number) => void;
}

export const ChefsForm: React.FC<ChefsFormProps> = ({chefs, onDeleteChef}) => {
  // Translation
  const {t} = useTranslation(['create-entity-page', 'common']);

  // Theme
  const theme = useTheme();

  // Callbacks
  const renderChefs = useCallback(
    (user: User) => {
      return (
        <ChefInfoContainer>
          <Name>{`${user.firstName} ${user.lastName}`}</Name>

          <Popconfirm
            title={t('create-entity-page:confirm.delete.title')}
            description={t('create-entity-page:confirm.delete.text')}
            onConfirm={() => onDeleteChef(user.id)}
            okText={t('common:buttons.yes')}
            cancelText={t('common:buttons.no')}>
            <AntButton
              icon={<TrashIcon size={22} color={theme.colors.green8} />}
              shape="circle"
              type="text"
            />
          </Popconfirm>
        </ChefInfoContainer>
      );
    },
    [onDeleteChef, t, theme],
  );

  return (
    <>
      {chefs && chefs.length > 0 && <List renderItem={renderChefs} dataSource={chefs} />}

      <Form.List name="chefs">
        {(fields, {add, remove}) => (
          <>
            {fields.map((field, index) => (
              <ChefFormContainer key={field.name}>
                <Row wrap={false}>
                  <StyledFormItemMargin
                    label={t('common:labels.lastName')}
                    name={[field.name, 'lastName']}
                    rules={[
                      {
                        required: true,
                        message: t('create-entity-page:errors.last-name-required'),
                      },
                    ]}>
                    <Input placeholder={t('create-entity-page:placeholders.last-name')} />
                  </StyledFormItemMargin>

                  <StyledFormItem
                    label={t('common:labels.firstName')}
                    name={[field.name, 'firstName']}
                    rules={[
                      {
                        required: true,
                        message: t('create-entity-page:errors.first-name-required'),
                      },
                    ]}>
                    <Input
                      placeholder={t('create-entity-page:placeholders.first-name')}
                    />
                  </StyledFormItem>

                  <CloseButton
                    icon={<CloseIcon />}
                    shape="circle"
                    type="text"
                    onClick={() => remove(index)}
                  />
                </Row>

                <LastFormItem
                  label={t('common:labels.email')}
                  name={[field.name, 'email']}
                  rules={[
                    {
                      required: true,
                      message: t('create-entity-page:errors.email-required'),
                    },
                    {
                      type: 'email',
                      message: t('create-entity-page:errors.email-invalid'),
                    },
                  ]}>
                  <Input placeholder={t('create-entity-page:placeholders.email')} />
                </LastFormItem>
              </ChefFormContainer>
            ))}

            <AddChefButton type="dashed" onClick={() => add()} icon={<PlusIcon />}>
              {t('create-entity-page:buttons.add-chef')}
            </AddChefButton>
          </>
        )}
      </Form.List>
    </>
  );
};
