import React from 'react';

import {Row} from 'antd';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';

import {ChallengeType} from 'src/interfaces/meal.interface';
import {NoisePollutionValue, WasteValue} from 'src/interfaces/api/generated.interface';

import {TruckIcon} from 'src/components/atoms/icon/TruckIcon';
import {NoiseIcon} from 'src/components/atoms/icon/NoiseIcon';
import {WeatherTag} from 'src/components/atoms/tag/WeatherTag';

const Container = styled.div`
  height: ${({theme}) => theme.heights.challengeResultCard.default};
  background-color: ${({theme}) => theme.colors.gray2};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme}) => theme.paddings.lg};
  overflow: hidden;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
`;

const Description = styled.span`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray8};
`;

const StyledTruckIcon = styled(TruckIcon)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const StyledNoiseIcon = styled(NoiseIcon)`
  margin-right: ${({theme}) => theme.margins.xs};
`;

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({theme}) => theme.margins.sm};
`;

const StyledWeatherTag = styled(WeatherTag)`
  margin-top: ${({theme}) => theme.margins.sm};
`;

interface MealChallengeResultCardProps extends React.HTMLAttributes<HTMLDivElement> {
  type: ChallengeType;
  value: NoisePollutionValue | WasteValue;
}

export const MealChallengeResultCard: React.FC<MealChallengeResultCardProps> = ({
  type,
  value,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation('home-page');

  // Theme
  const theme = useTheme();

  return (
    <Container {...rest}>
      <Row>
        {type === ChallengeType.FoodWaste ? (
          <StyledTruckIcon size={26} color={theme.colors.primary5} />
        ) : (
          <StyledNoiseIcon size={26} color={theme.colors.primary5} />
        )}

        <Title>
          {type === ChallengeType.FoodWaste
            ? t('challenge.food-waste')
            : t('challenge.noise-pollution')}
        </Title>
      </Row>

      <StyledWeatherTag value={value} type={type} />

      <StyledRow>
        <Description>{t('food-challenge-description')}</Description>
      </StyledRow>
    </Container>
  );
};
