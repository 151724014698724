import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const JarIcon = ({
  width = 21,
  height = 20,
  size,
  color,
  strokeWidth = 1.66,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width ?? size} height={height ?? size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M5.667 4.1v1.391c0 .221-.088.433-.244.589l-.447.446A3.33 3.33 0 0 0 4 8.881v6.115a2.5 2.5 0 0 0 2.5 2.499h8.333a2.5 2.5 0 0 0 2.5-2.499V8.881a3.33 3.33 0 0 0-.976-2.355l-.446-.446a.833.833 0 0 1-.244-.589v-1.39"
      />

      <rect
        width={11.667}
        height={2.498}
        x={4.833}
        y={1.672}
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        rx={1.249}
      />

      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M4 8.75h2.643c.442 0 .866-.175 1.178-.487v0a4.025 4.025 0 0 1 2.846-1.178v0c1.067 0 2.09.424 2.845 1.178v0c.312.312.736.488 1.178.488h2.643M17.333 12.915H14.69c-.442 0-.866.175-1.178.488v0a4.025 4.025 0 0 1-2.845 1.177v0a4.025 4.025 0 0 1-2.846-1.178v0a1.667 1.667 0 0 0-1.178-.487H4"
      />
    </svg>
  );
};
