import React, {useCallback} from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Divider, Form, Modal} from 'antd';
import styled, {useTheme} from 'styled-components';
import {RcFile} from 'antd/es/upload';

import {FoodForm} from 'src/interfaces/food.interface';

import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {Input} from 'src/components/atoms/input/Input';
import {Mark} from 'src/components/atoms/mark/Mark';
import {Button} from 'src/components/atoms/button/Button';
import {Upload} from 'src/components/atoms/input/Upload';

import useSnackBar from 'src/hooks/useSnackBar';
import {useFoodQuery} from 'src/hooks/queries/useFoodQuery';
import {useCreateFoodMutation} from 'src/hooks/mutations/useCreateFoodMutation';
import {useUploadFoodImageMutation} from 'src/hooks/mutations/useUploadFoodImageMutation';
import {useUpdateFoodMutation} from 'src/hooks/mutations/useUpdateFoodMutation';
import {useDeleteFoodImageMutation} from 'src/hooks/mutations/useDeleteFoodImageMutation';
import {settings} from 'src/constants/settings';

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const StyledFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
`;

export default function CreateEditFoodPage() {
  // Translation
  const {t} = useTranslation(['common', 'create-food-page']);

  // Navigate
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Extract FoodId from URL if exists
  const {id: foodId} = useParams();

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Query
  const {data: food} = useFoodQuery({foodId: Number(foodId)}, {enabled: !!foodId});

  // Mutation
  const {
    mutateAsync: createFood,
    isLoading: isCreatingFood,
    error: createError,
  } = useCreateFoodMutation();
  const {mutateAsync: updateFood, isLoading: isUpdatingFood} = useUpdateFoodMutation();
  const {mutateAsync: uploadFoodImage, isLoading: isUploadingImage} =
    useUploadFoodImageMutation();
  const {mutateAsync: deleteFoodImage, isLoading: isDeletingFoodImage} =
    useDeleteFoodImageMutation();

  // Callbacks
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleFinish = useCallback(
    async ({image, ...rest}: FoodForm) => {
      try {
        if (!food) {
          const newFood = await createFood(rest);

          if (image) {
            await uploadFoodImage({
              foodId: newFood.id,
              image:
                'originFileObj' in image.file && image.file.originFileObj
                  ? image.file.originFileObj
                  : (image.file as RcFile),
            });
          }

          showSuccessSnackBar(t('create-food-page:success.created'));
        } else {
          await updateFood({...rest, foodId: Number(foodId)});

          if (image?.file) {
            deleteFoodImage({
              foodId: Number(foodId),
              imageId: food.image.id,
              key: food.image.key,
            });

            await uploadFoodImage({
              image:
                'originFileObj' in image.file && image.file.originFileObj
                  ? image.file.originFileObj
                  : (image.file as RcFile),
              foodId: food.id,
            });
          }

          showSuccessSnackBar(t('create-food-page:success.updated'));
        }
        navigate(-1);
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [
      showErrorSnackBar,
      showSuccessSnackBar,
      updateFood,
      createFood,
      navigate,
      t,
      uploadFoodImage,
      deleteFoodImage,
      food,
      foodId,
    ],
  );

  const renderMark = useCallback((label: React.ReactNode, info: {required: boolean}) => {
    return <Mark label={label} info={info} />;
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {foodId ? t('create-food-page:helmet-edit') : t('create-food-page:helmet')}
        </title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        width={parseInt(theme.widths.createFoodModal.default, 10)}
        footer={<></>}>
        <HeaderContainer>
          <PlusIcon color={theme.colors.primary5} size={24} />

          <Title>
            {foodId ? t('create-food-page:title-edit') : t('create-food-page:title')}
          </Title>
        </HeaderContainer>

        <Divider />

        <Form
          layout="vertical"
          onFinish={handleFinish}
          requiredMark={renderMark}
          initialValues={{chefs: [{}]}}>
          <Form.Item
            label={t('create-food-page:labels.name')}
            name="name"
            validateStatus={
              createError?.response?.data.statusCode === 409 ? 'error' : undefined
            }
            help={createError?.response?.data.message}
            initialValue={food?.name}
            rules={[
              {
                required: true,
                message: t('create-food-page:errors.name-required'),
              },
            ]}>
            <Input defaultValue={food?.name} />
          </Form.Item>

          <Form.Item
            label={t('create-food-page:labels.description')}
            name="description"
            initialValue={food?.description}
            rules={[
              {
                required: true,
                message: t('create-food-page:errors.description-required'),
              },
            ]}>
            <Input defaultValue={food?.description} />
          </Form.Item>

          <Form.Item
            label={t('create-food-page:labels.seasonality')}
            name="seasonality"
            initialValue={food?.seasonality}
            rules={[
              {
                required: true,
                message: t('create-food-page:errors.seasonality-required'),
              },
            ]}>
            <Input defaultValue={food?.seasonality} />
          </Form.Item>

          <Form.Item
            label={t('create-food-page:labels.origin')}
            name="origin"
            initialValue={food?.origin}
            rules={[
              {
                required: true,
                message: t('create-food-page:errors.origin-required'),
              },
            ]}>
            <Input defaultValue={food?.origin} />
          </Form.Item>

          <Form.Item
            label={t('create-food-page:labels.nutritional-intake')}
            name="nutritionalIntake"
            initialValue={food?.nutritionalIntake}
            rules={[
              {
                required: true,
                message: t('create-food-page:errors.nutritional-intake-required'),
              },
            ]}>
            <Input defaultValue={food?.nutritionalIntake} />
          </Form.Item>

          <Form.Item
            label={t('create-food-page:labels.benefits')}
            name="benefits"
            initialValue={food?.benefits}
            rules={[
              {
                required: true,
                message: t('create-food-page:errors.benefits-required'),
              },
            ]}>
            <Input defaultValue={food?.benefits} />
          </Form.Item>

          <Form.Item
            name="image"
            label={t('create-food-page:labels.image')}
            initialValue={food?.image}
            rules={[
              {
                required: true,
                message: t('create-food-page:errors.image-required'),
              },
              {
                validator: (_, value) => {
                  if (!value || 'id' in value) {
                    return Promise.resolve();
                  }
                  if (value.file.size / 1024 / 1024 > settings.dish.maxFileSize.image) {
                    return Promise.reject(
                      new Error(
                        t('create-food-page:errors.image-too-big', {
                          maxSize: settings.dish.maxFileSize.image,
                        }),
                      ),
                    );
                  }
                  const isImg =
                    value.file.type === 'image/jpeg' ||
                    value.file.type === 'image/jpg' ||
                    value.file.type === 'image/png';

                  if (!isImg) {
                    return Promise.reject(
                      new Error(t('create-food-page:errors.image-invalid-format')),
                    );
                  }

                  return Promise.resolve();
                },
              },
            ]}>
            <Upload
              multiple={false}
              maxCount={1}
              defaultFileList={
                food?.image ? [{...food.image, uid: food.id.toString()}] : undefined
              }
            />
          </Form.Item>

          <FooterContainer>
            <BackButton block key="back" onClick={handleCancel}>
              {t('common:buttons.back')}
            </BackButton>

            <StyledFormItem>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={
                  isCreatingFood ||
                  isUpdatingFood ||
                  isUploadingImage ||
                  isDeletingFoodImage
                }>
                {t('common:buttons.create')}
              </Button>
            </StyledFormItem>
          </FooterContainer>
        </Form>
      </Modal>
    </>
  );
}
