import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const SquareCheckIcon = ({
  width = 24,
  height = 25,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width ?? size} height={height ?? size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M15.307 4.12H7.453C4.716 4.12 3 6.056 3 8.8v7.4c0 2.742 1.708 4.68 4.453 4.68h7.853c2.746 0 4.455-1.938 4.455-4.68V8.8c0-2.743-1.71-4.68-4.454-4.68Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M21 5.82c-5.153 2.494-8.3 7.042-9.744 9.752a16.333 16.333 0 0 0-3.527-4.14"
      />
    </svg>
  );
};
