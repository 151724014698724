import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {GetFoods, GetFoodsResponse} from 'src/interfaces/api/food.interface';

import {networkClient} from 'src/services/client';

export function useFoodsQuery(
  {page, search, limit}: GetFoods,
  options?: UseQueryOptions<GetFoodsResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.Foods, page, search, limit],
    queryFn: async (): Promise<GetFoodsResponse | null> => {
      const {data} = await networkClient.get(Routes.Foods, {
        params: {page, search, limit},
      });

      return data;
    },
    keepPreviousData: true,
    ...options,
  });
}
