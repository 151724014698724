import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {User} from 'src/interfaces/api/generated.interface';
import {ResetPasswordParams} from 'src/interfaces/api/user.interface';

import {networkClient} from 'src/services/client';

export function useResetPasswordMutation() {
  return useMutation({
    mutationFn: async (params: ResetPasswordParams): Promise<User> => {
      const {data} = await networkClient.put<User>(Routes.ResetPassword, {
        ...params,
      });

      return data;
    },
  });
}
