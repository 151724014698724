import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const MainDishIcon = ({
  size = 20,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 20 20" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m3.884 16.76.33.22a3.115 3.115 0 0 0 1.727.523h8.118c.615 0 1.216-.182 1.728-.523l.329-.22a3.115 3.115 0 0 0 1.387-2.592v0a.834.834 0 0 0-.834-.833H3.332a.834.834 0 0 0-.834.833v0c0 1.042.52 2.015 1.387 2.593v0Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M16.506 13.335a1.794 1.794 0 0 0-1.093-1.892l-.037-.016a1.794 1.794 0 0 1-.973-.972l-.015-.038a1.794 1.794 0 0 0-1.892-1.093l-.044.006a1.798 1.798 0 0 1-1.33-.357l-.03-.023a1.793 1.793 0 0 0-2.184 0l-.034.026c-.377.29-.854.418-1.325.356l-.045-.006a1.793 1.793 0 0 0-1.891 1.093l-.015.037c-.182.44-.533.79-.973.973l-.037.015c-.75.31-1.2 1.086-1.093 1.891"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M6.217 9.65 2.866 6.298a1.25 1.25 0 1 1 1.767-1.767l4.36 4.36"
      />
    </svg>
  );
};
