import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const ArrowLeftIcon = ({
  height = 15,
  width = 14,
  size,
  color,
  strokeWidth = 1,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      fill="none"
      viewBox="0 0 14 15"
      {...rest}>
      <path
        fill={color ?? theme.colors.gray12}
        fillOpacity={0.85}
        fillRule="evenodd"
        d="M8.697 4.637a.75.75 0 0 1 0 1.06L6.894 7.5l1.803 1.803a.75.75 0 0 1-1.06 1.061L5.302 8.031a.75.75 0 0 1 0-1.061l2.333-2.333a.75.75 0 0 1 1.06 0Z"
        clipRule="evenodd"
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
