import React, {useCallback, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {List, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useDebouncedCallback} from 'use-debounce';
import {useLocation, useNavigate} from 'react-router-dom';
import styled, {useTheme} from 'styled-components';

import {Search} from 'src/components/atoms/input/Search';
import {EmptyIllustrationCta} from 'src/components/organisms/empty/EmptyIllustrationCta';
import {FoodCard} from 'src/components/molecules/card/FoodCard';
import {Button} from 'src/components/atoms/button/Button';
import {EditIcon} from 'src/components/atoms/icon/EditIcon';
import {paths} from 'src/navigation/paths';

import useCurrentUser from 'src/hooks/useCurrentUser';
import {useFoodsQuery} from 'src/hooks/queries/useFoodsQuery';
import {settings} from 'src/constants/settings';

const StyledSearch = styled(Search)`
  margin-bottom: ${({theme}) => theme.margins.md};
  width: 100%;
`;

const AddButton = styled(Button)`
  margin-left: ${({theme}) => theme.margins.sm};
`;

const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PopoverButton = styled(Button)`
  align-items: center;
  display: flex;
  margin-bottom: ${({theme}) => theme.margins.xxs};
`;

export default function FoodsPage() {
  // Translation
  const {t} = useTranslation(['common', 'foods-page']);

  // Theme
  const theme = useTheme();

  // State
  const [search, setSearch] = useState('');
  const [missingFoodInput, setMissingFoodInput] = useState('');

  // Navigate
  const navigate = useNavigate();

  // Location
  const location = useLocation();

  // Current user
  const {isAdmin} = useCurrentUser();

  // Query
  const {data, isLoading} = useFoodsQuery({page: 1, search, limit: 24});

  // Callbacks
  const handleSearch = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, settings.input.debounce);

  //
  const handleOpenFood = useCallback(
    (foodId: number) => {
      navigate(
        isAdmin
          ? paths.admin.foods.details({id: foodId})
          : paths.chef.foods.details({id: foodId}),
        {
          state: {previousLocation: location},
        },
      );
    },
    [navigate, location, isAdmin],
  );

  const handleAddFood = useCallback(() => {
    navigate(paths.admin.foods.create.pattern, {
      state: {previousLocation: location},
    });
  }, [navigate, location]);

  const handleUnfoundFood = useCallback(() => {
    window.open(
      `mailto:email@example.com?subject=Demande%20d'ajout%20d'un%20aliment&body=Bonjour,%0A%0AJ'aimerais%20ajouter%20l'aliment%20suivant%20${missingFoodInput}%20Merci%20d'avance%20pour%20votre%20aide%20!`,
    );
  }, [missingFoodInput]);

  const handleEditClick = useCallback(
    (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
      foodId: number,
      close: () => void,
    ) => {
      event.stopPropagation();
      close();
      navigate(paths.admin.foods.edit({id: foodId}), {
        state: {previousLocation: location},
      });
    },
    [navigate, location],
  );

  const renderMoreContent = useCallback(
    (foodId: number, close: () => void) => {
      return (
        <PopoverContainer>
          <PopoverButton
            icon={<EditIcon />}
            type="text"
            onClick={e => handleEditClick(e, foodId, close)}>
            {t('buttons.edit')}
          </PopoverButton>
        </PopoverContainer>
      );
    },
    [t, handleEditClick],
  );

  return (
    <>
      <Helmet>
        <title>{t('foods-page:helmet')}</title>
      </Helmet>

      <Row wrap={false}>
        <StyledSearch
          placeholder={t('foods-page:placeholders.search')}
          onSearch={handleSearch}
        />

        {isAdmin && (
          <AddButton
            size="medium"
            type="primary"
            onClick={handleAddFood}
            disabled={!isAdmin}>
            {t('foods-page:buttons.add')}
          </AddButton>
        )}
      </Row>

      {data?.foods.length ? (
        <List
          grid={{gutter: parseInt(theme.paddings.xs, 10), column: 3}}
          loading={isLoading}
          dataSource={data?.foods ?? []}
          renderItem={item => (
            <FoodCard
              image={item?.image?.url}
              name={item.name}
              description={item.description}
              id={item.id}
              onClick={handleOpenFood}
              renderMoreContent={
                isAdmin ? close => renderMoreContent(item.id, close) : undefined
              }
            />
          )}
        />
      ) : (
        <EmptyIllustrationCta
          title={t('foods-page:empty.title', {foodName: search})}
          description={t('foods-page:empty.description')}
          buttonText={t('foods-page:empty.button')}
          input
          search={missingFoodInput}
          onSearch={setMissingFoodInput}
          onClick={handleUnfoundFood}
        />
      )}
    </>
  );
}
