import React, {useCallback, useMemo} from 'react';

import {Helmet} from 'react-helmet-async';
import {Divider, Modal} from 'antd';
import styled, {useTheme} from 'styled-components';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {DishRateCriteria} from 'src/interfaces/dish-rates.interface';

import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {Button} from 'src/components/atoms/button/Button';
import {CutleryIcon} from 'src/components/atoms/icon/CutleryIcon';
import {DishRatingCriteriaCard} from 'src/components/molecules/card/DishRatingCriteriaCard';

import {useDishRatesQuery} from 'src/hooks/queries/useDishRatesQuery';
import {getDishRatesByCriteria} from 'src/utils/dish-rates';

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AverageRatingText = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
  margin-right: ${({theme}) => theme.margins.xs};
`;

const TextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AverageRatingValue = styled.span`
  ${({theme}) => theme.textStyles.h3};
  color: ${({theme}) => theme.colors.primary5};
`;

const RatingDetailsText = styled.span`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

const RatingDetailsValue = styled.span`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.primary5};
  margin: 0 ${({theme}) => theme.margins.ssm};
`;

const AverateRatingRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const RatingDetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${({theme}) => theme.margins.xxxs} 0;
`;

const StyledDishRatingCriteriaCard = styled(DishRatingCriteriaCard)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const CriteriaRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${({theme}) => -parseInt(theme.margins.xs, 10)}px;
  margin-right: ${({theme}) => -parseInt(theme.margins.md, 10)}px;
  justify-content: space-around;
  margin-top: ${({theme}) => theme.margins.sm};
`;

export default function MealDishRatingsPage() {
  // Translation
  const {t} = useTranslation(['common', 'meal-dish-ratings-page']);

  // Theme
  const theme = useTheme();

  // Navigate
  const navigate = useNavigate();

  // Extract dishId from URL
  const {dishId, mealId} = useParams();

  // Queries
  const {data: dishRates} = useDishRatesQuery(
    {dishId: Number(dishId)!, mealId: Number(mealId)!},
    {enabled: !!dishId && !!mealId},
  );

  // Constants
  const rating = useMemo(
    () =>
      dishRates?.length
        ? (dishRates
            ?.map(
              rate =>
                (rate.aroma + rate.appearance + rate.temperature + rate.quantity) / 2,
            )
            ?.reduce((a, b) => a + b, 0) ?? 0) / (dishRates?.length ?? 1)
        : undefined,
    [dishRates],
  );

  const under3 = useMemo(
    () =>
      dishRates?.filter(
        rate =>
          (rate.aroma + rate.appearance + rate.temperature + rate.quantity) / 2 <= 3,
      ).length ?? 0,
    [dishRates],
  );

  const above4 = useMemo(
    () =>
      dishRates?.filter(
        rate =>
          (rate.aroma + rate.appearance + rate.temperature + rate.quantity) / 2 >= 4,
      ).length ?? 0,
    [dishRates],
  );

  const tasteData = useMemo(
    () => getDishRatesByCriteria(dishRates ?? [], DishRateCriteria.Aroma),
    [dishRates],
  );

  const appearanceData = useMemo(
    () => getDishRatesByCriteria(dishRates ?? [], DishRateCriteria.Appearance),
    [dishRates],
  );

  const quantityData = useMemo(
    () => getDishRatesByCriteria(dishRates ?? [], DishRateCriteria.Quantity),
    [dishRates],
  );

  const temperatureData = useMemo(
    () => getDishRatesByCriteria(dishRates ?? [], DishRateCriteria.Temperature),
    [dishRates],
  );

  // Callbacks
  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>{t('meal-dish-ratings-page:helmet')}</title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        width={920}
        footer={[
          <FooterContainer key="back">
            <Button onClick={handleCancel}>{t('common:buttons.back')}</Button>
          </FooterContainer>,
        ]}>
        <HeaderContainer>
          <CutleryIcon color={theme.colors.primary5} />

          <Title>{t('meal-dish-ratings-page:title', {dishName: 'Toooo'})}</Title>
        </HeaderContainer>

        <Divider />

        <ContentContainer>
          <TextsContainer>
            <AverateRatingRow>
              <AverageRatingText>
                {t('meal-dish-ratings-page:average-rating')}
              </AverageRatingText>

              <AverageRatingValue>{rating}/10</AverageRatingValue>
            </AverateRatingRow>

            {above4 !== undefined && dishRates && (
              <RatingDetailsRow>
                <RatingDetailsText>
                  {t('meal-dish-ratings-page:people-who-rate-4-or-more')}
                </RatingDetailsText>

                <RatingDetailsValue>
                  {(above4 / dishRates.length) * 100}%
                </RatingDetailsValue>

                {/* <RatingDetailsText>
                  {t('meal-dish-ratings-page:students-rate', {value: 130, total: 300})}
                </RatingDetailsText> */}
              </RatingDetailsRow>
            )}

            {under3 !== undefined && dishRates && (
              <RatingDetailsRow>
                <RatingDetailsText>
                  {t('meal-dish-ratings-page:people-who-rate-3-or-less')}
                </RatingDetailsText>

                <RatingDetailsValue>
                  {(under3 / dishRates?.length) * 100}%
                </RatingDetailsValue>

                {/* <RatingDetailsText>
                {t('meal-dish-ratings-page:students-rate', {value: 130, total: 300})}
              </RatingDetailsText> */}
              </RatingDetailsRow>
            )}
          </TextsContainer>

          <CriteriaRow>
            <StyledDishRatingCriteriaCard
              reviews={tasteData.reviews}
              title={t('meal-dish-ratings-page:criteria.taste')}
              rating={tasteData.averageRating}
            />

            <StyledDishRatingCriteriaCard
              reviews={appearanceData.reviews}
              title={t('meal-dish-ratings-page:criteria.visual-aspect')}
              rating={appearanceData.averageRating}
            />

            <StyledDishRatingCriteriaCard
              reviews={temperatureData.reviews}
              title={t('meal-dish-ratings-page:criteria.temperature')}
              rating={quantityData.averageRating}
            />

            <StyledDishRatingCriteriaCard
              reviews={quantityData.reviews}
              title={t('meal-dish-ratings-page:criteria.quantity')}
              rating={quantityData.averageRating}
            />
          </CriteriaRow>

          <Divider />
        </ContentContainer>
      </Modal>
    </>
  );
}
