export enum Routes {
  // Auth
  RefreshToken = '/user/refresh-token',
  ForgotPassword = '/user/forgot-password',
  ResetPassword = '/user/reset-password',
  SignIn = '/user/sign-in',
  SignUpChef = '/user/sign-up-chef',

  // User
  Me = '/user/me',
  User = '/user',

  // Entity
  Entity = '/entity',
  Entities = '/entities',
  EntityEatingDays = '/entity/eating-days',

  // Food
  Foods = '/foods',
  Food = '/food',
  FoodImage = '/food/image',

  // Dish
  Dishes = '/dishes',
  Dish = '/dish',
  DishImage = '/dish/image',
  DishDocuments = '/dish/documents',
  DishDocument = '/dish/document',

  // Dish Rate
  DishRates = '/dish/rates',

  // Menu
  Menu = '/menu',
  Menus = '/menus',

  // Meal
  Meal = '/meal',
  PublishMeal = '/meal/publish',
  NextWeeksMeals = '/meals/next-weeks',
  MealWeather = '/meal/weather',
  MealsLastWeatherReports = '/meals/last-weather-reports',

  // Admin
  AdminUser = '/admin/user',
  AdminEntity = '/admin/entity',
  AdminEntityInvite = '/admin/entity/invite',
}

export interface Error {
  message: string;
  error?: string;
  statusCode: number;
}

export interface PaginationResponse {
  totalCount: number;
  page: number;
  limit: number;
}
