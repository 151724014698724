import {spacings, radius, opacities, borders} from 'src/constants/theme/spacings';
import {fonts, fontSizes, textStyles} from 'src/constants/theme/fonts';
import {heights, widths, aspectRatios} from 'src/constants/theme/sizes';
import {shadows} from 'src/constants/theme/shadows';

const common = {
  ...spacings,
  radius,
  opacities,
  fonts,
  fontSizes,
  textStyles,
  borders,
  heights,
  widths,
  aspectRatios,
  shadows,
};

export const lightTheme = {
  colors: {
    // Base
    white: 'rgba(255, 255, 255, 1)',
    black: 'rgba(0, 0, 0, 1)',
    background: '#F6F2EF',

    // Grays
    gray1: '#ffffff',
    gray2: '#F4F4F4',
    gray3: '#EEEEEE',
    gray4: '#D6D7D7',
    gray5: '#BCBDC1',
    gray6: '#A3A5AA',
    gray7: '#8B8C93',
    gray8: '#73757D',
    gray10: '#46484F',
    gray11: '#434349',
    gray12: '#1F2026',

    // Black
    black300: '#D8D9E4',
    black500: '#8F90A6',

    // Primary
    primary1: '#FFF2EF',
    primary2: '#FFD6CA',
    primary5: '#FF815E',
    primary6: '#DD6948',

    // Yellow
    yellow3: '#FEBE39',

    // Green
    green2: '#E3FFDE',
    green5: '#A2D299',
    green6: '#8BB982',
    green8: '#608659',

    // Rose
    rose3: '#F46285',

    // Red
    red700: '#FF4E4E',
    red93d: '#E91B283D',
    red9: '#E91B28',

    // Purple
    purple3: '#7645D1',

    // Weather colors
    apple: '#74BE42',
    apple24: '#74BE423D',
    munsell: '#00A783',
    munsell24: '#00A7833D',
    mikado: '#FFC10D',
    mikado24: '#FFC10D3D',
    pigment: '#E91B28',
    pigment24: '#E91B283D',

    // Beige
    beige: '#F6F2EF',
  } as const,
  ...common,
};

export const themes = {
  light: lightTheme,
};

export type Theme = typeof lightTheme;
