import React, {useCallback, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {Divider, Image, Modal} from 'antd';
import styled, {useTheme} from 'styled-components';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {NutritionalScoreType} from 'src/interfaces/api/dish.interface';

import {HamburgerIcon} from 'src/components/atoms/icon/HamburgerIcon';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {Button} from 'src/components/atoms/button/Button';
import {FoodTab} from 'src/components/organisms/tabs/FoodTab';
import {NutritionalTag} from 'src/components/atoms/tag/NutritionalTag';
import {paths} from 'src/navigation/paths';

import {useDishQuery} from 'src/hooks/queries/useDishQuery';
import {useDeleteDishMutation} from 'src/hooks/mutations/useDeleteDishMutation';
import useSnackBar from 'src/hooks/useSnackBar';

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const StyledImage = styled(Image)`
  aspect-ratio: ${({theme}) => theme.aspectRatios.dishDetailsImage};
  border-radius: ${({theme}) => theme.radius.lg};
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: ${({theme}) => theme.margins.sm};
  left: ${({theme}) => theme.margins.sm};
  right: ${({theme}) => theme.margins.sm};
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledNutritionalTag = styled(NutritionalTag)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

export default function DishDetailsPage() {
  // Translation
  const {t} = useTranslation(['common', 'dish-details-page']);

  // Theme
  const theme = useTheme();

  // Navigate
  const navigate = useNavigate();

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  const [tabIndex, setTabIndex] = useState(0);

  // Extract dishId from URL
  const {id: dishId} = useParams();

  // Query
  const {data: dish} = useDishQuery({dishId: Number(dishId!)}, {enabled: !!dishId});

  // Mutation
  const {mutateAsync: deleteDish} = useDeleteDishMutation();

  // Callbacks
  const handleOpenDocument = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const handleEditDish = useCallback(() => {
    if (dishId) {
      navigate(paths.chef.dishes.edit({id: dishId}));
    }
  }, [navigate, dishId]);

  const handleDeleteDish = useCallback(async () => {
    try {
      if (dishId) {
        await deleteDish({dishId: Number(dishId)});
        navigate(paths.chef.dishes.pattern);
        showSuccessSnackBar(t('dish-details-page:success.deleted'));
      }
    } catch (error) {
      showErrorSnackBar(error?.message ?? error);
    }
  }, [dishId, navigate, deleteDish, showErrorSnackBar, showSuccessSnackBar, t]);

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  if (!dish) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{t('dish-details-page:helmet')}</title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        width={920}
        footer={[
          <FooterContainer key="back">
            <Button onClick={handleCancel}>{t('common:buttons.back')}</Button>
          </FooterContainer>,
        ]}>
        <HeaderContainer>
          <HamburgerIcon color={theme.colors.primary5} />

          <Title>{t('dish-details-page:title', {dishName: dish.name})}</Title>
        </HeaderContainer>

        <Divider />

        <ImageContainer>
          <StyledImage src={dish.image.url} width="100%" preview={false} />

          <TagsContainer>
            <StyledNutritionalTag
              type={NutritionalScoreType.Calories}
              value={dish.nutritionalScore.calories}
            />

            <StyledNutritionalTag
              type={NutritionalScoreType.Benefits}
              value={dish.nutritionalScore.benefits}
            />

            <StyledNutritionalTag
              type={NutritionalScoreType.CarbonFootprint}
              value={dish.nutritionalScore.carbonFootprint}
            />

            <NutritionalTag
              type={NutritionalScoreType.Transformation}
              value={dish.nutritionalScore.transformation}
            />
          </TagsContainer>
        </ImageContainer>

        <FoodTab currentIndex={tabIndex} onTabChange={setTabIndex} foods={dish.foods} />

        <Divider />
      </Modal>
    </>
  );
}
