/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  URLSearchParamsInit,
  useSearchParams as useRRSearchParams,
  NavigateOptions,
} from 'react-router-dom';
import {QueryParamsFor} from 'ts-routes';

// Ensure type safety with search params

interface URLSearchParams<SupportedKeys = any> {
  append(name: SupportedKeys, value: string): void;
  delete(name: SupportedKeys): void;
  get(name: SupportedKeys): string | null;
  getAll(name: SupportedKeys): string[];
  has(name: SupportedKeys): boolean;
  set(name: SupportedKeys, value: string): void;
  sort(): void;
  toString(): string;
  forEach(
    callbackfn: (
      value: string,
      key: SupportedKeys,
      parent: URLSearchParams,
    ) => void,
    thisArg?: any,
  ): void;
}

type SetURLSearchParams<SupportedKeys = any> = (
  nextInit?:
    | URLSearchParamsInit
    | ((prev: URLSearchParams<SupportedKeys>) => URLSearchParamsInit),
  navigateOpts?: NavigateOptions,
) => void;

export default function useSearchParams<T extends (...args: any[]) => string>(
  defaultInit?: URLSearchParamsInit,
) {
  const queryParams = useRRSearchParams(defaultInit);

  return queryParams as unknown as [
    URLSearchParams<keyof QueryParamsFor<T>>,
    SetURLSearchParams,
  ];
}
