import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {UploadDishImage} from 'src/interfaces/api/dish.interface';
import {Dish} from 'src/interfaces/api/generated.interface';

import {networkClient} from 'src/services/client';

export function useUploadDishImageMutation() {
  return useMutation({
    mutationFn: async ({image, dishId}: UploadDishImage): Promise<Dish> => {
      const formData = new FormData();

      formData.append('image', image);
      formData.append('dishId', dishId.toString());

      const {data} = await networkClient.post<Dish>(Routes.DishImage, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return data;
    },
  });
}
