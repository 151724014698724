import {
  NutritionalScoreForm,
  NutritionalScoreType,
} from 'src/interfaces/api/dish.interface';

export const nustritionalScoreDefaultValues: {
  [key in NutritionalScoreType]: NutritionalScoreForm;
} = {
  [NutritionalScoreType.Calories]: {
    question1: null,
    question2: null,
    question3: null,
  },
  [NutritionalScoreType.CarbonFootprint]: {
    question1: null,
    question2: null,
    question3: null,
  },
  [NutritionalScoreType.Benefits]: {
    question1: null,
    question2: null,
    question3: null,
  },
  [NutritionalScoreType.Transformation]: {
    question1: null,
    question2: null,
    question3: null,
  },
};
