import React, {useCallback} from 'react';

import {Avatar, Col, Row} from 'antd';
import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';

import {Food} from 'src/interfaces/api/generated.interface';

import {CalendarInfoIcon} from 'src/components/atoms/icon/CalendarInfoIcon';
import {CompassInfoIcon} from 'src/components/atoms/icon/CompassInfoIcon';
import {StrengthInfoIcon} from 'src/components/atoms/icon/StrengthInfoIcon';
import {ThumbUpInfoIcon} from 'src/components/atoms/icon/ThumbUpInfoIcon';

const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({theme}) => theme.colors.beige};
  height: ${({theme}) => theme.heights.foodTabBar.default};
  border-radius: ${({theme}) => theme.radius.md};
  overflow: scroll;
  padding: ${({theme}) => theme.paddings.xxxs};
  margin-top: ${({theme}) => theme.margins.sm};
`;

const TabItem = styled.div<{active: boolean}>`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: ${({theme}) => theme.paddings.md};
  background-color: ${({theme, active}) =>
    active ? theme.colors.primary5 : 'transparent'};
  max-height: ${({theme}) => theme.heights.foodTabBar.active};
  min-width: ${({theme}) => theme.widths.foodTabBar.active};
  border-radius: ${({theme}) => theme.radius.md};
  margin-right: ${({theme}) => theme.margins.xs};
`;

const TabName = styled.text<{active: boolean; isLast: boolean}>`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme, active}) => (active ? theme.colors.white : theme.colors.gray6)};
  margin-left: ${({theme, isLast}) => (isLast ? '0px' : theme.margins.xs)};
`;

const Title = styled.text`
  ${({theme}) => theme.textStyles.h4};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({theme}) => theme.margins.sm};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const InfoTitle = styled.text`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
`;

const InfoContainer = styled.div`
  flex-direction: row;
  display: flex;
  margin-top: ${({theme}) => theme.margins.sm};
`;

const InfoValue = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
`;

const InfoFieldsContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-left: ${({theme}) => theme.margins.ssm};
  width: 100%;
`;

const Description = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
  margin-top: ${({theme}) => theme.margins.sm};
  margin-bottom: ${({theme}) => theme.margins.sm};
  text-align: center;
`;

const StyledRow = styled(Row)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

interface FoodTabProps {
  onTabChange: (index: number) => void;
  currentIndex: number;
  foods: Food[];
}

export const FoodTab: React.FC<FoodTabProps> = ({onTabChange, currentIndex, foods}) => {
  // Theme
  const theme = useTheme();

  // Translation
  const {t} = useTranslation(['food-details-page', 'common']);

  const renderTabContent = useCallback(() => {
    const food = foods[currentIndex];

    if (!food) {
      return null;
    }

    return (
      <ContentContainer>
        <HeaderContainer>
          <Avatar size={parseInt(theme.heights.avatar.md, 10)} src={food.image?.url} />

          <Title>{food.name}</Title>
        </HeaderContainer>

        <Description>{food.description}</Description>

        <StyledRow>
          <Col span={12}>
            <InfoContainer>
              <CalendarInfoIcon />

              <InfoFieldsContainer>
                <InfoTitle>{t('food-details-page:sections.seasonality')}</InfoTitle>

                <InfoValue>{food.seasonality}</InfoValue>
              </InfoFieldsContainer>
            </InfoContainer>
          </Col>

          <Col span={12}>
            <InfoContainer>
              <CompassInfoIcon />

              <InfoFieldsContainer>
                <InfoTitle>{t('food-details-page:sections.origin')}</InfoTitle>

                <InfoValue>{food.origin}</InfoValue>
              </InfoFieldsContainer>
            </InfoContainer>
          </Col>
        </StyledRow>

        <Row>
          <Col span={12}>
            <InfoContainer>
              <StrengthInfoIcon />

              <InfoFieldsContainer>
                <InfoTitle>{t('food-details-page:sections.nutritionalIntake')}</InfoTitle>

                <InfoValue>{food.nutritionalIntake}</InfoValue>
              </InfoFieldsContainer>
            </InfoContainer>
          </Col>

          <Col span={12}>
            <InfoContainer>
              <ThumbUpInfoIcon />

              <InfoFieldsContainer>
                <InfoTitle>{t('food-details-page:sections.benefits')}</InfoTitle>

                <InfoValue>{food.benefits}</InfoValue>
              </InfoFieldsContainer>
            </InfoContainer>
          </Col>
        </Row>
      </ContentContainer>
    );
  }, [currentIndex, theme.heights.avatar.md, foods, t]);

  return (
    <>
      <TabContainer>
        {foods.map((food, index) => (
          <TabItem
            onClick={() => onTabChange(index)}
            active={index === currentIndex}
            key={food.id}>
            <TabName active={index === currentIndex} isLast={index === foods.length - 1}>
              {food.name}
            </TabName>
          </TabItem>
        ))}
      </TabContainer>

      {renderTabContent()}
    </>
  );
};
