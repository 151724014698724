import {EatingDay} from 'src/interfaces/api/generated.interface';

export function getDayNbFromEatingDay(day: EatingDay) {
  switch (day) {
    case EatingDay.MONDAY:
      return 1;
    case EatingDay.TUESDAY:
      return 2;
    case EatingDay.WEDNESDAY:
      return 3;
    case EatingDay.THURSDAY:
      return 4;
    case EatingDay.FRIDAY:
      return 5;
    case EatingDay.SATURDAY:
      return 6;
    case EatingDay.SUNDAY:
      return 7;
  }
}
