import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const PhoneIcon = ({size = 18, color, strokeWidth = 1.5, ...rest}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M8.649 9.354c2.992 2.991 3.67-.469 5.575 1.435 1.837 1.836 2.892 2.203.565 4.53-.291.234-2.143 3.052-8.65-3.454-6.51-6.507-3.693-8.36-3.459-8.652C5.013.88 5.374 1.942 7.21 3.778 9.117 5.682 5.658 6.363 8.65 9.354Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
