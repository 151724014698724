import React, {useCallback, useMemo, useRef, useState} from 'react';

import {Col, List} from 'antd';
import {useDebouncedCallback} from 'use-debounce';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Dish, DishType, MenuType} from 'src/interfaces/api/generated.interface';

import {Search} from 'src/components/atoms/input/Search';
import {SmallDishCard} from 'src/components/molecules/card/SmallDishCard';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {MenuSectionsMenu} from 'src/components/molecules/menu/MenuSectionsMenu';
import {EmptyResults} from 'src/components/atoms/empty/EmptyResults';

import {useDishesQuery} from 'src/hooks/queries/useDishesQuery';
import {settings} from 'src/constants/settings';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({theme}) => theme.heights.menuForm.default};
`;

const StyledSmallDishCard = styled(SmallDishCard)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const InputContainer = styled.div`
  display: block;
  width: 100%;
`;

const StyledSearch = styled(Search)`
  margin-bottom: ${({theme}) => theme.paddings.sm};
`;

const SectionDishesContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-left: ${({theme}) => theme.margins.sm};
  overflow: scroll;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
`;

interface MenuDishPickerFormProps {
  menuType: MenuType;
  selectedDishes: Dish[];
  onSelectDish: (dish: Dish) => void;
  onRemoveDish: (dishId: number) => void;
}

export const MenuDishPickerForm: React.FC<MenuDishPickerFormProps> = ({
  menuType,
  selectedDishes,
  onSelectDish,
  onRemoveDish,
}) => {
  // Translation
  const {t} = useTranslation(['common', 'create-menu-page']);

  // State
  const [search, setSearch] = useState('');
  const [activeSection, setActiveSection] = useState<DishType>(DishType.STARTER);

  // Ref
  const searchRef = useRef<HTMLInputElement>(null);

  // Query
  const {data, isLoading} = useDishesQuery({
    page: 1,
    search,
    publicDishes: true,
    types:
      activeSection === DishType.DESSERT && menuType === MenuType.FOUR_DISHES
        ? [activeSection, DishType.DAIRY_PRODUCT]
        : [activeSection],
  });

  // Constants
  const sectionSelectedDishes = useMemo(
    () =>
      selectedDishes.filter(
        dish =>
          dish.type === activeSection ||
          (dish.type === DishType.DAIRY_PRODUCT &&
            menuType === MenuType.FOUR_DISHES &&
            activeSection === DishType.DESSERT),
      ),
    [activeSection, selectedDishes, menuType],
  );

  // Callbacks
  const handleSearch = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, settings.input.debounce);

  const handleSelectDish = useCallback(
    (dish: Dish) => {
      if (searchRef.current) {
        searchRef.current.value = '';
        setSearch('');
      }
      onSelectDish(dish);
    },
    [onSelectDish],
  );

  return (
    <Container>
      <MenuSectionsMenu
        activeSection={activeSection}
        menuType={menuType}
        setActiveSection={setActiveSection}
      />

      <SectionDishesContainer span={16}>
        <InputContainer>
          <StyledSearch
            placeholder={t('create-menu-page:placeholders.search')}
            onSearch={handleSearch}
            ref={searchRef}
          />
        </InputContainer>

        {search.length > 0 ? (
          <List
            loading={isLoading}
            dataSource={data?.dishes ?? []}
            renderItem={item => (
              <StyledSmallDishCard
                image={item?.image?.url}
                name={item.name}
                id={item.id}
                allergens={item.allergens}
                onClick={() => handleSelectDish(item)}
              />
            )}
          />
        ) : (
          <List
            loading={isLoading}
            dataSource={sectionSelectedDishes}
            locale={{
              emptyText: (
                <EmptyResults text={t('create-menu-page:placeholders.empty-selected')} />
              ),
            }}
            renderItem={item => (
              <StyledSmallDishCard
                image={item?.image?.url}
                name={item.name}
                id={item.id}
                allergens={item.allergens}
                rightComponent={
                  <CloseButton onClick={() => onRemoveDish(item.id)}>
                    <CloseIcon />
                  </CloseButton>
                }
              />
            )}
          />
        )}
      </SectionDishesContainer>
    </Container>
  );
};
