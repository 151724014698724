import {useMutation} from '@tanstack/react-query';

import {SignIn, SignedUser} from 'src/interfaces/api/user.interface';
import {Routes} from 'src/interfaces/api/api.interface';

import store from 'src/redux/store';
import {userActions} from 'src/redux/slices/user';
import {networkClient} from 'src/services/client';

export function useSignInMutation() {
  return useMutation({
    mutationFn: async (userData: SignIn): Promise<SignedUser> => {
      const {data} = await networkClient.post<SignedUser>(Routes.SignIn, userData);

      if (data.refreshToken && data.accessToken) {
        store.dispatch(userActions.setRefreshToken(data.refreshToken));
        store.dispatch(userActions.setAccessToken(data.accessToken));
      }

      return data;
    },
  });
}
