import React, {useCallback} from 'react';

import styled, {useTheme} from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Divider, Space} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';

import {Role} from 'src/interfaces/api/generated.interface';

import {Button} from 'src/components/atoms/button/Button';
import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {InitialsAvatar} from 'src/components/atoms/avatar/InitialsAvatar';
import {ArrowRightIcon} from 'src/components/atoms/icon/ArrowRightIcon';
import {paths} from 'src/navigation/paths';

import useCurrentUser from 'src/hooks/useCurrentUser';

const ActionHeaderContainer = styled.div`
  background-color: ${({theme}) => theme.colors.white};
  padding: ${({theme}) => theme.paddings.xs};
  border-radius: ${({theme}) => theme.radius.xxl};
  flex-direction: row;
  display: flex;
  align-items: center;
  ${({theme}) => theme.shadows.appActionBar.default};
`;

const Firstname = styled.text`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.black};
`;

const RoleText = styled.text`
  ${({theme}) => theme.textStyles.p3};
  color: ${({theme}) => theme.colors.gray8};
  text-transform: capitalize;
`;

const UserInfoContainer = styled.div`
  flex-direction: column;
  display: flex;
  margin-right: ${({theme}) => theme.margins.xs};
`;

const IconContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  margin-right: ${({theme}) => theme.margins.xs};
`;

const RightContainer = styled.div`
  flex-direction: row;
  display: flex;
  /* hover */
  &:hover {
    cursor: pointer;
  }
`;

export const AppActionBar: React.FC = () => {
  // Theme
  const theme = useTheme();

  // Translation
  const {t} = useTranslation();

  // Navigate
  const navigate = useNavigate();

  // Location
  const location = useLocation();

  // Current user
  const {user} = useCurrentUser();

  // Callbacks
  const handleCreateMenu = useCallback(() => {
    navigate(paths.chef.menus.create.pattern, {
      state: {previousLocation: location},
    });
  }, [navigate, location]);

  const handleCreateMeal = useCallback(() => {
    navigate(paths.chef.meals.pattern);
  }, [navigate]);

  const handleOpenMyAccount = useCallback(() => {
    if (user?.roles.includes(Role.ADMIN)) {
      navigate(paths.admin.myAccount.pattern);
    } else if (user?.roles.includes(Role.CHEF)) {
      navigate(paths.chef.myAccount.pattern);
    }
  }, [navigate, user?.roles]);

  return (
    <ActionHeaderContainer>
      <Space size={parseInt(theme.paddings.lg, 10)}>
        {user?.roles.includes(Role.CHEF) && (
          <>
            <Button icon={<PlusIcon />} onClick={handleCreateMeal}>
              {t('buttons.create-meal')}
            </Button>

            <Button
              type="primary"
              icon={<PlusIcon color={theme.colors.white} />}
              onClick={handleCreateMenu}>
              {t('buttons.create-menu')}
            </Button>

            <Divider type="vertical" />
          </>
        )}

        <InitialsAvatar
          firstName={user?.firstName ?? ''}
          lastName={user?.lastName ?? ''}
        />

        <RightContainer onClick={handleOpenMyAccount}>
          <UserInfoContainer>
            <Firstname>{user?.firstName}</Firstname>

            <RoleText>{user?.roles[0].toLocaleLowerCase()}</RoleText>
          </UserInfoContainer>

          <IconContainer>
            <ArrowRightIcon size={30} />
          </IconContainer>
        </RightContainer>
      </Space>
    </ActionHeaderContainer>
  );
};
