import React from 'react';

import styled from 'styled-components';

import {Allergy} from 'src/interfaces/api/generated.interface';
import {AllergenSize} from 'src/interfaces/theme.interface';

import {assetsAllergens} from 'src/constants/allergens';

interface AllergenTagProps extends React.HTMLAttributes<HTMLDivElement> {
  type: Allergy;
  size?: AllergenSize;
}

const _defaultSize: AllergenSize = 'small';

const AllergenContainer = styled.div<Pick<AllergenTagProps, 'size'>>`
  background-color: ${({theme}) => theme.colors.white};
  border-radius: ${({theme}) => theme.radius.lg};
  border-width: ${({theme}) => theme.borders.xs};
  border-color: ${({theme}) => theme.colors.gray4};
  border-style: solid;
  height: ${({theme, size = _defaultSize}) => theme.heights.allergenCircle[size]};
  width: ${({theme, size = _defaultSize}) => theme.heights.allergenCircle[size]};
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const AllergenTag: React.FC<AllergenTagProps> = ({type, size, ...rest}) => {
  // Constants
  const iconSize = size === 'smallest' ? 12 : 14;

  return (
    <AllergenContainer size={size} {...rest}>
      {/* @ts-ignore */}
      {React.cloneElement(assetsAllergens[type], {height: iconSize, width: iconSize})}
    </AllergenContainer>
  );
};
