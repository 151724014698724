import React, {PropsWithChildren, useEffect, useState} from 'react';

import {useSelector} from 'react-redux';
import isEqual from 'lodash.isequal';

import {User} from 'src/interfaces/api/generated.interface';

import {useMeQuery} from 'src/hooks/queries/useMeQuery';
import CurrentUserContext from 'src/providers/CurrentUser/Context';
import {refreshTokenUserSelector} from 'src/redux/selectors/user';

const CurrentUserProvider: React.FC<PropsWithChildren<{}>> = ({children}) => {
  // Selectors
  const refreshToken = useSelector(refreshTokenUserSelector);

  // Query
  const {data, isLoading, ...rest} = useMeQuery({
    // Skip if we don't have refreshToken
    enabled: !!refreshToken,
  });

  // State
  const [user, setUser] = useState<User | undefined | null>(data);

  // Effects
  useEffect(() => {
    if (!isEqual(user, data)) {
      setUser(data);
    }
  }, [data, user]);

  return (
    <CurrentUserContext.Provider value={{user, isLoading}} {...rest}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export default CurrentUserProvider;
