import React from 'react';

import {Empty, Typography} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

const {Text} = Typography;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

interface EmptyDetailsProps extends React.ComponentProps<typeof Empty> {
  details?: string;
}

export const EmptyDetails: React.FC<EmptyDetailsProps> = ({description, details}) => {
  // Translation
  const {t} = useTranslation('common');

  return (
    <Container>
      <Empty
        description={
          description ?? details ? t('errors.unkown') : t('errors.unkown-try-again')
        }
      />

      {details && <Text>{details}</Text>}
    </Container>
  );
};
