import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const MessageChatIcon = ({
  size = 24,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" viewBox="0 0 24 24" {...rest}>
      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M15 19h-4a2 2 0 0 1-2-2v-5.5a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2V17a2 2 0 0 1-2 2h-1v2l-3-2Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M17.609 9.5V5.435A2.435 2.435 0 0 0 15.174 3H5.435A2.435 2.435 0 0 0 3 5.435v6.696a2.435 2.435 0 0 0 2.435 2.435h1.217V17L9 15.435"
      />
    </svg>
  );
};
