import {NoisePollutionValue, WasteValue} from 'src/interfaces/api/generated.interface';

export function convertNoisePollutionValueToNumber(
  noisePollutionValue?: NoisePollutionValue,
) {
  switch (noisePollutionValue) {
    case NoisePollutionValue.VERY_LOW:
      return 40;
    case NoisePollutionValue.LOW:
      return 60;
    case NoisePollutionValue.HIGH:
      return 75;
    case NoisePollutionValue.VERY_HIGH:
      return 90;
    default:
      return 0;
  }
}

export function convertWasteValueToNumber(wasteValue?: WasteValue) {
  switch (wasteValue) {
    case WasteValue.VERY_LOW:
      return 57;
    case WasteValue.LOW:
      return 115;
    case WasteValue.HIGH:
      return 201.5;
    case WasteValue.VERY_HIGH:
      return 300;
    default:
      return undefined;
  }
}

export function getAverageNoisePollutionValue(
  noisePollutionValues: NoisePollutionValue[],
) {
  const noisePollutionValuesAsNumbers: number[] = noisePollutionValues.map(
    convertNoisePollutionValueToNumber,
  );
  const averageNoisePollutionValue =
    noisePollutionValuesAsNumbers.reduce((a, b) => a + b, 0) /
    noisePollutionValuesAsNumbers.length;
  return isNaN(averageNoisePollutionValue) ? undefined : averageNoisePollutionValue;
}

export function getClosestWasteValue(
  waste: number | undefined,
  totalParticipants: number | undefined,
) {
  const wasteValue = waste && totalParticipants ? waste / totalParticipants : undefined;

  if (!wasteValue) {
    return undefined;
  } else if (wasteValue <= 57) {
    return WasteValue.VERY_LOW;
  } else if (wasteValue <= 115) {
    return WasteValue.LOW;
  } else if (wasteValue <= 201.5) {
    return WasteValue.HIGH;
  } else if (wasteValue > 201.5) {
    return WasteValue.VERY_HIGH;
  } else {
    return undefined;
  }
}
