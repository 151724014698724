import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const HamburgerIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width ?? size} height={height ?? size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M15.516 9.829c.934 0 1.632-.76 1.408-1.546-.797-2.782-3.697-4.845-7.152-4.845S3.417 5.5 2.62 8.282c-.224.786.475 1.546 1.408 1.546h11.488ZM14.342 18.438H5.657a3.08 3.08 0 0 1-3.079-3.08.43.43 0 0 1 .431-.43h13.982a.43.43 0 0 1 .43.43c0 1.7-1.378 3.08-3.079 3.08Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M2.578 12.328c.385.27.74.561 1.5.561 1.432 0 1.432-1.022 2.864-1.022s1.432 1.022 2.854 1.022c1.432 0 1.432-1.022 2.864-1.022s1.432 1.022 2.864 1.022c.72 0 1.082-.255 1.442-.51M9.84 6.26h-.068M12.981 7.437h-.068M6.704 7.437h-.068"
      />
    </svg>
  );
};
