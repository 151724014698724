import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {AdminUpdateEntityParams} from 'src/interfaces/api/entity.interface';
import {User} from 'src/interfaces/api/generated.interface';

import {networkClient, queryClient} from 'src/services/client';

export function useAdminUpdateEntityMutation() {
  return useMutation({
    mutationFn: async (params: AdminUpdateEntityParams): Promise<User> => {
      const {data} = await networkClient.put<User>(Routes.AdminEntity, params);

      queryClient.invalidateQueries([Routes.Entities]);
      queryClient.invalidateQueries([Routes.Entity, params.entityId]);
      return data;
    },
  });
}
