import React, {useCallback, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {List, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDebouncedCallback} from 'use-debounce';
import styled, {useTheme} from 'styled-components';

import {Search} from 'src/components/atoms/input/Search';
import {Button} from 'src/components/atoms/button/Button';
import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {MenuCard} from 'src/components/molecules/card/MenuCard';
import {paths} from 'src/navigation/paths';

import {useDeleteMenuMutation} from 'src/hooks/mutations/useDeleteMenuMutation';
import useSnackBar from 'src/hooks/useSnackBar';
import {useMenusQuery} from 'src/hooks/queries/useMenusQuery';
import {settings} from 'src/constants/settings';

const StyledSearch = styled(Search)`
  margin-bottom: ${({theme}) => theme.margins.md};
  margin-right: ${({theme}) => theme.margins.sm};
`;

const InputContainer = styled.div`
  display: block;
  width: 100%;
`;

const StyledButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

export default function MenusPage() {
  // Translation
  const {t} = useTranslation(['common', 'menus-page']);

  // Navigate
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Location
  const location = useLocation();

  // State
  const [search, setSearch] = useState('');

  // Snackbar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Query
  const {data, isLoading} = useMenusQuery({page: 1, search});

  // // Mutation
  const {mutateAsync: deleteMenu, isLoading: isDeleting} = useDeleteMenuMutation();

  // Callbacks
  const handleAddMenu = useCallback(() => {
    navigate(paths.chef.menus.create.pattern, {state: {previousLocation: location}});
  }, [navigate, location]);

  const handleSearch = useDebouncedCallback((value: string) => {
    setSearch(value);
  }, settings.input.debounce);

  const handleEditMenu = useCallback(
    (menuId: number) => {
      navigate(paths.chef.menus.edit({id: menuId}), {
        state: {previousLocation: location},
      });
    },
    [navigate, location],
  );

  const handleDeleteMenu = useCallback(
    async (menuId: number) => {
      try {
        await deleteMenu({menuId});

        showSuccessSnackBar(t('menus-page:success.deleted'));
      } catch (error) {
        showErrorSnackBar(error?.message ?? error);
      }
    },
    [showErrorSnackBar, showSuccessSnackBar, t, deleteMenu],
  );

  const handleOpenMenu = useCallback(
    (dishId: number) => {
      navigate(paths.chef.menus.details({id: dishId}), {
        state: {previousLocation: location},
      });
    },
    [navigate, location],
  );

  return (
    <>
      <Helmet>
        <title>{t('menus-page:helmet')}</title>
      </Helmet>

      <Row wrap={false}>
        <InputContainer>
          <StyledSearch
            placeholder={t('menus-page:placeholders.search')}
            onSearch={handleSearch}
          />
        </InputContainer>

        <StyledButton type="dashed" size="medium">
          {t('menus-page:buttons.filter')}
        </StyledButton>

        <Button
          type="primary"
          size="medium"
          onClick={handleAddMenu}
          icon={<PlusIcon color={theme.colors.white} size={24} />}>
          {t('menus-page:buttons.add')}
        </Button>
      </Row>

      <List
        loading={isLoading}
        dataSource={data?.menus ?? []}
        renderItem={item => (
          <MenuCard
            id={item.id}
            name={item.name}
            type={item.type}
            onClick={handleOpenMenu}
            onDelete={handleDeleteMenu}
            onEdit={handleEditMenu}
          />
        )}
      />
    </>
  );
}
