import {DishRate} from 'src/interfaces/api/generated.interface';
import {DishRateCriteria} from 'src/interfaces/dish-rates.interface';

export function getDishRatesByCriteria(
  dishRates: DishRate[],
  criteria: DishRateCriteria,
) {
  const reviews: {reason: string; nbVotes: number; total: number}[] = [];

  // Multiply by 2 to have a rating between 0 and 10
  const averageRating =
    (dishRates.reduce((acc, rate) => acc + rate[criteria], 0) * 2) / dishRates.length;

  for (const rate of dishRates) {
    if (rate[criteria] && rate[`${criteria}Reason`]) {
      // Search if the review already exists
      const review = reviews.find(review => review.reason === rate[`${criteria}Reason`]);

      if (review) {
        // Update the review
        review.nbVotes += 1;
      } else {
        // Create a new review
        reviews.push({
          reason: rate[`${criteria}Reason`] ?? '',
          nbVotes: 1,
          total: dishRates.length,
        });
      }
    }
  }

  return {averageRating, reviews};
}
