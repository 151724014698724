import React, {useCallback, useMemo, useState} from 'react';

import styled from 'styled-components';
import moment from 'moment';
import {Switch, Button as AntButton, Col, List, Select, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {useDebouncedCallback} from 'use-debounce';

import {WeekMealForm} from 'src/interfaces/meal.interface';
import {DishType, Menu, MenuType} from 'src/interfaces/api/generated.interface';

import {Separator} from 'src/components/atoms/separator/Separator';
import {Button} from 'src/components/atoms/button/Button';
import {ArrowDownIcon} from 'src/components/atoms/icon/ArrowDownIcon';
import {MenuSectionsMenu} from 'src/components/molecules/menu/MenuSectionsMenu';
import {SmallDishCard} from 'src/components/molecules/card/SmallDishCard';
import {paths} from 'src/navigation/paths';

import {useMenuQuery} from 'src/hooks/queries/useMenuQuery';
import {useMenusQuery} from 'src/hooks/queries/useMenusQuery';
import {settings} from 'src/constants/settings';

const WeekItemContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const WeekItemContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({theme}) => theme.paddings.lg} 0px;
  justify-content: space-between;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Date = styled.text`
  ${({theme}) => theme.textStyles.p1};
  color: ${({theme}) => theme.colors.gray12};
`;

const SwitchText = styled.text`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
  margin-right: ${({theme}) => theme.margins.md};
`;

const MenuName = styled.text`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
  margin-right: ${({theme}) => theme.margins.md};
`;

const ArrowButton = styled(AntButton)<{isOpen: boolean}>`
  rotate: ${({isOpen}) => (isOpen ? '0deg' : '-90deg')};
`;

const SectionDishesContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-left: ${({theme}) => theme.margins.sm};
  overflow: scroll;
`;

const ContentContainer = styled.div<{isOpen: boolean}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({isOpen, theme}) =>
    isOpen
      ? parseInt(theme.heights.mealForm.default, 10) + parseInt(theme.margins.sm, 10)
      : 0}px;
  overflow: hidden;
  transition: all 0.3s ease-out;
  background-color: ${({theme}) => theme.colors.white};
  ${({theme}) => theme.shadows.menuPicker.default};
  border-radius: ${({theme}) => theme.radius.lg};
  padding: ${({theme, isOpen}) => (isOpen ? theme.paddings.lg : '0px')};
  margin-bottom: ${({theme, isOpen}) => (isOpen ? theme.margins.sm : '0px')};
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${({theme}) =>
    parseInt(theme.heights.menuForm.small, 10) + parseInt(theme.margins.sm, 10)}px;
  overflow: hidden;
  transition: all 0.2s ease-out;
`;

const SelectContainer = styled.div`
  display: block;
  width: 35%;
  margin-right: ${({theme}) => theme.margins.sm};
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledSeparator = styled(Separator)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

const StyledSmallDishCard = styled(SmallDishCard)`
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

interface MealFormProps {
  item: WeekMealForm;
  isLast: boolean;
  published: boolean | undefined;
  onPublicHolidayChange: (value: boolean) => void;
  onMenuChange: (menu: Menu | undefined) => void;
}

export const MealForm: React.FC<MealFormProps> = ({
  item: {menu, date, publicHoliday},
  isLast,
  published,
  onPublicHolidayChange,
  onMenuChange,
}) => {
  // Translation
  const {t} = useTranslation('meals-page');

  // Navigation
  const navigate = useNavigate();

  // Location
  const location = useLocation();

  // State
  const [open, setOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<DishType>(DishType.STARTER);
  const [searchMenu, setSearchMenu] = useState('');

  // Queries
  const {data, isLoading} = useMenusQuery({search: searchMenu});
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const {data: menuDetails} = useMenuQuery({menuId: menu?.id!}, {enabled: !!menu?.id});

  // Constants
  const menus = useMemo(
    () =>
      data?.menus ? data.menus.map(item => ({value: item.id, label: item.name})) : [],
    [data],
  );

  // Constants
  const sectionDishes = useMemo(
    () =>
      menu?.id
        ? menuDetails?.dishes.filter(
            dish =>
              dish.type === activeSection ||
              (dish.type === DishType.DAIRY_PRODUCT &&
                menuDetails.type === MenuType.FOUR_DISHES &&
                activeSection === DishType.DESSERT),
          )
        : undefined,
    [activeSection, menuDetails, menu?.id],
  );

  // Callbacks
  const handleSearchMenu = useDebouncedCallback((value: string) => {
    setSearchMenu(value);
  }, settings.input.debounce);

  const handleEditMenu = useCallback(() => {
    // Reset the menu to reflect edition
    onMenuChange(undefined);

    navigate(paths.chef.menus.edit({id: menu?.id}), {
      state: {previousLocation: location},
    });
  }, [navigate, onMenuChange, menu?.id, location]);

  const handleMenuSelected = useCallback(
    (menuId: number) => {
      const newMenu = data?.menus.find(item => item.id === menuId);
      if (newMenu) {
        onMenuChange(newMenu);
      }
    },
    [data?.menus, onMenuChange],
  );

  return (
    <WeekItemContainer>
      <WeekItemContentContainer>
        <Date>{moment(date).format('dddd D MMMM YYYY')}</Date>

        <RightContainer>
          {menu && <MenuName>{t('menu-name', {name: menu?.name})}</MenuName>}

          <Switch
            onChange={onPublicHolidayChange}
            checked={publicHoliday}
            disabled={published}
          />

          <SwitchText>{t('public-holiday')}</SwitchText>

          <ArrowButton
            shape="circle"
            type="dashed"
            icon={<ArrowDownIcon />}
            onClick={() => setOpen(!open)}
            isOpen={open}
          />
        </RightContainer>
      </WeekItemContentContainer>

      <ContentContainer isOpen={open}>
        <Row>
          <SelectContainer>
            <StyledSelect
              placeholder={t('placeholders.menu')}
              showSearch
              onSearch={handleSearchMenu}
              onSelect={handleMenuSelected}
              filterOption={false}
              loading={isLoading}
              options={menus}
              value={menu?.id}
              defaultValue={menu?.id}
              disabled={published}
            />
          </SelectContainer>

          <Button
            type="primary"
            size="medium"
            onClick={handleEditMenu}
            disabled={published || !menu}>
            {t('buttons.edit-menu')}
          </Button>
        </Row>

        <StyledSeparator />

        <MenuContainer>
          <MenuSectionsMenu
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            menuType={menuDetails?.type}
            span={7}
          />

          <SectionDishesContainer span={17}>
            <List
              locale={{emptyText: t('errors.select-menu')}}
              dataSource={sectionDishes}
              renderItem={item => (
                <StyledSmallDishCard
                  image={item?.image?.url}
                  name={item.name}
                  id={item.id}
                  allergens={item.allergens}
                />
              )}
            />
          </SectionDishesContainer>
        </MenuContainer>
      </ContentContainer>

      {!isLast && <Separator />}
    </WeekItemContainer>
  );
};
