import React from 'react';

import styled from 'styled-components';

import {Color} from 'src/interfaces/theme.interface';

const _defaultBgColor: Color = 'primary2';
const _defaultTextColor: Color = 'primary5';

const Container = styled.div<Pick<InfoTagProps, 'bgColor' | 'borderless'>>`
  background-color: ${({theme, bgColor = _defaultBgColor}) => theme.colors[bgColor]};
  border-radius: ${({theme}) => theme.radius.max};
  padding: ${({theme}) => theme.margins.xxxs} ${({theme}) => theme.paddings.sm};
  justify-content: center;
  align-items: center;
  border: ${({theme, borderless}) => (borderless ? theme.borders.null : theme.borders.xs)}
    solid ${({theme}) => theme.colors.gray4};
`;

const Text = styled.span<Pick<InfoTagProps, 'textColor'>>`
  ${({theme}) => theme.textStyles.p2};
  color: ${({theme, textColor = _defaultTextColor}) => theme.colors[textColor]};
`;

interface InfoTagProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  bgColor?: Color;
  textColor?: Color;
  borderless?: boolean;
}

export const InfoTag: React.FC<InfoTagProps> = ({
  value,
  bgColor,
  textColor,
  borderless = true,
  ...rest
}) => {
  return (
    <Container bgColor={bgColor} borderless={borderless} {...rest}>
      <Text textColor={textColor}>{value}</Text>
    </Container>
  );
};
