import {useMemo, useContext} from 'react';

import {Role} from 'src/interfaces/api/generated.interface';

import CurrentUserContext from 'src/providers/CurrentUser/Context';

export default function useCurrentUser() {
  // Current user context
  const {user, isLoading} = useContext(CurrentUserContext);

  // Constants
  const isRetrieved = useMemo(() => !!user, [user]);

  return {
    user,
    isRetrieved,
    isLoading,
    isAdmin: user?.roles.includes(Role.ADMIN),
    isChef: user?.roles.includes(Role.CHEF),
  };
}
