import {RcFile, UploadFile} from 'antd/es/upload';

import {PaginationResponse} from 'src/interfaces/api/api.interface';
import {
  Allergy,
  Dish,
  DishType,
  File,
  NutritionalScoreValue,
} from 'src/interfaces/api/generated.interface';

export interface GetDishes {
  publicDishes?: boolean;
  page?: number;
  search?: string;
  types?: DishType[];
}

export interface GetDishesResponse extends PaginationResponse {
  dishes: (Dish & {
    image: File;
    entity: {
      public: boolean;
    };
    nutritionalScore: {
      id: number;
      createdAt: Date;
      calories: NutritionalScoreValue;
      benefits: NutritionalScoreValue;
      carbonFootprint: NutritionalScoreValue;
      transformation: NutritionalScoreValue;
    };
    foods: {
      image: {
        id: number;
        createdAt: Date;
        name: string;
        url: string;
        key: string;
        dishDocumentId: number;
      };
    }[];
  })[];
}

export interface CreateDishMutation {
  name: string;
  type: DishType;
  calories: NutritionalScoreValue;
  carbonFootprint: NutritionalScoreValue;
  transformation: NutritionalScoreValue;
  benefits: NutritionalScoreValue;
  foodIds: number[];
  allergens: Allergy[];
}

export interface UploadDishImage {
  dishId: number;
  image: RcFile;
}

export interface CreateDishInfo {
  image: {file: UploadFile};
  documents: {file: UploadFile; fileList: UploadFile[]};
  name: string;
  type: DishType;
}

export interface CreateDishIngredients {
  foodIds: number[];
}

export interface CreateDishAllergens {
  allergens: Allergy[];
}

export interface CreateDishNutritionalScore {
  calories: NutritionalScoreValue;
  carbonFootprint: NutritionalScoreValue;
  transformation: NutritionalScoreValue;
  benefits: NutritionalScoreValue;
}

export interface CreateDishValues
  extends Partial<CreateDishInfo>,
    Partial<CreateDishIngredients>,
    Partial<CreateDishAllergens>,
    Partial<CreateDishNutritionalScore> {}

export interface GetDish {
  dishId: number;
}

export type GetDishResponse = Dish & {image: File; documents: File[]};

export interface UploadDishDocuments {
  documents: RcFile[];
  dishId: number;
}

export interface DeleteDishDocument {
  dishId: number;
  documentId: number;
  key: string;
}

export interface DeleteDishImage {
  dishId: number;
  imageId: number;
  key: string;
}

export interface UpdateDish {
  name: string;
  type: DishType;
  calories: NutritionalScoreValue;
  carbonFootprint: NutritionalScoreValue;
  transformation: NutritionalScoreValue;
  benefits: NutritionalScoreValue;
  foodIds: number[];
  dishId: number;
  allergens: Allergy[];
}

export interface DeleteDish {
  dishId: number;
}

export enum NutritionalScoreType {
  Calories = 'calories',
  Transformation = 'transformation',
  Benefits = 'benefits',
  CarbonFootprint = 'carbon-footprint',
}

export interface NutritionalScoreForm {
  question1: null | NutritionalScoreValue;
  question2: null | NutritionalScoreValue;
  question3: null | NutritionalScoreValue;
}

export interface NutritionalScoreNumbers {
  calories: number;
  carbonFootprint: number;
  transformation: number;
  benefits: number;
}

export interface NutritionalScoreValues {
  calories: NutritionalScoreValue;
  carbonFootprint: NutritionalScoreValue;
  transformation: NutritionalScoreValue;
  benefits: NutritionalScoreValue;
}
