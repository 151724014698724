import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const CutleryIcon = ({
  width = 20,
  height = 21,
  size,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={width ?? size} height={height ?? size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M15.278 3.625c-.001-.436-.266-.711-.617-.6-.22.07-2.816 1.81-2.513 8.053.05.648.33 1.085.813 1.419.474.326.598.738.552 1.288-.108 1.299-.183 1.811-.256 3.114-.036.656.448 1.134 1.082 1.1.548-.03.938-.479.939-1.102.003-2.377.003-11.858 0-13.272Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M9.736 3.312c.054 1.217.113 2.433.162 3.65.032.788-.238 1.466-.829 1.99-.807.714-1.006 1.593-.896 2.637.2 1.925.348 3.066.485 4.997.055.775-.641 1.414-1.347 1.414-.707 0-1.404-.639-1.349-1.414.138-1.93.285-3.072.486-4.997.11-1.044-.09-1.923-.897-2.638-.59-.523-.86-1.2-.828-1.989.048-1.217.107-2.433.162-3.65M7.306 6.263l.008-2.887"
      />
    </svg>
  );
};
