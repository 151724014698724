import React, {useCallback, useEffect, useState} from 'react';

import {Divider, Form, Row} from 'antd';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';

import {Dish, MenuType} from 'src/interfaces/api/generated.interface';

import {Input} from 'src/components/atoms/input/Input';
import {MenuDishPickerForm} from 'src/components/organisms/form/MenuDishPickerForm';
import {Mark} from 'src/components/atoms/mark/Mark';
import {Button} from 'src/components/atoms/button/Button';

import {hasDishesForAllSections} from 'src/utils/menu';

const StyledFormItemMargin = styled(Form.Item)`
  margin-right: ${({theme}) => theme.margins.sm};
  display: block;
  width: 100%;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const MenuTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${({theme}) => theme.colors.beige};
  height: ${({theme}) => theme.heights.foodTabBar.default};
  border-radius: ${({theme}) => theme.radius.md};
  padding: ${({theme}) => theme.paddings.xxxs};
`;

const MenuTypeItem = styled.div<{active: boolean}>`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: ${({theme}) => theme.paddings.md};
  background-color: ${({theme, active}) =>
    active ? theme.colors.primary5 : 'transparent'};
  width: 100%;
  border-radius: ${({theme}) => theme.radius.md};
  margin-right: ${({theme}) => theme.margins.xs};
`;

const MenuTypeName = styled.text<{active: boolean; isLast?: boolean}>`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme, active}) => (active ? theme.colors.white : theme.colors.gray6)};
  margin-left: ${({theme, isLast}) => (isLast ? '0px' : theme.margins.xs)};
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const BackButton = styled(Button)`
  margin-right: ${({theme}) => theme.margins.sm};
`;

const StyledFormItem = styled(Form.Item)`
  display: block;
  width: 100%;
`;

interface MenuFormProps {
  name?: string;
  type?: MenuType;
  dishes?: Dish[];
  isUpdating?: boolean;
  onFinish: (name: string, menuType: MenuType, dishes: Dish[]) => void;
}

export const MenuForm: React.FC<MenuFormProps> = ({
  name,
  type,
  dishes,
  isUpdating,
  onFinish,
}) => {
  // Translation
  const {t} = useTranslation(['create-menu-page', 'common']);

  // State
  const [menuType, setMenuType] = useState(MenuType.FIVE_DISHES);
  const [selectedDishes, setSelectedDishes] = useState<Dish[]>([]);

  // Form
  const [form] = Form.useForm();

  // Effects
  useEffect(() => {
    if (dishes && type && !selectedDishes.length) {
      setSelectedDishes(dishes);
      setMenuType(type);
    }
  }, [form, dishes, name, type, selectedDishes.length]);

  // Callbacks
  const renderMark = useCallback((label: React.ReactNode, info: {required: boolean}) => {
    return <Mark label={label} info={info} />;
  }, []);

  const handleSelectDish = useCallback(
    (dish: Dish) => {
      if (!selectedDishes.find(selectedDish => selectedDish.id === dish.id)) {
        setSelectedDishes([...selectedDishes, dish]);
      }
    },
    [selectedDishes],
  );

  const handleRemoveDish = useCallback(
    (dishId: number) => {
      setSelectedDishes(selectedDishes.filter(dish => dish.id !== dishId));
    },
    [selectedDishes],
  );

  const handleFinish = useCallback(async () => {
    try {
      await form.validateFields();
      onFinish(form.getFieldValue('name'), menuType, selectedDishes);
    } catch (error) {}
  }, [selectedDishes, form, menuType, onFinish]);

  return (
    <Form form={form} layout="vertical" requiredMark={renderMark}>
      <Row wrap={false}>
        <StyledFormItemMargin
          label={t('create-menu-page:fields.name')}
          name="name"
          initialValue={name}
          rules={[
            {
              required: true,
              message: t('create-menu-page:errors.name-required'),
            },
          ]}>
          <StyledInput
            defaultValue={name}
            placeholder={t('create-menu-page:placeholders.name')}
          />
        </StyledFormItemMargin>

        <StyledFormItemMargin
          label={t('create-menu-page:fields.menu-type')}
          name="menuType"
          initialValue={menuType}
          rules={[
            {
              required: true,
            },
          ]}>
          <MenuTypeContainer>
            <MenuTypeItem
              active={menuType === MenuType.FIVE_DISHES}
              onClick={() => setMenuType(MenuType.FIVE_DISHES)}>
              <MenuTypeName active={menuType === MenuType.FIVE_DISHES}>
                {t('common:menu-type.five-dishes')}
              </MenuTypeName>
            </MenuTypeItem>

            <MenuTypeItem
              active={menuType === MenuType.FOUR_DISHES}
              onClick={() => setMenuType(MenuType.FOUR_DISHES)}>
              <MenuTypeName active={menuType === MenuType.FOUR_DISHES} isLast={true}>
                {t('common:menu-type.four-dishes')}
              </MenuTypeName>
            </MenuTypeItem>
          </MenuTypeContainer>
        </StyledFormItemMargin>
      </Row>

      <MenuDishPickerForm
        menuType={menuType}
        selectedDishes={selectedDishes}
        onSelectDish={handleSelectDish}
        onRemoveDish={handleRemoveDish}
      />

      <Divider />

      <FooterContainer>
        <BackButton block>{t('common:buttons.back')}</BackButton>

        <StyledFormItem>
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={!hasDishesForAllSections(menuType, selectedDishes)}
            onClick={handleFinish}>
            {isUpdating ? t('common:buttons.edit') : t('common:buttons.create')}
          </Button>
        </StyledFormItem>
      </FooterContainer>
    </Form>
  );
};
