import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {InviteToEntity} from 'src/interfaces/api/entity.interface';
import {Error, Routes} from 'src/interfaces/api/api.interface';
import {Entity} from 'src/interfaces/api/generated.interface';

import {networkClient} from 'src/services/client';

export function useInviteToEntityMutation() {
  return useMutation<Entity, AxiosError<Error>, InviteToEntity>({
    mutationFn: async (params: InviteToEntity): Promise<Entity> => {
      const {data} = await networkClient.post<Entity>(Routes.AdminEntityInvite, params);

      return data;
    },
  });
}
