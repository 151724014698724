import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {GetFood, GetFoodResponse} from 'src/interfaces/api/food.interface';

import {networkClient} from 'src/services/client';

export function useFoodQuery(
  {foodId}: GetFood,
  options?: UseQueryOptions<GetFoodResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.Food, foodId],
    queryFn: async (): Promise<GetFoodResponse | null> => {
      const {data} = await networkClient.get(Routes.Food, {params: {foodId}});

      return data;
    },
    ...options,
  });
}
