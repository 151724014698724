import React, {useMemo, useRef} from 'react';

import moment from 'moment';
import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import styled, {useTheme} from 'styled-components';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {Divider, Row} from 'antd';

import {TruckIcon} from 'src/components/atoms/icon/TruckIcon';
import {NoiseIcon} from 'src/components/atoms/icon/NoiseIcon';

import {useLastWeatherReportsQuery} from 'src/hooks/queries/useLastWeatherReportsQuery';
import {
  convertNoisePollutionValueToNumber,
  convertWasteValueToNumber,
  getAverageNoisePollutionValue,
} from 'src/utils/challenges';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.h5};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const StyledResponsiveContainer = styled(ResponsiveContainer)`
  margin: ${({theme}) => theme.margins.md} 0;
`;

export default function ChallengesPage() {
  // Translation
  const {t} = useTranslation('challenges-page');

  // Theme
  const theme = useTheme();

  const today = useRef(moment().toDate());

  // Query
  const {data} = useLastWeatherReportsQuery({
    from: moment(today.current).subtract(90, 'days').toDate(),
    to: today.current,
  });

  // Constants
  const noisePollutionData = useMemo(
    () =>
      data
        ?.filter(weatherReport => weatherReport.noisePollutionValue)
        ?.map(weatherReport => ({
          date: moment(weatherReport.meal.date).format('ddd DD/MM'),
          value1: convertNoisePollutionValueToNumber(weatherReport.noisePollutionValue),
          value2: getAverageNoisePollutionValue(
            weatherReport.meal.noisePollutionRates.map(value => value.rate),
          ),
        })),
    [data],
  );

  const foodWasteData = useMemo(
    () =>
      data
        ?.filter(
          weatherReport =>
            weatherReport.wasteValue ||
            (weatherReport.totalWaste && weatherReport.totalParticipants),
        )
        ?.map(weatherReport => ({
          date: moment(weatherReport.meal.date).format('ddd DD/MM'),
          value1:
            weatherReport.totalWaste && weatherReport.totalParticipants
              ? Math.round(weatherReport.totalWaste / weatherReport.totalParticipants)
              : undefined,
          value2: convertWasteValueToNumber(weatherReport.wasteValue),
        })),
    [data],
  );

  return (
    <Container>
      <Helmet>
        <title>{t('helmet')}</title>
      </Helmet>

      <Row align="middle">
        <TruckIcon color={theme.colors.primary5} />

        <Title>{t('food-waste.title')}</Title>
      </Row>

      <StyledResponsiveContainer
        width="100%"
        height={parseInt(theme.heights.chartChallenges.default, 10)}>
        <ComposedChart data={foodWasteData ?? []}>
          <CartesianGrid vertical={false} strokeDasharray="3 5" />

          <XAxis type="category" dataKey="date" />

          <YAxis type="number" name={t('food-waste.unit')} unit={t('food-waste.unit')} />

          <Tooltip cursor={{strokeDasharray: '3 5'}} />

          <Legend />

          <Scatter
            name={t('food-waste.legend.with-scale')}
            fill={theme.colors.purple3}
            dataKey="value1"
          />

          <Scatter
            name={t('food-waste.legend.without-scale')}
            fill={theme.colors.yellow3}
            dataKey="value2"
          />
        </ComposedChart>
      </StyledResponsiveContainer>

      <Divider />

      <Row align="middle">
        <NoiseIcon color={theme.colors.primary5} />

        <Title>{t('noise-pollution.title')}</Title>
      </Row>

      <StyledResponsiveContainer
        width="100%"
        height={parseInt(theme.heights.chartChallenges.default, 10)}>
        <ComposedChart data={noisePollutionData ?? []}>
          <CartesianGrid vertical={false} strokeDasharray="3 5" />

          <XAxis type="category" dataKey="date" name="date" />

          <YAxis
            type="number"
            name={t('noise-pollution.unit')}
            unit={t('noise-pollution.unit')}
          />

          <Tooltip cursor={{strokeDasharray: '3 5'}} />

          <Legend />

          <Scatter
            name={t('noise-pollution.legend.my-rate')}
            fill={theme.colors.rose3}
            dataKey="value1"
          />

          <Scatter
            name={t('noise-pollution.legend.students-rate')}
            fill={theme.colors.green6}
            dataKey="value2"
          />
        </ComposedChart>
      </StyledResponsiveContainer>
    </Container>
  );
}
