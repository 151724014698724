import {Dish, DishType, MenuType} from 'src/interfaces/api/generated.interface';
import {GetMenuDishesType} from 'src/interfaces/api/menu.interface';

export function countMenuDishesByType(
  dishes: Dish[] | undefined,
  dishType: DishType,
): number {
  if (!dishes) {
    return 0;
  }

  return dishes.filter(dish => dish.type === dishType).length;
}

export function getMenuDishesByType(
  dishes: Dish[] | undefined,
  dishType: DishType,
  menuType?: MenuType,
): Dish[] {
  if (!dishes) {
    return [];
  }

  if (
    menuType === MenuType.FOUR_DISHES &&
    (dishType === DishType.DAIRY_PRODUCT || dishType === DishType.DESSERT)
  ) {
    return dishes.filter(
      dish => dish.type === DishType.DAIRY_PRODUCT || dish.type === DishType.DESSERT,
    );
  }

  return dishes.filter(dish => dish.type === dishType);
}

export function getMenuDishesByTypes(dishes: Dish[] | undefined): GetMenuDishesType {
  return {
    starters: getMenuDishesByType(dishes, DishType.STARTER),
    proteinDishes: getMenuDishesByType(dishes, DishType.PROTEIN_DISH),
    sideDishes: getMenuDishesByType(dishes, DishType.SIDE_DISH),
    mixedDishes: getMenuDishesByType(dishes, DishType.MIXED_DISH),
    dairyProducts: getMenuDishesByType(dishes, DishType.DAIRY_PRODUCT),
    desserts: getMenuDishesByType(dishes, DishType.DESSERT),
  };
}

export function hasDishesForAllSections(type: MenuType, dishes: Dish[]): boolean {
  if (!dishes.length || dishes.length < 4) {
    return false;
  }
  if (type === MenuType.FIVE_DISHES) {
    return (
      dishes.filter(dish => dish.type === DishType.STARTER).length > 0 &&
      (dishes.filter(dish => dish.type === DishType.PROTEIN_DISH).length > 0 ||
        dishes.filter(dish => dish.type === DishType.MIXED_DISH).length > 0) &&
      (dishes.filter(dish => dish.type === DishType.SIDE_DISH).length > 0 ||
        dishes.filter(dish => dish.type === DishType.MIXED_DISH).length > 0) &&
      dishes.filter(dish => dish.type === DishType.DAIRY_PRODUCT).length > 0 &&
      dishes.filter(dish => dish.type === DishType.DESSERT).length > 0
    );
  } else {
    return (
      dishes.filter(dish => dish.type === DishType.STARTER).length > 0 &&
      (dishes.filter(dish => dish.type === DishType.PROTEIN_DISH).length > 0 ||
        dishes.filter(dish => dish.type === DishType.MIXED_DISH).length > 0) &&
      (dishes.filter(dish => dish.type === DishType.SIDE_DISH).length > 0 ||
        dishes.filter(dish => dish.type === DishType.MIXED_DISH).length > 0) &&
      (dishes.filter(dish => dish.type === DishType.DESSERT).length > 0 ||
        dishes.filter(dish => dish.type === DishType.DAIRY_PRODUCT).length > 0)
    );
  }
}
