export const shadows = {
  menuPicker: {
    default: `
    box-shadow: 0px 4px 20px 0px #EEEEEE80;
  `,
  },
  monthYearPicker: {
    default: `
    box-shadow: 0px 4px 20px 0px #EEEEEE80;
  `,
  },
  appActionBar: {
    default: `
    box-shadow: 0px 4px 20px 0px #BCBDC13D;
    `,
  },
  content: {
    default: `
    box-shadow: 0px 4px 20px 0px #BCBDC13D;
  `,
  },
  sider: {
    default: `
    box-shadow: 0px 4px 20px 0px #EEEEEE80;
    `,
  },
};
