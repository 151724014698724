import {createSyncStoragePersister} from '@tanstack/query-sync-storage-persister';
import {QueryClient} from '@tanstack/react-query';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';

import {REACT_APP_API_URL} from 'src/constants/api';
import {userActions} from 'src/redux/slices/user';
import store from 'src/redux/store';

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

let isRefreshingToken = false;

axios.defaults.baseURL = REACT_APP_API_URL;

axios.interceptors.request.use(config => {
  console.info('[NETWORK]: Sending request - ', config.url);
  if (
    config.url !== Routes.RefreshToken &&
    config.url !== Routes.SignUpChef &&
    config.url !== Routes.SignIn
  ) {
    config.headers.Authorization = `Bearer ${store.getState().user.accessToken}`;
  }
  return config;
}, undefined);

const handleRefreshToken = async (
  error: AxiosError<Error>,
  onSuccess: (
    error?: AxiosError<Error>,
  ) => void | Promise<AxiosResponse<unknown, unknown>>,
) => {
  try {
    if (!store.getState().user.refreshToken) {
      store.dispatch(userActions.signOut());
      queryClient.removeQueries();
      return Promise.reject(
        error?.response?.data ? error.response.data.message : error?.message,
      );
    }
    const res = await networkClient.get(Routes.RefreshToken, {
      headers: {
        Authorization: `Bearer ${store.getState().user.refreshToken}`,
      },
    });

    if (res?.data?.accessToken && res?.data?.refreshToken) {
      store.dispatch(userActions.setRefreshToken(res.data.refreshToken));
      store.dispatch(userActions.setAccessToken(res.data.accessToken));
      console.log('[NETWORK]: Refresh Token Success, retrying original request');
      isRefreshingToken = false;
      return onSuccess(error);
    }
  } catch (refreshTokenError) {
    console.log('[NETWORK]: Refresh Token Error :' + refreshTokenError);
    store.dispatch(userActions.signOut());
    queryClient.removeQueries();
    isRefreshingToken = false;
    return Promise.reject(refreshTokenError);
  }
};

// TODO: Handle sign in success but profile failed (seems to redirect to onboarding when it should stay on login)
// TODO: Make sure refresh token fail will logout and change screens (because of cache it might not work)
axios.interceptors.response.use(
  (value: AxiosResponse<unknown, unknown>) => {
    console.info('[NETWORK]: Request success - ' + value.config?.url);
    return value;
  },
  (error: AxiosError<Error>) => {
    console.log(
      `[NETWORK]: Error interceptor for request ${error.config?.url} :` + error.message,
    );
    if (error?.response?.data) {
      console.log(
        `[NETWORK]: Error details for ${error.config?.url} :` +
          JSON.stringify(error.response?.data),
      );
    } else {
      console.log(
        `[NETWORK]: Full Error details for ${error.config?.url} :` +
          JSON.stringify(error),
      );
    }

    if (
      (error?.status === 401 || error?.response?.status === 401) &&
      error.config?.url !== Routes.SignIn &&
      (error.response?.data?.message === 'Unauthorized' ||
        error.message === 'Unauthorized') &&
      isRefreshingToken === false
    ) {
      isRefreshingToken = true;
      handleRefreshToken(error, err => {
        if (err?.config && err?.config?.url !== Routes.RefreshToken) {
          // Update header and retry original request
          err.config.headers.Authorization = `Bearer ${
            store.getState().user.accessToken
          }`;
          err.config.baseURL = undefined;

          return axios.request(err.config);
        }
      });
    }
    return Promise.reject(
      error?.response && error?.response?.data
        ? error.response.data.message
        : error?.message,
    );
  },
);

export const networkClient = axios;
