import {CustomDetector} from 'i18next-browser-languagedetector';

import {localeAppSelector} from 'src/redux/selectors/app';
import {appActions} from 'src/redux/slices/app';
import store from 'src/redux/store';

export const languageReduxDetector: CustomDetector = {
  name: 'redux',
  lookup: () => {
    const state = store.getState();
    const locale = localeAppSelector(state);

    return locale as string;
  },
  cacheUserLanguage: (langKey: string) => {
    const state = store.getState();
    const locale = localeAppSelector(state);

    if (locale !== langKey) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      store.dispatch<any>(
        appActions.setLocale(langKey as `${string}-${string}`),
      );
    }
  },
};
