import {
  NutritionalScoreForm,
  NutritionalScoreNumbers,
  NutritionalScoreType,
  NutritionalScoreValues,
} from 'src/interfaces/api/dish.interface';
import {NutritionalScoreValue} from 'src/interfaces/api/generated.interface';

export function nutritionalScoreValueToNumber(value: NutritionalScoreValue | null) {
  switch (value) {
    case NutritionalScoreValue.LOW:
      return 1;
    case NutritionalScoreValue.MEDIUM:
      return 2;
    case NutritionalScoreValue.HIGH:
      return 3;
    default:
      return 0;
  }
}

export function calculateNutritionalScore(nutritionalResults: {
  [key in NutritionalScoreType]: NutritionalScoreForm;
}): NutritionalScoreNumbers {
  return {
    calories:
      (nutritionalScoreValueToNumber(
        nutritionalResults[NutritionalScoreType.Calories].question1,
      ) +
        nutritionalScoreValueToNumber(
          nutritionalResults[NutritionalScoreType.Calories].question2,
        ) +
        nutritionalScoreValueToNumber(
          nutritionalResults[NutritionalScoreType.Calories].question3,
        )) /
      3,
    carbonFootprint:
      (nutritionalScoreValueToNumber(
        nutritionalResults[NutritionalScoreType.CarbonFootprint].question1,
      ) +
        nutritionalScoreValueToNumber(
          nutritionalResults[NutritionalScoreType.CarbonFootprint].question2,
        ) +
        nutritionalScoreValueToNumber(
          nutritionalResults[NutritionalScoreType.CarbonFootprint].question3,
        )) /
      3,
    transformation:
      (nutritionalScoreValueToNumber(
        nutritionalResults[NutritionalScoreType.Transformation].question1,
      ) +
        nutritionalScoreValueToNumber(
          nutritionalResults[NutritionalScoreType.Transformation].question2,
        ) +
        nutritionalScoreValueToNumber(
          nutritionalResults[NutritionalScoreType.Transformation].question3,
        )) /
      3,
    benefits:
      (nutritionalScoreValueToNumber(
        nutritionalResults[NutritionalScoreType.Benefits].question1,
      ) +
        nutritionalScoreValueToNumber(
          nutritionalResults[NutritionalScoreType.Benefits].question2,
        ) +
        nutritionalScoreValueToNumber(
          nutritionalResults[NutritionalScoreType.Benefits].question3,
        )) /
      3,
  };
}

function nutritionalScoreNumberToValue(value: number) {
  if (value <= 1.5) {
    return NutritionalScoreValue.LOW;
  } else if (value <= 2.5) {
    return NutritionalScoreValue.MEDIUM;
  } else {
    return NutritionalScoreValue.HIGH;
  }
}

export function convertNutritionalScoreToValue(
  nutritionalScore: NutritionalScoreNumbers,
): NutritionalScoreValues {
  return {
    calories: nutritionalScoreNumberToValue(nutritionalScore.calories),
    carbonFootprint: nutritionalScoreNumberToValue(nutritionalScore.carbonFootprint),
    transformation: nutritionalScoreNumberToValue(nutritionalScore.transformation),
    benefits: nutritionalScoreNumberToValue(nutritionalScore.benefits),
  };
}

export function isNutritionalScoreFormCompleted(nutritionalScore: {
  [key in NutritionalScoreType]: NutritionalScoreForm;
}) {
  if (
    nutritionalScore[NutritionalScoreType.Calories].question1 === null ||
    nutritionalScore[NutritionalScoreType.Calories].question2 === null ||
    nutritionalScore[NutritionalScoreType.Calories].question3 === null ||
    nutritionalScore[NutritionalScoreType.CarbonFootprint].question1 === null ||
    nutritionalScore[NutritionalScoreType.CarbonFootprint].question2 === null ||
    nutritionalScore[NutritionalScoreType.CarbonFootprint].question3 === null ||
    nutritionalScore[NutritionalScoreType.Transformation].question1 === null ||
    nutritionalScore[NutritionalScoreType.Transformation].question2 === null ||
    nutritionalScore[NutritionalScoreType.Transformation].question3 === null ||
    nutritionalScore[NutritionalScoreType.Benefits].question1 === null ||
    nutritionalScore[NutritionalScoreType.Benefits].question2 === null ||
    nutritionalScore[NutritionalScoreType.Benefits].question3 === null
  ) {
    return false;
  }
  return true;
}
