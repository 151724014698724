import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {Meal} from 'src/interfaces/api/generated.interface';
import {CreateMealParams} from 'src/interfaces/api/meal.interface';

import {networkClient} from 'src/services/client';

export function useCreateMealMutation() {
  return useMutation({
    mutationFn: async (mealData: CreateMealParams): Promise<Meal> => {
      const {data} = await networkClient.post<Meal>(Routes.Meal, mealData);

      return data;
    },
  });
}
