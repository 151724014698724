import React, {useCallback, useState} from 'react';

import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {Row, Button as AntButton} from 'antd';

import {
  NutritionalScoreForm,
  NutritionalScoreType,
} from 'src/interfaces/api/dish.interface';
import {NutritionalScoreValue} from 'src/interfaces/api/generated.interface';

import {Button} from 'src/components/atoms/button/Button';
import {ArrowDownIcon} from 'src/components/atoms/icon/ArrowDownIcon';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({theme}) => theme.paddings.lg};
  border: 1px solid ${({theme}) => theme.colors.gray4};
  border-radius: ${({theme}) => theme.radius.lg};
`;

const HeaderRow = styled(Row)`
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  ${({theme}) => theme.textStyles.p1Bold};
  color: ${({theme}) => theme.colors.gray12};
  margin: ${({theme}) => theme.margins.sm} 0;
`;

const StyledArrowDownIcon = styled(ArrowDownIcon)<{open: boolean}>`
  rotate: ${({open}) => (open ? '180deg' : '0deg')};
  transition: rotate 0.2s ease-in-out;
`;

const Question = styled.span`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.gray12};
  margin-bottom: ${({theme}) => theme.margins.xs};
`;

const AnswerButton = styled(Button)<{selected?: boolean}>`
  margin-right: ${({theme}) => theme.margins.sm};
  margin-bottom: ${({theme}) => theme.margins.sm};
`;

interface NutritionalScoreItemFormProps extends React.HTMLAttributes<HTMLDivElement> {
  type: NutritionalScoreType;
  values: NutritionalScoreForm;
  onSelectItem(
    type: NutritionalScoreType,
    questionNumber: number,
    value: NutritionalScoreValue,
  ): void;
}

export const NutritionalScoreItemForm: React.FC<NutritionalScoreItemFormProps> = ({
  type,
  values,
  onSelectItem,
  ...rest
}) => {
  // Translation
  const {t} = useTranslation('create-dish-page');

  // State
  const [open, setOpen] = useState(false);

  // Callbacks
  const renderQuestion = useCallback(
    (value: 1 | 2 | 3) => {
      return (
        <>
          <Question>
            {t(`nutritional-intake.${type}.question-${value}.question`)}
          </Question>

          <Row>
            <AnswerButton
              type={
                values[`question${value}`] === NutritionalScoreValue.LOW
                  ? 'primary'
                  : 'dashed'
              }
              onClick={() => onSelectItem(type, value, NutritionalScoreValue.LOW)}>
              {t(`nutritional-intake.${type}.question-${value}.answer-1`)}
            </AnswerButton>

            <AnswerButton
              type={
                values[`question${value}`] === NutritionalScoreValue.MEDIUM
                  ? 'primary'
                  : 'dashed'
              }
              onClick={() => onSelectItem(type, value, NutritionalScoreValue.MEDIUM)}>
              {t(`nutritional-intake.${type}.question-${value}.answer-2`)}
            </AnswerButton>

            <AnswerButton
              type={
                values[`question${value}`] === NutritionalScoreValue.HIGH
                  ? 'primary'
                  : 'dashed'
              }
              onClick={() => onSelectItem(type, value, NutritionalScoreValue.HIGH)}>
              {t(`nutritional-intake.${type}.question-${value}.answer-3`)}
            </AnswerButton>
          </Row>
        </>
      );
    },
    [t, onSelectItem, type, values],
  );

  return (
    <Container {...rest}>
      <HeaderRow>
        <Title>{t(`nutritional-intake.${type}.title`)}</Title>

        <AntButton type="dashed" shape="circle" onClick={() => setOpen(!open)}>
          <StyledArrowDownIcon open={open} />
        </AntButton>
      </HeaderRow>

      {open && ([1, 2, 3] as (1 | 2 | 3)[]).map(renderQuestion)}
    </Container>
  );
};
