export enum EntityStatus {
  Invited = 'invited',
  Registered = 'registered',
}

export interface StatusConfig {
  color: string;
  textColor: string;
}

export interface CreateEntityForm {
  name: string;
  address: string;
  chefs: {firstName: string; lastName: string; email: string}[];
}
