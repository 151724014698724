import React, {useCallback, useEffect, useState} from 'react';

import {Helmet} from 'react-helmet-async';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {Divider, Modal} from 'antd';
import styled, {useTheme} from 'styled-components';

import {Dish, MenuType} from 'src/interfaces/api/generated.interface';
import {CreateMenuForm} from 'src/interfaces/api/menu.interface';

import {PlusIcon} from 'src/components/atoms/icon/PlusIcon';
import {CloseIcon} from 'src/components/atoms/icon/CloseIcon';
import {MenuForm} from 'src/components/organisms/form/MenuForm';
import {MenuConfirmAllergenForm} from 'src/components/organisms/form/MenuConfirmAllergensForm';

import useSnackBar from 'src/hooks/useSnackBar';
import {useCreateMenuMutation} from 'src/hooks/mutations/useCreateMenuMutation';
import {useMenuQuery} from 'src/hooks/queries/useMenuQuery';
import {useUpdateMenuMutation} from 'src/hooks/mutations/useUpdateMenuMutation';

const Title = styled.text`
  ${({theme}) => theme.textStyles.h6};
  color: ${({theme}) => theme.colors.gray12};
  margin-left: ${({theme}) => theme.margins.xs};
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function CreateEditMenuPage() {
  // Translation
  const {t} = useTranslation(['create-menu-page', 'common']);

  // Navigate
  const navigate = useNavigate();

  // Theme
  const theme = useTheme();

  // Extract menuId from URL if exists
  const {id: menuId} = useParams();

  // SnackBar
  const {showErrorSnackBar, showSuccessSnackBar} = useSnackBar();

  // Query
  const {data: menu} = useMenuQuery({menuId: Number(menuId!)}, {enabled: !!menuId});

  // Mutations
  const {mutateAsync: createMenu, isLoading: isCreatingMenu} = useCreateMenuMutation();
  const {mutateAsync: updateMenu, isLoading: isUpdatingMenu} = useUpdateMenuMutation();

  // Ref
  const valuesRef = React.useRef<CreateMenuForm | null>(null);

  // State
  const [hasFinishSelected, setHasFinishSelected] = useState(false);

  // Effects
  useEffect(() => {
    if (menu && !valuesRef.current) {
      valuesRef.current = {
        name: menu.name,
        type: menu.type,
        dishes: menu.dishes,
      };
    }
  }, [menu]);

  // Callbacks
  const handleFinishPicking = useCallback(
    async (name: string, menuType: MenuType, dishes: Dish[]) => {
      // Store values in ref in case user decide to go back and change something
      valuesRef.current = {
        dishes,
        type: menuType,
        name,
      };
      setHasFinishSelected(true);
    },
    [],
  );

  const handleCreateUpdateMenu = useCallback(async () => {
    try {
      if (valuesRef.current) {
        const {name, type, dishes} = valuesRef.current;

        if (menuId) {
          await updateMenu({
            menuId: Number(menuId),
            dishIds: dishes.map(dish => dish.id),
            type,
            name,
          });
          showSuccessSnackBar(t('create-menu-page:success.updated'));
        } else {
          await createMenu({
            dishIds: dishes.map(dish => dish.id),
            type,
            name,
          });
          showSuccessSnackBar(t('create-menu-page:success.created'));
        }

        navigate(-1);
      }
    } catch (error) {
      showErrorSnackBar(error?.message ?? error);
    }
  }, [
    t,
    showErrorSnackBar,
    showSuccessSnackBar,
    navigate,
    createMenu,
    updateMenu,
    menuId,
  ]);

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleGoBack = useCallback(() => {
    setHasFinishSelected(false);
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('create-menu-page:helmet.title')}</title>
      </Helmet>

      <Modal
        open={true}
        onCancel={handleCancel}
        closeIcon={<CloseIcon />}
        width={parseInt(theme.widths.createMenuModal.default, 10)}
        footer={<></>}>
        {!hasFinishSelected && (
          <HeaderContainer>
            <PlusIcon color={theme.colors.primary5} size={24} />

            <Title>
              {!menuId ? t('create-menu-page:title') : t('create-menu-page:title-edit')}
            </Title>
          </HeaderContainer>
        )}

        <Divider />

        {!hasFinishSelected ? (
          <MenuForm
            name={valuesRef.current?.name ?? menu?.name}
            isUpdating={!!menuId}
            type={valuesRef.current?.type}
            dishes={valuesRef.current?.dishes}
            onFinish={handleFinishPicking}
          />
        ) : (
          <MenuConfirmAllergenForm
            isLoading={isCreatingMenu || isUpdatingMenu}
            isUpdating={!!menuId}
            onConfirm={handleCreateUpdateMenu}
            onGoBack={handleGoBack}
          />
        )}
      </Modal>
    </>
  );
}
