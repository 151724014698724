import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {User} from 'src/interfaces/api/generated.interface';

import {networkClient} from 'src/services/client';

export function useMeQuery(options?: UseQueryOptions<User | null, AxiosError<Error>>) {
  return useQuery({
    queryKey: [Routes.Me],
    queryFn: async (): Promise<User | null> => {
      const {data} = await networkClient.get(Routes.Me);

      return data;
    },
    ...options,
  });
}
