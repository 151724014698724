import React, {useCallback, useMemo} from 'react';

import moment from 'moment';
import styled from 'styled-components';
import {Button} from 'antd';

import {ArrowLeftIcon} from 'src/components/atoms/icon/ArrowLeftIcon';
import {ArrowRightIcon} from 'src/components/atoms/icon/ArrowRightIcon';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DayContainer = styled.div<{selected: boolean}>`
  border-radius: ${({theme}) => theme.radius.lg};
  border: 1px solid ${({theme}) => theme.colors.gray4};
  height: ${({theme}) => theme.heights.dayPicker.default};
  width: ${({theme}) => theme.widths.dayPicker.default};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  cursor: pointer;
  background-color: ${({theme, selected}) =>
    selected ? theme.colors.primary5 : 'transparent'};
  transition: background-color 0.5s ease-in-out;
`;

const DayText = styled.span<{selected: boolean}>`
  ${({theme}) => theme.textStyles.h3};
  color: ${({theme, selected}) => (selected ? theme.colors.white : theme.colors.gray12)};
  transition: color 0.5s ease-in-out;
`;

const WeekDayText = styled.span<{selected: boolean}>`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme, selected}) => (selected ? theme.colors.white : theme.colors.gray11)};
  transition: color 0.5s ease-in-out;
`;

interface DayPickerProps {
  selectedDay: Date;
  onChangeDay: (day: Date) => void;
}

export const DayPicker: React.FC<DayPickerProps> = ({selectedDay, onChangeDay}) => {
  // Days
  const days: Date[] = useMemo(() => {
    const startDate = moment(selectedDay).subtract(4, 'days').toDate();

    return Array.from(Array(9)).map((_, index) =>
      moment(startDate).add(index, 'days').toDate(),
    );
  }, [selectedDay]);

  // Callbacks
  const renderDay = useCallback(
    (date: Date) => {
      const isSelected = moment(date).isSame(selectedDay);

      return (
        <DayContainer
          key={date.toString()}
          selected={isSelected}
          onClick={() => onChangeDay(date)}>
          <WeekDayText selected={isSelected}>{moment(date).format('ddd')}</WeekDayText>

          <DayText selected={isSelected}>{moment(date).format('DD')}</DayText>
        </DayContainer>
      );
    },
    [selectedDay, onChangeDay],
  );

  return (
    <Container>
      <Button
        shape="circle"
        type="dashed"
        icon={<ArrowLeftIcon />}
        onClick={() => onChangeDay(moment(selectedDay).subtract(1, 'days').toDate())}
      />

      {days.map(renderDay)}

      <Button
        shape="circle"
        type="dashed"
        icon={<ArrowRightIcon />}
        onClick={() => onChangeDay(moment(selectedDay).add(1, 'days').toDate())}
      />
    </Container>
  );
};
