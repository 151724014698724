import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {User} from 'src/interfaces/api/generated.interface';
import {SignUpChef} from 'src/interfaces/api/user.interface';

import {networkClient} from 'src/services/client';

export function useSignUpChefMutation() {
  return useMutation({
    mutationFn: async (userData: SignUpChef): Promise<User> => {
      const {data} = await networkClient.post(Routes.SignUpChef, userData);
      return data;
    },
  });
}
