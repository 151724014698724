import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const TimeCircleIcon = ({
  size = 20,
  color,
  strokeWidth = 1.5,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M19.25 10A9.25 9.25 0 0 1 10 19.25 9.25 9.25 0 0 1 .75 10 9.25 9.25 0 0 1 10 .75 9.25 9.25 0 0 1 19.25 10Z"
        clipRule="evenodd"
      />

      <path
        stroke={color ?? theme.colors.primary5}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="m13.431 12.943-3.77-2.25V5.848"
      />
    </svg>
  );
};
