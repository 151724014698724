import React from 'react';

import {ConfigProvider} from 'antd';
import {useTheme} from 'styled-components';
import frFR from 'antd/locale/fr_FR';

import {EmptyResults} from 'src/components/atoms/empty/EmptyResults';

const AntConfigProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  // Theme
  const theme = useTheme();

  return (
    <ConfigProvider
      locale={frFR}
      renderEmpty={() => <EmptyResults />}
      theme={{
        token: {
          colorPrimary: theme.colors.primary5,
          borderRadius: parseInt(theme.radius.xs, 10),
          fontFamily: theme.fonts.medium,
          colorText: theme.colors.gray12,
          fontSize: parseInt(theme.fontSizes.text.md, 10),
          colorLink: theme.colors.primary5,
          colorLinkHover: theme.colors.primary5,
        },
        components: {
          Input: {
            colorTextPlaceholder: theme.colors.gray7,
          },
          Menu: {
            activeBarWidth: 3,
            colorBgTextActive: 'transparent',
            itemHoverBg: 'transparent',
            groupTitleColor: theme.colors.black500,
            itemSelectedBg: 'transparent',
          },
          Divider: {
            colorSplit: theme.colors.black300,
          },
          Modal: {
            borderRadiusLG: parseInt(theme.radius.xxl, 10),
          },
          Steps: {
            fontSizeLG: parseInt(theme.fontSizes.text.sm, 10),
            fontFamily: theme.fonts.medium,
          },
          Select: {
            controlHeight: parseInt(theme.heights.input.default, 10),
            borderRadius: parseInt(theme.radius.lg, 10),
          },
          Upload: {
            borderRadiusLG: parseInt(theme.radius.lg, 10),
          },
          Popover: {
            borderRadiusLG: parseInt(theme.radius.lg, 10),
            colorBorder: theme.colors.gray4,
          },
        },
      }}>
      {children}
    </ConfigProvider>
  );
};

export default AntConfigProvider;
