import {useMutation} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {CreateFoodParams} from 'src/interfaces/api/food.interface';
import {Food} from 'src/interfaces/api/generated.interface';

import {networkClient} from 'src/services/client';

export function useCreateFoodMutation() {
  return useMutation<Food, AxiosError<Error>, CreateFoodParams>({
    mutationFn: async (foodData: CreateFoodParams): Promise<Food> => {
      const {data} = await networkClient.post<Food>(Routes.Food, foodData);

      return data;
    },
  });
}
