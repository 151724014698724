import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const TruckIcon = ({
  width = 20,
  height = 21,
  size,
  strokeWidth = 1.5,
  color,
  ...rest
}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg
      width={size ?? width}
      height={size ?? height}
      fill="none"
      viewBox="0 0 20 21"
      {...rest}>
      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M13.031 4.444h-2.558a.667.667 0 0 0-.657.667v6.725a.565.565 0 0 1-.216.453.57.57 0 0 1-.49.106l-6.794-1.961a.59.59 0 0 1-.441-.558V5.904a.577.577 0 0 1 .569-.578h7.372M8.511 15.917c.001.148-.018.295-.059.437-.089.34-.283.64-.553.854-.27.214-.601.33-.942.33-.34 0-.67-.116-.94-.33a1.607 1.607 0 0 1-.555-.854c-.04-.142-.06-.29-.058-.437.03-.405.208-.783.496-1.058a1.53 1.53 0 0 1 1.057-.429c.392 0 .77.153 1.058.429.288.275.465.653.496 1.058ZM16.413 15.917c.002.148-.018.295-.058.437-.09.34-.284.64-.554.854-.27.214-.601.33-.941.33-.34 0-.671-.116-.941-.33a1.607 1.607 0 0 1-.554-.854c-.04-.142-.06-.29-.06-.437.032-.405.209-.783.497-1.058a1.53 1.53 0 0 1 1.058-.429c.392 0 .769.153 1.057.429.288.275.465.653.496 1.058ZM13.213 15.875H8.605M5.15 15.915H3.893a.745.745 0 0 1-.735-.735v-1.255M11.903 10.954h3.774"
      />

      <path
        stroke={color ?? theme.colors.gray8}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M11.846 15.836V7.013a.659.659 0 0 1 .656-.657h2.157a1.51 1.51 0 0 1 1.236.647l2.382 3.46c.18.256.272.561.265.873v2.873a1.734 1.734 0 0 1-1.736 1.735h-.264M7.463 8.238v3.52M4.572 8.836v2.118"
      />
    </svg>
  );
};
