import {UseQueryOptions, useQuery} from '@tanstack/react-query';
import {AxiosError} from 'axios';

import {Error, Routes} from 'src/interfaces/api/api.interface';
import {GetMeal, GetMealResponse} from 'src/interfaces/api/meal.interface';

import {networkClient} from 'src/services/client';

export function useMealQuery(
  {mealId, date}: GetMeal,
  options?: UseQueryOptions<GetMealResponse | null, AxiosError<Error>>,
) {
  return useQuery({
    queryKey: [Routes.Meal, mealId, date],
    queryFn: async (): Promise<GetMealResponse | null> => {
      const {data} = await networkClient.get(Routes.Meal, {
        params: {mealId, date},
      });

      return data;
    },
    ...options,
  });
}
