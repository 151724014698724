import * as React from 'react';

import {useTheme} from 'styled-components';

import {IconProps} from 'src/interfaces/icon.interface';

export const FileIcon = ({size = 24, color, strokeWidth = 1.5, ...rest}: IconProps) => {
  // Theme
  const theme = useTheme();

  return (
    <svg width={size} height={size} fill="none" {...rest}>
      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M19.831 12.952V9.16c0-.544-.21-1.067-.586-1.459L15.35 3.647A2.107 2.107 0 0 0 13.832 3H8.03a3.847 3.847 0 0 0-3.861 3.756v6.196"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M14.328 3.063v2.97a2.628 2.628 0 0 0 2.623 2.63h2.813M4.416 19.472h1.397a1.547 1.547 0 0 0 0-3.093H4.416v4.615M19.83 16.375h-2.506v4.626m2.12-1.927h-2.12"
      />

      <path
        stroke={color ?? theme.colors.gray12}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={strokeWidth}
        d="M11.654 21a2.313 2.313 0 0 0 0-4.625h-1.156v4.626h1.156Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
