import {useMutation} from '@tanstack/react-query';

import {Routes} from 'src/interfaces/api/api.interface';
import {UploadDishDocuments} from 'src/interfaces/api/dish.interface';
import {Dish} from 'src/interfaces/api/generated.interface';

import {networkClient} from 'src/services/client';

export function useUploadDishDocumentsMutation() {
  return useMutation({
    mutationFn: async ({documents, dishId}: UploadDishDocuments): Promise<Dish> => {
      const formData = new FormData();

      formData.append('dishId', dishId.toString());
      for (const document of documents) {
        formData.append('documents', document);
      }

      const {data} = await networkClient.post<Dish>(Routes.DishDocuments, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    },
  });
}
