import React from 'react';

import {Checkbox} from 'antd';
import styled from 'styled-components';

import {NoisePollutionValue, WasteValue} from 'src/interfaces/api/generated.interface';

const Container = styled.button<{selected: boolean}>`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  background-color: ${({theme, selected}) =>
    !selected ? theme.colors.white : theme.colors.primary2};
  border-radius: ${({theme}) => theme.radius.lg};
  border: ${({theme, selected}) =>
    !selected ? `1px solid ${theme.colors.gray4}` : 'none'};
  padding: ${({theme}) => theme.paddings.md};
  align-items: center;
  width: 100%;
`;

const Text = styled.span<{selected: boolean}>`
  ${({theme, selected}) => (selected ? theme.textStyles.p2Bold : theme.textStyles.p2)};
  color: ${({theme, selected}) =>
    selected ? theme.colors.primary6 : theme.colors.gray12};
`;

interface OptionButtonProps<T>
  extends Omit<React.HTMLAttributes<HTMLButtonElement>, 'onClick'> {
  selected: boolean;
  text: string;
  value: T;
  description?: string;
  onClick: (option: T) => void;
}

export function OptionButton<T extends string>({
  text,
  selected,
  description,
  value,
  onClick,
  ...rest
}: OptionButtonProps<T>) {
  return (
    <Container selected={selected} onClick={() => onClick(value)} {...rest}>
      <Text selected={selected}>{text}</Text>

      {description && <Text selected={selected}>{description}</Text>}

      <Checkbox checked={selected} onChange={() => onClick(value)} />
    </Container>
  );
}
