import React, {useMemo} from 'react';

import styled from 'styled-components';

const Avatar = styled.div`
  background-color: ${({theme}) => theme.colors.primary2};
  border-radius: ${({theme}) => theme.radius.sm};
  height: ${({theme}) => theme.heights.avatar.xxs};
  width: ${({theme}) => theme.heights.avatar.xxs};
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Intials = styled.text`
  ${({theme}) => theme.textStyles.p2Bold};
  color: ${({theme}) => theme.colors.primary5};
`;

interface InitialsAvatarProps {
  firstName: string;
  lastName: string;
}

export const InitialsAvatar: React.FC<InitialsAvatarProps> = ({firstName, lastName}) => {
  // Constants
  const initials = useMemo(() => {
    if (firstName && lastName) {
      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }

    return '';
  }, [firstName, lastName]);

  return (
    <Avatar>
      <Intials>{initials}</Intials>
    </Avatar>
  );
};
